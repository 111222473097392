import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {TaskType11} from "../../models/task-type11.model";
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";

@Component({
  selector: 'app-type11',
  templateUrl: './type11.component.html',
  styleUrls: ['./type11.component.css']
})
export class Type11Component implements OnInit {

  public task: TaskType11 = null;
  // Display setup
  attempts = 0;
  opacity = 0;
  finished = false;
  playingAudio = false;
  textAudio = null;

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
  result = { correct: 0, of: 0, incorrectAttempts: 0, answer_details: [] };
  images = [[],[]];


  constructor(private dataService: DataService) {
    // Begin the task..
    // GroverService.setControllerState(self);
    this.task = <TaskType11> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnInit() {
  }

  setupTask() {

    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
    this.finished = false;
    this.playingAudio = false;
    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 2 task does not exist - check your Session layout in the CMS');
      return;
    }

    let tempImages = [];
    for(let i=1; i < 7; i++) {
      let image = {
        imageUrl: typeof this.task['image'+i].file !== 'undefined' ? this.task['image'+i].file.url : '',
        imageFilename: typeof this.task['image'+i].file !== 'undefined' ? this.task['image'+i].file.filename : '',
        correct: this.task['image'+i].correct,
        enabled: true,
        borderGreen: false,
        borderRed: false
      };

      if(image.correct) {
        this.result.of++;
      }
      if (image.imageUrl !== '') {
        tempImages.push(image);
      }
    }
    tempImages = Utilities.shuffleArray(tempImages);
    this.images[0] = tempImages.splice(0,tempImages.length/2);
    this.images[1] = tempImages;

    this.dataService.progressShow({ stars: this.result.of });

    if (typeof this.task.word_audio !== 'undefined' && this.task.word_audio.url !== null) {
      this.textAudio = new Audio(this.task.word_audio.url);
      this.textAudio.addEventListener('ended', () => {
        this.playingAudio = false;
      });
    }
    this.introduceChallenge();
  };

  introduceChallenge() {
    this.attempts = 0;
    setTimeout(() => {
      this.opacity = 1;
      if (typeof this.task.instruction_audio !== 'undefined' && this.task.instruction_audio.url !== null) {
        let instructionAudio = new Audio(this.task.instruction_audio.url);
        instructionAudio.addEventListener('ended', () => {
          this.playingAudio = false;
          this.dataService.setSpeakerSound(this.task.instruction_audio.url, null);
          this.dataService.speakerIsPlaying = false;
        });
        this.dataService.speakerIsPlaying = true;
        this.playingAudio = true;
        instructionAudio.play();
      }
    }, 1000);
  };

  playWordAudio() {
    if (!this.playingAudio && this.textAudio !== null) {
      this.playingAudio = true;
      this.textAudio.play();
      this.dataService.progress.results.use_audio_content_items++;
    }
  };

  clickAnswer(item) {
    if(item.enabled && !this.finished) {
      this.attempts++;
      if (item.correct) {
        this.result.correct++;
        this.dataService.progress.results.answer_details
          .push({ attempt: 'CMS image: ' + item.imageFilename, correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
        this.dataService.progress.starData.completed++;
        item.borderGreen = true;
      } else {
        item.borderRed = true;
        this.dataService.progress.results.answer_details
          .push({ attempt: 'CMS image: ' + item.imageFilename, correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
        this.dataService.progress.results.incorrectAttempts++;
        if (this.task.unforgiving) {
          this.dataService.progress.starData.completed++;
        }
        this.dataService.tryAgain();
      }
      item.enabled = false;

      // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
      if ((this.task.unforgiving && this.attempts === this.result.of) ||
        (!this.task.unforgiving && this.result.of === this.result.correct)) {
        this.finished = true;
        this.nextRound();
      }
    }
  };

  nextRound() {
    setTimeout(() => {
      this.opacity = 0;
      if (this.dataService.taskMode === TaskMode.Warmups) {
        setTimeout(() => {
          const a = new Audio('assets/sounds/task_instructions/warmups/type11.mp3');
          a.addEventListener('ended', () => {
            this.completeTask();
          });
          a.play();
        }, 1000);
      } else {
        setTimeout(() => {
          this.completeTask();
        }, 1000)
      }
    }, 500);
  };

  completeTask() {
    this.dataService.completeTask();
  };

  borderColourClass(image) {
    if (this.task.unforgiving && !image.enabled) {
      return 'borderYellow';
    } else {
      if (image.borderGreen) {
        return 'borderGreen';
      } else if (image.borderRed) {
       return 'borderRed';
      } else {
        return '';
      }
    }
  }


}
