import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class JSONParseWithDateModule implements JSON {

  private reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
  private reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

  constructor() {}

  public parseWithoutDate = JSON.parse;
  public stringify = JSON.stringify;
  readonly [Symbol.toStringTag]: "JSON";

  stringifyWcf = (json) => {
    /// <summary>
    /// Wcf specific stringify that encodes dates in the
    /// a WCF compatible format ("/Date(9991231231)/")
    /// Note: this format works ONLY with WCF.
    ///       ASMX can use ISO dates as of .NET 3.5 SP1
    /// </summary>
    /// <param name="key" type="var">property name</param>
    /// <param name="value" type="var">value of the property</param>
    return JSON.stringify(json, (key, value) => {
      if (typeof value === "string") {
        let a = this.reISO.exec(value);
        if (a) {
          let val = '/Date(' +
            new Date(Date.UTC(+a[1], +a[2] - 1,
              +a[3], +a[4],
              +a[5], +a[6])).getTime() + ')/';
          this[key] = val;
          return val;
        }
      }
      return value;
    })
  };

  dateStringToDate = (dtString) => {
    /// <summary>
    /// Converts a JSON ISO or MSAJAX string into a date object
    /// </summary>
    /// <param name="" type="var">Date String</param>
    /// <returns type="date or null if invalid" />
    let a = this.reISO.exec(dtString);
    if (a) {
      return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
    }
    a = this.reMsAjax.exec(dtString);
    if (a) {
      let b = a[1].split(/[-,.]/);
      return new Date(+b[0]);
    }
    return null;
  };

  parseWithDate = (json) => {
    /// <summary>
    /// parses a JSON string and turns ISO or MSAJAX date strings
    /// into native JS date objects
    /// </summary>
    /// <param name="json" type="var">json with dates to parse</param>
    /// </param>
    /// <returns type="value, array or object" />
    try {
      return this.parseWithoutDate(json,
        (key, value) => {
          if (typeof value === 'string') {
            let a = this.reISO.exec(value);
            if (a) {
              return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
            }
            a = this.reMsAjax.exec(value);
            if (a) {
              let b = a[1].split(/[-+,.]/);
              return new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
          }
          return value;
        });
    } catch (e) {
      // orignal error thrown has no error message so rethrow with message
      throw new Error("JSON content could not be parsed. JSON: " + json);
    }
  };

  public parse = this.parseWithDate;

}
