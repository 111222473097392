import {Task} from "../models/task.model";

export class Utilities {

  public static shuffleArray(array) {
    let indexArray = array.map((item, index) => index);
    let currentIndex = indexArray.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);

      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = indexArray[currentIndex];
      indexArray[currentIndex] = indexArray[randomIndex];
      indexArray[randomIndex] = temporaryValue;
    }

    return indexArray.map((index) => {
      return array[index];
    })
  }

  public static pseudoShuffleTasks(taskArray: Task[]): Task[] {
    let shuffledTasks = Utilities.shuffleArray(taskArray);
    return Utilities.unSort(shuffledTasks);
  }

  // Adapted from Nina Scholz answer here:
  // https://stackoverflow.com/questions/32667815/jumble-consecutive-same-items-in-an-array-such-that-the-output-array-has-no-cons
  // The problem with this method is that is does not shuffle, or un-shuffles a shuffled input
  public static spread(input) {

    let findMaxKey = () => {
      let max = 0, key;
      Object.keys(length).forEach((k) => {
        if (length[k].length > max) {
          max = length[k].length;
          key = k;
        }
      });
      return key;
    };

    if (input.length === 0) {
      return input;
    }
    let length = input.reduce((r, a) => {
        if (r[a.taskType]) {
          r[a.taskType].push(a);
        } else {
          r[a.taskType] = [a];
        }
        return r;
      }, {}),
      i = 0, k = findMaxKey(), l,
      outputLength = length[k].length,
      output = Array.apply(Array, {length: outputLength}).map(() => {
        return [];
      });

    if (input.length - outputLength < outputLength - 1) {
      return input; // no spread possible
    }
    while (k = findMaxKey()) {
      l = length[k].length;
      while (l--) {
        output[i % outputLength].push(length[k].pop());
        i++;
      }
      delete length[k];
    }
    return output.reduce(function (r, a) {
      return r.concat(a)
    }, []);
  }


  // https://stackoverflow.com/questions/39170398/is-there-a-way-to-shuffle-an-array-so-that-no-two-consecutive-values-are-the-sam?noredirect=1&lq=1
  public static unSort(input) {
    let a = input.map((item, index) => index);

    // construct a measure of "blockiness"
    let blockiness = (a): number => {
      let bl = 0;
      for (let i = 0; i < a.length; i++) {
        // Wrap around, as OP wants this on a circle
        if (input[a[i]].taskType === input[a[(i + 1) % a.length]].taskType) {
          bl += 1;
        }
      }
      return bl;
    };

    let aCopy = a; // Make it a mutable var
    let giveUpAfter = aCopy.length; // Frankly, arbitrary...
    while (blockiness(aCopy) > 0 && giveUpAfter > 0) {
      // i.e. we give up if either blockiness has been removed ( == 0)
      // OR if we have made too many changes without solving

      // Look for adjacent pairs
      for (let i = 0; i < aCopy.length; i++) {
        // Wrap around, as OP wants this on a circle
        let prev = (i - 1 >= 0) ? i - 1 : i - 1 + aCopy.length;
        if (input[aCopy[i]].taskType === input[aCopy[prev]].taskType) { // two adjacent elements match
          let next = (i + 1) % aCopy.length; // again, circular
          // move the known match away, swapping it with the "unknown" next element
          let temp = aCopy[i];
          aCopy[i] = aCopy[next];
          aCopy[next] = temp;
        }
      }
      giveUpAfter -= 1
    }

    return aCopy.map((index) => {
      return input[index];
    })
  }

  public static v4(): string {
    let id: string = '', i: number;

    for (i = 0; i < 36; i++) {
      if (i === 14) {
        id += '4';
      } else if (i === 19) {
        id += '89ab'.charAt(Utilities.getRandom(4));
      } else if (i === 8 || i === 13 || i === 18 || i === 23) {
        id += '-';
      } else {
        id += '0123456789abcdef'.charAt(Utilities.getRandom(16));
      }
    }
    return id;
  }

  static getRandom(max: number): number {
    return Math.random() * max;
  }

  public static filterTextCharacters(text: string): string {
    let t = text.toString().replace(/\d|\s/g, '');
    return t.replace(/([\u002E]?|[#0-9]\u20E3)|\d|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g, '');
  }


}
