import {Task} from './task.model';
import {Constants} from '../constants';

export class TaskType5 extends Task {
  morph: string = "";
  stem: string = "";
  morphedStem: string = "";
  affix: string = "";
  category: string = "";

  instruction1Text: string;
  instruction1Audio: { url: string; };
  instruction1AudioText: string;

  instruction2Text: string;
  instruction2Audio: { url: string; };
  instruction2AudioText: string;

  box1: {
    image: { url: string; };
    word: string;
    audio: { url: string; };
    audioText: string;
  };

  box2: {
    image: { url: string; };
    word: string;
    audio: { url: string; };
    audioText: string;
  };

  image1: {
    image: { url: string; };
    word: string;
  };
  image2: {
    image: { url: string; };
    word: string;
  };
  image3: {
    image: { url: string; };
    word: string;
  };
  image4: {
    image: { url: string; };
    word: string;
  };
  image5: {
    image: { url: string; };
    word: string;
  };
  image6: {
    image: { url: string; };
    word: string;
  };

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

/*  deserialize(instanceData: any) {
    // Note this.active will not be listed in keys since it's declared, but not defined
    const keys = Object.keys(this);

    for (const key of keys) {
      if (instanceData.hasOwnProperty(key)) {
        this[key] = instanceData[key];
      }
    }
  }*/

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.stem = input['stem'];
    this.morphedStem = input['morphedStem'];
    this.affix = input['affix'];
    this.instruction1Text = input['instruction1Text'];
    this.instruction1Audio = input['instruction1Audio'];
    this.instruction1AudioText = input['instruction1AudioText'];
    this.instruction2Text = input['instruction2Text'];
    this.instruction2Audio = input['instruction2Audio'];
    this.instruction2AudioText = input['instruction2AudioText'];
    this.box1 = input['box1'];
    this.box2 = input['box2'];
    this.image1 = input['image1'];
    this.image2 = input['image2'];
    this.image3 = input['image3'];
    this.image4 = input['image4'];
    this.image5 = input['image5'];
    this.image6 = input['image6'];
    return this;
  }

  rewriteUrlsToLocalAssets(input: Object) {

    if (this.instruction1Audio) {
      this.instruction1Audio.url =
        Constants.localAssetUrl + input['instruction1Audio']['path'] + '/' + input['instruction1Audio']['filename'];
    }
    if (this.instruction2Audio) {
      this.instruction2Audio.url =
        Constants.localAssetUrl + input['instruction2Audio']['path'] + '/' + input['instruction2Audio']['filename'];
    }
    if (this.box1) {
      if (this.box1.audio) {
        this.box1.audio.url =
          Constants.localAssetUrl + input['box1']['audio']['path'] + '/' + input['box1']['audio']['filename'];
      }
      if (this.box1.image) {
        this.box1.image.url =
          Constants.localAssetUrl + input['box1']['image']['path'] + '/' + input['box1']['image']['filename'];
      }
    }
    if (this.box2) {
      if (this.box2.audio) {
        this.box2.audio.url =
          Constants.localAssetUrl + input['box2']['audio']['path'] + '/' + input['box2']['audio']['filename'];
      }
      if (this.box2.image) {
        this.box2.image.url =
          Constants.localAssetUrl + input['box2']['image']['path'] + '/' + input['box2']['image']['filename'];
      }
    }
    if (this.image1 && this.image1.image) {
      this.image1.image.url =
        Constants.localAssetUrl + input['image1']['image']['path'] + '/' + input['image1']['image']['filename'];
    }
    if (this.image2 && this.image2.image) {
      this.image2.image.url =
        Constants.localAssetUrl + input['image2']['image']['path'] + '/' + input['image2']['image']['filename'];
    }
    if (this.image3 && this.image3.image) {
      this.image3.image.url =
        Constants.localAssetUrl + input['image3']['image']['path'] + '/' + input['image3']['image']['filename'];
    }
    if (this.image4 && this.image4.image) {
      this.image4.image.url =
        Constants.localAssetUrl + input['image4']['image']['path'] + '/' + input['image4']['image']['filename'];
    }
    if (this.image5 && this.image5.image) {
      this.image5.image.url =
        Constants.localAssetUrl + input['image5']['image']['path'] + '/' + input['image5']['image']['filename'];
    }
    if (this.image6 && this.image6.image) {
      this.image6.image.url =
        Constants.localAssetUrl + input['image6']['image']['path'] + '/' + input['image6']['image']['filename'];
    }

  }

}
