import {Task} from './task.model';
import {Constants} from '../constants';

export class Type7Word {
  audio: { url: string; };
  text: string;
  reference?: number;
  enabled?: boolean;
  draggable?: boolean;

  constructor(data: {}) {
    this.audio = { url: null };
    this.text = '';
    this.enabled = false;
    this.draggable = true;
    if (data) {
      if (data.hasOwnProperty('audio')) {
        this.audio.url = data['audio'].url
      }
      this.text = data['text'];
    }
  }

  public playAudio(callback: () => void) {
    if (this.audio.url !== null) {
      let a = new Audio(this.audio.url);
      a.addEventListener('ended', callback);
      a.play().catch();
    } else {
      callback();
    }
  }
}

export class Type7Correct {
  firstWord: number;
  secondWord: number;
  thirdWord: number;
  audio: { url: string; };

  constructor(data: {}) {
    if (data) {
      this.firstWord = data['firstWord'];
      this.secondWord = data['secondWord'];
      this.thirdWord = data['thirdWord'];
      this.audio = {url: null};
      if (data.hasOwnProperty('audio') && data['audio'] !== null) {
        this.audio.url = data['audio'].url
      }
    }
  }

  public playAudio() {
    if (this.audio.url !== null) {
      new Audio(this.audio.url).play();
    }
  }

}

export class TaskType7 extends Task {
  morph: string = "";
  affix: string = "";
  category: string = "";
  morf_category: string;

  total_correct_required: number;
  total_correct_possible: number;

  word1: Type7Word;
  word2: Type7Word;
  word3: Type7Word;
  word4: Type7Word;
  word5: Type7Word;
  word6: Type7Word;

  correct1: Type7Correct;
  correct2: Type7Correct;
  correct3: Type7Correct;
  correct4: Type7Correct;
  correct5: Type7Correct;
  correct6: Type7Correct;
  correct7: Type7Correct;
  correct8: Type7Correct;
  correct9: Type7Correct;
  correct10: Type7Correct;
  correct11: Type7Correct;
  correct12: Type7Correct;
  correct13: Type7Correct;
  correct14: Type7Correct;
  correct15: Type7Correct;

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.affix = input['affix'];
    this.morf_category = input['morf_category'];

    this.total_correct_required = input['total_correct'];

    for (let w = 1; w < 7; w++) {
      this['word' + w] = new Type7Word(input['word' + w]);
      this['word' + w].reference = w;
    }

    this.total_correct_possible = 0;
    let correctIndex = 1;
    for (let c = 1; c < 16; c++) {
      if (input['correct' + c]) {
        this['correct' + correctIndex] = new Type7Correct(input['correct' + c]);
        this.total_correct_possible++;
        correctIndex++;
      }
    }

    return this;
  }

  // Calculate if the given combination fully or partially matches a correct answer
  public hasCombinationStatus(cc: Type7Correct, compareInput: Type7Correct[]): { status: string, correctRef: Type7Correct } {
    let result = { status: 'incorrect', correctRef: null };

    // If only one word has been placed, return 'some'
    if ((cc.firstWord === 0 && cc.secondWord === 0 && cc.thirdWord > 0)
      || (cc.firstWord === 0 && cc.secondWord > 0 && cc.thirdWord === 0)
      || (cc.firstWord > 0 && cc.secondWord === 0 && cc.thirdWord === 0)) {
        result.status = 'some';
        return result;
      }

    let checkCombinations = (cc, compare): boolean => {
      let wordCount = compare.firstWord > 0 && compare.secondWord > 0 && compare.thirdWord > 0 ? 3 : 2;

      let a = compare.firstWord === cc.firstWord;
      let b = compare.secondWord === cc.secondWord;
      let c = compare.thirdWord === cc.thirdWord;

      if (a && b && c) {
        result.status = 'correct';
        result.correctRef = compare;
        return true;
      } else {
        if (wordCount === 3) {
          if( (a && b && cc.thirdWord === 0) || (a && cc.secondWord === 0 && cc.thirdWord === 0) || (cc.firstWord === 0 && b && c)
            || (cc.firstWord === 0 && b && cc.thirdWord === 0) || (a && cc.secondWord === 0 && c)
            || (cc.firstWord === 0 && cc.secondWord === 0 && c) ) {
            result.status = 'some';
            return true;
          }
        } else {
          if ( (cc.firstWord === 0 && b && cc.thirdWord === 0) || (a && cc.secondWord === 0 && cc.thirdWord === 0)
            || (cc.firstWord === 0 && cc.secondWord === 0 && c) || (cc.firstWord === 0 && b && cc.thirdWord === 0) ) {
            result.status = 'some';
            return true;
          }
        }
      }
      return false;
    };

    if (compareInput !== null) {
      for (let i = 0; i < compareInput.length; i++) {
        let compare: Type7Correct = compareInput[i];
        if (checkCombinations(cc, compare)) {
          break;
        }
      }
    } else {
      for (let i = 1; i < 16; i++) {
        let compare: Type7Correct = this['correct' + i];
        if (checkCombinations(cc, compare)) {
          break;
        }
      }
    }
    return result;
  }

  // Return the fully concatenated word for the given word parts
  public combinedWordFromCC(cc: Type7Correct): string {
    let word = cc.firstWord > 0 ? this['word' + cc.firstWord].text : '';
    word += cc.secondWord > 0 ? this['word' + cc.secondWord].text : '';
    word += cc.thirdWord > 0 ? this['word' + cc.thirdWord].text : '';
    return word;
  }

  // A string showing the words inside the given Type7Correct class
  public wordsFromCC(cc: Type7Correct) {
    let w1 = '', w2 = '', w3 = '';
    if (cc.firstWord > 0) {
      w1 = this['word' + cc.firstWord].text;
    }
    if (cc.secondWord > 0) {
      w2 = ', ' + this['word' + cc.secondWord].text;
    }
    if (cc.thirdWord > 0) {
      w3 = ', ' + this['word' + cc.thirdWord].text;
    }
    return w1 + w2 + w3;
  }


  rewriteUrlsToLocalAssets(input: Object) {

    if (this.word1 && this.word1.audio && this.word1.audio.url) {
      this.word1.audio.url =
        Constants.localAssetUrl + input['word1']['audio']['path'] + '/' + input['word1']['audio']['filename'];
    }
    if (this.word2 && this.word2.audio && this.word2.audio.url) {
      this.word2.audio.url =
        Constants.localAssetUrl + input['word2']['audio']['path'] + '/' + input['word2']['audio']['filename'];
    }
    if (this.word3 && this.word3.audio && this.word3.audio.url) {
      this.word3.audio.url =
        Constants.localAssetUrl + input['word3']['audio']['path'] + '/' + input['word3']['audio']['filename'];
    }
    if (this.word4 && this.word4.audio && this.word4.audio.url) {
      this.word4.audio.url =
        Constants.localAssetUrl + input['word4']['audio']['path'] + '/' + input['word4']['audio']['filename'];
    }
    if (this.word5 && this.word5.audio && this.word5.audio.url) {
      this.word5.audio.url =
        Constants.localAssetUrl + input['word5']['audio']['path'] + '/' + input['word5']['audio']['filename'];
    }
    if (this.word6 && this.word6.audio && this.word6.audio.url) {
      this.word6.audio.url =
        Constants.localAssetUrl + input['word6']['audio']['path'] + '/' + input['word6']['audio']['filename'];
    }

    for (let c = 1; c < 16; c++) {
      let ref = typeof this['correct' + c] !== 'undefined' ? this['correct' + c] : null;
      if (ref && ref['audio'] && ref['audio']['url']) {
        ref['audio']['url'] =
          Constants.localAssetUrl + input['correct' + c]['audio']['path'] + '/' + input['correct' + c]['audio']['filename'];
      }
    }

  }

}
