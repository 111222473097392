import { Component, OnInit, OnDestroy } from '@angular/core';

import {DataService} from "../../services/data.service";
import {ActivatedRoute} from "@angular/router";
import { Subject } from 'rxjs';

declare let AdobeAn: any; // this variable will be set by createjs
declare let Media: any;
declare let createjs: any;


/*freeGlobal['morfSceneMedia'] = {
  status: {
    playing: false,
    id: ''
  },
  media: {}
};
freeGlobal['registerSound'] = (src, id) => {
  freeGlobal['morfSceneMedia'].media[id] = freeGlobal.hasOwnProperty('cordova') ? new Media(src, null) : new Audio(src);
};
freeGlobal['playSound'] = (id, loop) => {
  if (freeGlobal['morfSceneMedia'].media[id] != null) {
    freeGlobal['morfSceneMedia'].media[id].play();
    freeGlobal['morfSceneMedia'].status.playing = true;
    freeGlobal['morfSceneMedia'].status.id = id;
  }
  //return createjs.Sound.play(id, {interrupt: createjs.Sound.INTERRUPT_EARLY, loop: loop});
};*/

@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.css']
})
export class SceneComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>(); // = new Subject(); in Typescript 2.2-2.4

  subscription;
  currentSceneSubpath: string;
  theScript;
  complete = true;

  canvas;
  stage;
  exportRoot;
  anim_container;
  dom_overlay_container;

  anKey;
  comp;
  lib;
  loader;
  ss;

  registeredSounds: string[];

  constructor(private route: ActivatedRoute, private dataService: DataService) {    // Constructor gets called multiple times as Component is being recreated, and so scripts stack up..
  }

  ngOnInit() {
    this.subscription = this.route.url.subscribe(
      url => {
        let urlString = url.map(segment => segment.path);
        if (urlString.indexOf('scene') > -1 && !this.dataService.sceneInProgress) {
          const params = this.route.queryParams.subscribe((params) => {
            this.complete = false;
            // Override for testing scenes:
            if (params['scene']) this.currentSceneSubpath = params['scene'];
            else this.currentSceneSubpath = this.dataService.sceneSubpath;
            //this.currentSceneSubpath = 'general/3/'
            if (this.currentSceneSubpath !== '') {
              this.dataService.sceneInProgress = true;
              this.addScriptToDOM();
            }
          })
        }
      }
    );
  }


  ngOnDestroy() {
    // this.subscription.unsubscribe();
    if (typeof this.registeredSounds !== 'undefined') {
      this.registeredSounds.forEach((soundId) => {
        this.dataService.unregisterSound(soundId);
      });
    }
  }

  addScriptToDOM() {
    let head = document.getElementsByTagName('head')[0];

    this.theScript= document.createElement('script');
    this.theScript.src = "assets/scenes/"
      + this.currentSceneSubpath + "morfScene.js"; // ?r=" + Date.now();
    this.theScript.type = "text/javascript";
    this.theScript.onload = () => {
      this.init();
    };

    head.appendChild( this.theScript );
  }

  removeScriptFromDOM() {
    this.theScript.parentNode.removeChild( this.theScript );
  }

  // Scene IDs must be named 'sceneXY' inside the Adobe Edge JS file.  Refer to the bottom of the file.
  init() {
    this.canvas = document.getElementById("canvas");
    this.anim_container = document.getElementById("animation_container");
    this.dom_overlay_container = document.getElementById("dom_overlay_container");

    let anKeys = Object.keys(AdobeAn.compositions);
    if (anKeys.length !== 1) return;
    this.anKey = anKeys[0];
    this.comp = AdobeAn.getComposition(this.anKey);

    this.lib = this.comp.getLibrary();
    this.registeredSounds = [];
    let soundLoadPromises = [];
    this.lib.properties.manifest.forEach((m) => {
      let s = m.src.substr(0, 6);
      let subPath = m.src.substr(7);
      if (s === 'sounds') {
        m.src = 'assets/sounds/scenes/' + subPath.split('?')[0];
        const p: Promise<string> = new Promise((resolve, reject) => {
          this.dataService.registerSound(m.src, m.id, resolve, reject);
        });
        soundLoadPromises.push(p);
      } else if (s === 'images') {
        m.src = 'assets/images/scenes/' + subPath.split('?')[0];
      }
      // window['registerSound'](m.src, m.id);
      // createjs.Sound.registerSound(m.src, m.id);
    });

    Promise.all<string>(soundLoadPromises).then((ids) => {
      ids.forEach((id) => {
        this.registeredSounds.push(id);
      });

      this.loader = new createjs.LoadQueue(false);
      this.loader.installPlugin(createjs.Sound);
      this.loader.addEventListener("fileload", (evt) => { this.handleFileLoad(evt, this.comp)});
      this.loader.addEventListener("complete", (evt) => { this.handleComplete(evt) });
      this.lib = this.comp.getLibrary();
      this.loader.loadManifest(this.lib.properties.manifest);

    });

    AdobeAn.bootstrapCallback( (compId) => {
      console.log('composition loaded: ' + compId);
     /* AdobeAn.Symbol.bindTimelineAction(compId, "stage", "Default Timeline", "complete", (sym, e) => {
        console.log('timeline complete');
      });*/
    });
  	// this.handleComplete({});
  }

  handleFileLoad(evt, comp) {
    let images = comp.getImages();
    if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result; }
  }

  handleComplete(evt) {

    this.lib = this.comp.getLibrary();
    this.ss = this.comp.getSpriteSheet();
    let queue = evt.target;
    let ssMetadata = this.lib.ssMetadata;
    for(let i = 0; i < ssMetadata.length; i++) {
      this.ss[ssMetadata[i].name] = new createjs.SpriteSheet( {"images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames} )
    }
    this.exportRoot = new this.lib['morfScene']();
    this.exportRoot.addEventListener("complete", this.timelineComplete);
    this.stage = new this.lib.Stage(this.canvas);
    this.stage.addChild(this.exportRoot);
    createjs.Touch.enable(this.stage);


    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
 /* 	let lib = this.theComp.getLibrary();
  	let ss = this.theComp.getSpriteSheet();
  	this.exportRoot = new lib['morfScene']();
    this.exportRoot.addEventListener("complete", () => {
      this.timelineComplete();
    });
  	this.theStage.addChild(this.exportRoot);*/

  	//Registers the "tick" event listener.
  	let fnStartAnimation = () => {
  		createjs.Ticker.framerate = this.lib.properties.fps;
  		createjs.Ticker.addEventListener("tick", this.stage);
  	};

    //Code to support hidpi screens and responsive scaling.
    let makeResponsive = (isResp, respDim, isScale, scaleType) => {
      let lastW, lastH, lastS=1;

      let resizeCanvas = () => {
        let w = this.lib.properties.width, h = this.lib.properties.height;
        let iw = window.innerWidth, ih=window.innerHeight;
        let pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;
        if(isResp) {
          if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {
            sRatio = lastS;
          }
          else if(!isScale) {
            if(iw<w || ih<h)
              sRatio = Math.min(xRatio, yRatio);
          }
          else if(scaleType==1) {
            sRatio = Math.min(xRatio, yRatio);
          }
          else if(scaleType==2) {
            sRatio = Math.max(xRatio, yRatio);
          }
        }
/*         this.canvas.width = w*pRatio*sRatio;
        this.canvas.height = h*pRatio*sRatio;
        this.canvas.style.width = this.anim_container.style.width =  w*sRatio+1+'px';
        this.canvas.style.height = this.anim_container.style.height = h*sRatio+1+'px'; */
        this.canvas.width = w*pRatio*sRatio;
        this.canvas.height = h*pRatio*sRatio;
        this.canvas.style.width = '100vw';
        this.canvas.style.height = '75vw';

        this.stage.scaleX = pRatio*sRatio;
        this.stage.scaleY = pRatio*sRatio;
        lastW = iw; lastH = ih; lastS = sRatio;
      };

      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();
    };

    makeResponsive(true, 'both', true, 1);
    AdobeAn.compositionLoaded(this.lib.properties.id);
  	fnStartAnimation();
  }

  timelineComplete = () => {
    if(!this.complete) {
      this.complete = true;
      console.log("Completed timeline for scene " + this.currentSceneSubpath);
      createjs.Ticker.removeEventListener("tick", this.stage);
      this.exportRoot.removeEventListener("complete", this.timelineComplete);
      this.exportRoot.stop();
      this.stage.removeChild(this.exportRoot);
      this.removeScriptFromDOM();
      delete AdobeAn.compositions[this.anKey];
      createjs.Sound.removeAllSounds();
      this.currentSceneSubpath = '';
      this.dataService.sceneInProgress = false;
      this.dataService.updateState(true);
    }
  }

}
