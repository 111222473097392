import {Task} from './task.model';
import {Constants} from '../constants';

export class TaskType6 extends Task {
  morph: string = "";
  stem: string = "";
  morphedStem: string = "";
  affix: string = "";
  category: string = "";
  box1: {
    image: { url: string };
    word: string;
  };
  box2: {
    image: { url: string };
    word: string;
  };
  text1: {
    text: string,
    audio: { url: string; },
    word: string;
  };
  text2: {
    text: string,
    audio: { url: string; },
    word: string;
  };
  text3: {
    text: string,
    audio: { url: string; },
    word: string;
  };
  text4: {
    text: string,
    audio: { url: string; },
    word: string;
  };
  text5: {
    text: string,
    audio: { url: string; },
    word: string;
  };

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

/*  deserialize(instanceData: any) {
    // Note this.active will not be listed in keys since it's declared, but not defined
    const keys = Object.keys(this);

    for (const key of keys) {
      if (instanceData.hasOwnProperty(key)) {
        this[key] = instanceData[key];
      }
    }
  }*/

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.stem = input['stem'];
    this.morphedStem = input['morphedStem'];
    this.affix = input['affix'];
    this.box1 = input['box1'];
    this.box2 = input['box2'];
    this.text1 = input['text1'];
    this.text2 = input['text2'];
    this.text3 = input['text3'];
    this.text4 = input['text4'];
    this.text5 = input['text5'];
    return this;
  }


  rewriteUrlsToLocalAssets(input: Object) {

    if (this.box1 && this.box1.image) {
      this.box1.image.url =
        Constants.localAssetUrl + input['box1']['image']['path'] + '/' + input['box1']['image']['filename'];
    }
    if (this.box2 && this.box2.image) {
      this.box2.image.url =
        Constants.localAssetUrl + input['box2']['image']['path'] + '/' + input['box2']['image']['filename'];
    }
    if (this.text1 && this.text1.audio) {
      this.text1.audio.url =
        Constants.localAssetUrl + input['text1']['audio']['path'] + '/' + input['text1']['audio']['filename'];
    }
    if (this.text2 && this.text2.audio) {
      this.text2.audio.url =
        Constants.localAssetUrl + input['text2']['audio']['path'] + '/' + input['text2']['audio']['filename'];
    }
    if (this.text3 && this.text3.audio) {
      this.text3.audio.url =
        Constants.localAssetUrl + input['text3']['audio']['path'] + '/' + input['text3']['audio']['filename'];
    }
    if (this.text4 && this.text4.audio) {
      this.text4.audio.url =
        Constants.localAssetUrl + input['text4']['audio']['path'] + '/' + input['text4']['audio']['filename'];
    }
    if (this.text5 && this.text5.audio) {
      this.text5.audio.url =
        Constants.localAssetUrl + input['text5']['audio']['path'] + '/' + input['text5']['audio']['filename'];
    }

  }
}
