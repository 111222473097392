import {Component} from '@angular/core';
import { ActivatedRoute, UrlSegment } from "@angular/router";
import {DataService} from "./services/data.service";
import {LoginMode, TaskMode, ViewState} from './models/general.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  constructor(public dataService: DataService, private route: ActivatedRoute) {
    this.route.url.subscribe((res: UrlSegment[]) => {
      let paths = res.map(segment => segment.path);

      // This services the need to display tasks directly from the CMS
      if (paths.length > 2 && paths[0] === "sample") {
        console.log('Displaying sample task')
      } else {
        this.dataService.loginMode = LoginMode.AppStarted;
        this.dataService.viewState = ViewState.Loggedin;
        const parsedUrl = new URL(window.location.href);
        if (parsedUrl.href.indexOf('?mode=login') < 0) {
          this.dataService.viewState = ViewState.Login;
        }
        this.dataService.updateState();
      }
    });
  }
}
