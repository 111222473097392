import {Directive, ElementRef, HostListener, AfterViewInit} from '@angular/core';
import {Message, MessageService} from '../services/message.service';

// import { DOCUMENT } from '@angular/platform-browser';

@Directive({
  selector: '[appDrawing]'
})
export class DrawingDirective implements AfterViewInit {

  ctx;
  currentWidth = 0;
  currentHeight = 0;
  // variable that decides if something should be drawn on mousemove
  drawing = false;
  strokeColour =  "#1DEf3F"; // "rgba(255, 0, 255, 0.7)";

  // the last coordinates before the current move
  startX;
  startY;
  confirmedLines;

  choke;
  subscription;

  constructor(private el: ElementRef, private messageService: MessageService) {
    // el.nativeElement.style.backgroundColor = 'yellow';
    this.ctx = el.nativeElement.getContext('2d');
    this.subscription = messageService.messageSubject.subscribe(message => this.onNewMessage(message));
    this.confirmedLines = [];
    this.choke = 0;
    this.resizeWindow();
  }

  ngAfterViewInit() { // wait for the view to init before using the element
    this.ctx = this.el.nativeElement.getContext('2d');
    this.subscription = this.messageService.messageSubject.subscribe(message => this.onNewMessage(message));
    this.confirmedLines = [];
    this.resizeWindow();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("window:resize", ['$event'])
  onWindowScroll() {
    this.resizeWindow();
  }

  @HostListener("window:mouseup", ['$event'])
  onMouseup() {
    this.myMouseUp();
  }

  @HostListener("window:mousemove", ['$event'])
  onMousemove(event) {
    this.myMouseMove(event);
  }

  private onNewMessage(message: Message): void {
    // do something with added item
    switch (message.message) {
      case "morfologiTaskLineClear":
        this.confirmedLines = [];
        this.myMouseUp();
        break;
      case "morfologiTaskLine":
        this.confirmLine(message.args);
        this.drawConfirmedLines();
        break;
      case "morfologiTaskMousedown":
        this.startX = message.args['startX'];
        this.startY = message.args['startY'];
        this.drawing = true;
        break;
      case "morfologiTaskMouseup":
        this.myMouseUp();
        break;
      case "morfologiTaskMousemove":
        this.myMouseMove(message.args);
        break;
      case "":
    }
  }


  confirmLine(linkedWord) {
    linkedWord['startX%'] = linkedWord.startX / this.currentWidth;
    linkedWord['startY%'] = linkedWord.startY / this.currentHeight;

    linkedWord['endX%'] = linkedWord.endX / this.currentWidth;
    linkedWord['endY%'] = linkedWord.endY / this.currentHeight;

    this.confirmedLines.push(linkedWord);
  }

  resizeWindow() {
    this.el.nativeElement.width = window.innerWidth;
    this.el.nativeElement.height = window.innerHeight;

    this.currentWidth = this.el.nativeElement.width;
    this.currentHeight = this.el.nativeElement.height;
    this.drawConfirmedLines();
  }

  myMouseMove(event) {
    this.choke = (this.choke === 2 ? 0 : ++this.choke);
    if (this.choke === 0 && this.drawing) {
      // clear canvas
      this.ctx.clearRect(0, 0, this.currentWidth, this.currentHeight);

      this.drawConfirmedLines();
      this.ctx.lineWidth = 5;
      // begins new line
      this.ctx.beginPath();

      let currentX, currentY;
      // get current mouse position
      if (typeof event.clientX !== 'undefined') {
        currentX = event.clientX;
        currentY = event.clientY;
      } else if (event.touches && event.touches[0]) {
        currentX = event.touches[0].clientX;
        currentY = event.touches[0].clientY;
      } else {
        currentX = event.layerX - event.currentTarget.offsetLeft;
        currentY = event.layerY - event.currentTarget.offsetTop;
      }



      this.draw(this.startX, this.startY, currentX, currentY);
    }
  };

  myMouseUp() {
    // clear canvas
    this.ctx.clearRect(0, 0, this.currentWidth, this.currentHeight);

    // stop drawing
    this.drawing = false;
    this.drawConfirmedLines();
  };

  private arrowHead = (x, y, rot) => {
    this.ctx.save();
    this.ctx.translate(x, y);
    this.ctx.rotate(rot);
    this.ctx.beginPath();
    this.ctx.moveTo(0, 5);
    this.ctx.lineTo(-20, -30);
    this.ctx.lineTo(20, -30);
    this.ctx.closePath();
    this.ctx.fillStyle = this.strokeColour;
    this.ctx.fill();
    this.ctx.restore();
  };

  drawConfirmedLines() {
    let x1, x2, y1, y2;

    this.confirmedLines.forEach( (line) => {
      this.ctx.lineWidth = 5;
      this.ctx.beginPath();

      // We now need to account for shuffling of items, so calculate start coordinates from the element reference

      // x1 = line['startX%'] * this.currentWidth;
      // y1 = line['startY%'] * this.currentHeight;
      // x2 = line['endX%'] * this.currentWidth;
      // y2 = line['endY%'] * this.currentHeight;

      let rect = line.startElement.getBoundingClientRect();
      x1 = rect.left + (rect.right - rect.left) / 2;
      y1 = rect.top + (rect.bottom - rect.top) / 2;

      rect = line.endElement.getBoundingClientRect();
      x2 = rect.left + (rect.right - rect.left) / 2;
      y2 = rect.top + (rect.bottom - rect.top) / 2;

      let rot = -Math.atan2(x1 - x2, y1 - y2);

      // start location
      this.ctx.moveTo(x1, y1);
      // to
      this.ctx.lineTo(x2, y2);

      // color
      this.ctx.strokeStyle = this.strokeColour;
      // draw it
      this.ctx.stroke();
      // draw arrowhead at start
      // arrowHead(x1, y1, rot);
      // draw arrowhead at end
      this.arrowHead(x2, y2, rot + Math.PI);
    })
  }

  draw(lX, lY, cX, cY) {
    // line from
    this.ctx.moveTo(lX, lY);
    // to
    this.ctx.lineTo(cX, cY);
    // color
    this.ctx.strokeStyle = this.strokeColour;   // Blue = #4bf
    // draw it
    this.ctx.stroke();
    // draw arrow head
    let rot = -Math.atan2(lX - cX, lY - cY);
    this.arrowHead(cX, cY, rot + Math.PI);
  }


}
