import {Component, OnInit, OnDestroy} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import {DataService} from '../../services/data.service';
import {TaskType7, Type7Correct, Type7Word} from '../../models/task-type7.model';
import {DragulaService} from 'ng2-dragula-base/dist';
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";

@Component({
  selector: 'app-type7',
  templateUrl: './type7.component.html',
  styleUrls: ['./type7.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        transition(
          ':enter', [
            style({opacity: 0}),
            animate('500ms', style({'opacity': 1}))
          ]
        ),
        transition(
          ':leave', [
            style({'opacity': 1}),
            animate('500ms', style({'opacity': 0}))
          ]
        )]
    )
  ]
})
export class Type7Component implements OnInit {


  public task: TaskType7 = null;
  // Display setup
  unforgivingTestMode = false;
  attempts = 0;
  opacity = 0;
  opacityWords = 0;

  // Models for words
  words: Type7Word[] = [];
  discoveredCombinations = [];

  // Divide the correct combined word answers into twos and threes, so we know how many answer boxes to show on screen
  combinationBoxes = 2;
  correctCombinations2 = [];
  correctCombinations3 = [];
  selectedCombination: Type7Correct = null;

  // Temp models for Dragula
  public draggingWord: Type7Word[] = [];
  public droppedItem1: Type7Word[] = [];
  public droppedItem2: Type7Word[] = [];
  public droppedItem3: Type7Word[] = [];
  public combinedWord = "";

  draggingNow = false;
  finishDone = false;
  taskCompleted = false;
  audioPlaying = false;

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
  result = {correct: 0, of: 2, incorrectAttempts: 0, answer_details: [] };
  correctAudio1 = null;
  correctAudio2 = null;
  audioSentenceQueue = [];

  starLeftCss = {};
  starMiddleCss = {};
  starRightCss = {};

  private dragulaCancelSubscription;
  private dragulaDropSubscription;
  private dragulaDragSubscription;
  private dragulaRemoveSubscription;

  private cancelDropEvent: boolean;

  constructor(private dragulaService: DragulaService, private dataService: DataService) {
    this.setupDragula();
    this.cancelDropEvent = false;
  }

  ngOnInit() {

    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    this.dataService.progressShow({stars: 2});
    // GroverService.setControllerState(self);

    this.task = <TaskType7> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnDestroy() {
    this.dragulaDropSubscription.unsubscribe();
    this.dragulaCancelSubscription.unsubscribe();
    this.dragulaDragSubscription.unsubscribe();
    this.dragulaService.destroy("drag-bag7");
  }

  setupTask() {
    this.finishDone = false;
    this.audioSentenceQueue = [];
    this.audioPlaying = false;
    this.taskCompleted = false;
    this.combinedWord = "";
    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 7 task does not exist - check your Session layout in the CMS');
      return;
    }

    let c2 = [];
    let c3 = [];
    for (let ci = 1; ci < this.task.total_correct_possible; ci++) {
      let correct: Type7Correct = this.task['correct' + ci];
      if (correct.firstWord > 0 || correct.secondWord > 0 || correct.thirdWord > 0) {
        if (correct.thirdWord > 0) {
          c3.push(correct);
        } else {
          c2.push(correct);
        }
      }
    }
    this.correctCombinations2 = Utilities.shuffleArray(c2);
    this.correctCombinations3 = Utilities.shuffleArray(c3);
    this.chooseRandomBoxes();

    let tempWords = [];
    for (let i = 1; i < 7; i++) {
      const theWord: Type7Word = this.task['word' + i];
      if (theWord.text !== '') {
        tempWords.push(theWord);
      }
    }
    this.result.of = this.task.total_correct_required;
    this.dataService.progressShow({stars: this.result.of});
    this.words = Utilities.shuffleArray(tempWords);
    //this.words = tempWords;
    /*    this.words.sort((a, b) => {         // Sorting now in HTML template using module ngx-order-pipe module
          return a.reference - b.reference;
        });*/
    this.introduceChallenge();
  };

  // ---------  Dragula functions -------------------

  private setupDragula() {
    this.dragulaService.setOptions('drag-bag7', {
      revertOnSpill: true,
      direction: 'horizontal',
      accepts: (el, target, source, sibling) => {
        let boxIndex = this.getBoxIndex(target);
        let dropBoxEmpty = !isNaN(boxIndex) && this['droppedItem' + boxIndex].length === 0;
        return target.id !== 'word-source-box' && dropBoxEmpty;
      },
      moves: (el, source, handle, sibling) => {
        return source.id === 'word-source-box' && this.opacityWords === 1 && !this.draggingNow; // don't prevent any drags from initiating by default
      }
    });

    this.dragulaDragSubscription = this.dragulaService.drag.subscribe((value) => {
      this.draggingNow = true;
    });

    this.dragulaCancelSubscription = this.dragulaService.cancel.subscribe((value) => {
      this.draggingNow = false;
    });

    this.dragulaDropSubscription = this.dragulaService.dropModel.subscribe((value) => {
      if (!this.cancelDropEvent) {
        this.onDrop();
      } else {
        this.cancelDropEvent = false;
        const [el, target, source, sibling] = value.slice(1);
        const boxIndex = this.getBoxIndex(target);
        const word = this['droppedItem' + boxIndex].pop();
        if (typeof word !== 'undefined') {
          setTimeout(() => {
            this.words.push(word);
            this.draggingNow = false;
          }, 100);
        }
      }
    });

    this.dragulaRemoveSubscription = this.dragulaService.remove.subscribe((value) => {
      console.log('removed');
    });
  }

  preventDropOnTap(event) {
    this.cancelDropEvent = true;
    setTimeout(() => {
      this.cancelDropEvent = false;
    }, 1000);
  };

  private removeAllWords() {
    let removedWord;
    if (typeof (removedWord = this.droppedItem1.pop()) !== 'undefined') {
      this.words.push(removedWord);
    }
    if (typeof (removedWord = this.droppedItem2.pop()) !== 'undefined') {
      this.words.push(removedWord);
    }
    if (typeof (removedWord = this.droppedItem3.pop()) !== 'undefined') {
      this.words.push(removedWord);
    }
    this.draggingNow = false;
  };

  trackByFn(index, station: Type7Word) {
    return station.reference;
  }

  private onDrop() {
    this.attempts++;
    let newCC;
    if (this.combinationBoxes === 2) {
      newCC = {
        firstWord: this.droppedItem1.length > 0 ? this.droppedItem1[0].reference : 0,
        secondWord: this.droppedItem3.length > 0 ? this.droppedItem3[0].reference : 0,
        thirdWord: 0,
        audio: null
      }
    } else {
      newCC = {
        firstWord: this.droppedItem1.length > 0 ? this.droppedItem1[0].reference : 0,
        secondWord: this.droppedItem2.length > 0 ? this.droppedItem2[0].reference : 0,
        thirdWord: this.droppedItem3.length > 0 ? this.droppedItem3[0].reference : 0,
        audio: null
      }
    }
    let cc: Type7Correct = new Type7Correct(newCC);

    let result: { status: string, correctRef: Type7Correct } = this.task.hasCombinationStatus(cc, this.combinationBoxes === 2 ? this.correctCombinations2 : this.correctCombinations3);    // Result is the correct combination including audio

    if (result.status === 'correct') {
      this.combinedWord = this.task.combinedWordFromCC(result.correctRef);
      if (this.discoveredCombinations.indexOf(this.combinedWord) === -1) {
        this.result.correct++;
        this.dataService.progress.results.answer_details
          .push({ attempt: this.task.wordsFromCC(cc), correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
        this.dataService.progress.completeAStar();
        // Fuse into one word
        this.closeStars();
        // Read completed word audio
        result.correctRef.playAudio();
        // Add completed word to completed list
        this.discoveredCombinations.push(this.combinedWord);
        // Remove this combination from the list of correct combinations
        const correctIndex = this['correctCombinations' + this.combinationBoxes].indexOf(result.correctRef);
        this['correctCombinations' + this.combinationBoxes].splice(correctIndex, 1);
        if (this.result.correct === this.result.of) {
          this.opacityWords = 0;
          setTimeout(() => {
            this.fadeOut();
          }, 1000)
        }
      }
      // Place all words back into 'words'
      setTimeout(() => {
        this.combinedWord = "";
        this.removeAllWords();
        this.chooseRandomBoxes();
      }, 2000);

    } else if (result.status === 'some') {
      this.draggingNow = false;
      // Deactivate the boxIndex position
    } else if (result.status === 'incorrect') {
      this.dataService.progress.results.incorrectAttempts++;
      this.dataService.progress.results.answer_details
        .push({ attempt: this.task.wordsFromCC(cc), correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
      setTimeout(() => {
        this.removeAllWords();
      }, 100);
    }

    // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
    // If number of attempts are limited to correct answers we will finish the task
    if (this.unforgivingTestMode && this.attempts === this.result.of && !this.finishDone) {
      this.opacityWords = 0;
      this.fadeOut();
    }
  }

  private chooseRandomBoxes() {
    this.openStars();
    setTimeout(() => {
      if (this.correctCombinations3.length === 0) {
        this.combinationBoxes = 2;
      } else if (this.correctCombinations2.length === 0) {
        this.combinationBoxes = 3;
      } else {
        this.combinationBoxes = Math.floor(Math.random() * 2) + 2;
      }
      this.openStars();
    }, 1000);
  }

  private getWordIndex(el: any) {
    return parseInt(el.id.substr(11), 10);
  }

  private getBoxIndex(target: any) {
    return parseInt(target.id.substr(9), 10);
  }


  // --------------------------------------------------


  openStars() {
    if (this.combinationBoxes === 3) {
      this.starLeftCss = {
        left: '70px'
      };
      this.starMiddleCss = {
        left: '265px'
      };
      this.starRightCss = {
        left: '460px'
      }
    } else {
      this.starLeftCss = {
        left: '60px'
      };
      this.starRightCss = {
        left: '399px'
      }
    }
  }

  closeStars() {
    if (this.combinationBoxes === 3) {
      this.starLeftCss = {
        left: '160px'
      };
      this.starMiddleCss = {
        left: '265px'
      };
      this.starRightCss = {
        left: '371px'
      }
    } else {
      this.starLeftCss = {
        left: '170px'
      };
      this.starRightCss = {
        left: '309px'
      }
    }
  }


  clickWord(item: Type7Word) {
    if (!this.audioPlaying && !this.draggingNow) {
      this.audioPlaying = true;
      item.playAudio(() => {
        this.audioPlaying = false;
      });
      this.dataService.progress.results.use_audio_content_items++;
    } else {
      this.draggingNow = false;
    }
  };

  introduceChallenge() {
    this.audioPlaying = true;
    this.attempts = 0;
    setTimeout(() => {
      this.opacity = 1;
      this.opacityWords = 1;
      let instructionAudio = new Audio('assets/sounds/task_instructions/type7.mp3');
      instructionAudio.addEventListener('ended', () => {
        this.audioPlaying = false;
        this.dataService.setSpeakerSound('assets/sounds/task_instructions/type7.mp3', null);
        this.dataService.speakerIsPlaying = false;
      });
      this.dataService.speakerIsPlaying = true;
      instructionAudio.play();
    }, 1000);
  };


  fadeOut() {
    if (!this.finishDone) {
      this.finishDone = true;
      if (this.dataService.taskMode === TaskMode.Warmups) {
        setTimeout(() => {
          const a = new Audio('assets/sounds/task_instructions/warmups/type7.mp3');
          a.addEventListener('ended', () => {
            this.finish();
          });
          a.play();
        }, 1000);
      } else {
        this.finish();
      }
    }
  };

  finish() {
    setTimeout(() => {
      setTimeout(() => {
        this.opacity = 0;
        this.completeTask();
      }, 500);
    }, 1000);
  };

  completeTask() {
    if (!this.taskCompleted) {
      this.taskCompleted = true;
      setTimeout(() => {
        this.dataService.completeTask();
      }, 1000)
    }
  };

}
