//import 'hammerjs';
import 'createjs-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { JSONParseWithDateModule } from './jsonparse-with-date/json-parse-with-date.module';
import { OrderBy } from "./pipes/OrderBy";

import { DragulaModule, DragulaService } from 'ng2-dragula-base/dist';
import { BrowserService } from './services/browser.service';
import { DataService } from './services/data.service';
import { ServerService } from './services/server.service';
import { CordovaService } from './services/cordova.service';

import { TaskComponent } from './views/task/task.component';
import { ProgressStarsComponent } from './views/task/progress/progress-stars/progress-stars.component';
import { ProgressBarComponent } from './views/task/progress/progress-bar/progress-bar.component';
import { LoginComponent } from './views/login/login.component';
import { ShipComponent } from './views/ship/ship.component';

import { Type1Component } from './tasks/type1/type1.component';
import { Type2Component } from './tasks/type2/type2.component';
import { Type3Component } from './tasks/type3/type3.component';
import { Type4Component } from './tasks/type4/type4.component';
import { Type5Component } from './tasks/type5/type5.component';
import { Type7Component } from './tasks/type7/type7.component';
import { Type8Component } from "./tasks/type8/type8.component";
import { Type9Component } from './tasks/type9/type9.component';
import { Type10Component } from "./tasks/type10/type10.component";
import { Type11Component } from "./tasks/type11/type11.component";
import { Type12Component } from './tasks/type12/type12.component';
import { Type13Component } from './tasks/type13/type13.component';

import { MapComponent } from './views/map/map.component';
import { SceneComponent } from './views/scene/scene.component';
import { Type6Component } from './tasks/type6/type6.component';
import { MessageService } from './services/message.service';
import { DrawingDirective } from './directives/drawing.directive';
import { CaretPositionDirective } from './directives/caret-position.directive';
import { TaskAnchorDirective } from './directives/task-anchor.directive';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { DelayComponent } from './views/delay/delay.component';
import { SpeakerComponent } from './views/task/speaker/speaker.component';
import { AdminComponent } from './views/admin/admin.component';


// declare let Hammer: any;
//
// export class MyHammerConfig extends HammerGestureConfig {
//     buildHammer(element: HTMLElement) {
//         return new Hammer(element, {
//             touchAction: "pan-y",
//         });
//     }
// }

const appRoutes: Routes = [
/*  { path: 'display/:position', component: SerendipitousViewComponent },
  { path: 'vote/:position', component: VotingViewComponent },
  { path: 'smaller', component: SmallerViewComponent },*/
  { path: 'scene/:random', component: SceneComponent },
  { path: 'map', component: MapComponent },
  { path: 'ship', component: ShipComponent },
  { path: 'sample/:tasktype/:taskid', component: TaskComponent },
  { path: 'task', component: TaskComponent },
  { path: 'delay', component: DelayComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent },
  { path: '', component: LoginComponent }
];

@NgModule({
  declarations: [
    OrderBy,
    AppComponent,
    TaskComponent,
    MapComponent,
    LoginComponent,
    ShipComponent,
    SceneComponent,
    DrawingDirective,
    CaretPositionDirective,
    TaskAnchorDirective,
    ProgressStarsComponent,
    ProgressBarComponent,
    Type1Component,
    Type2Component,
    Type3Component,
    Type4Component,
    Type5Component,
    Type6Component,
    Type7Component,
    Type8Component,
    Type9Component,
    Type10Component,
    Type11Component,
    Type12Component,
    Type13Component,
    DelayComponent,
    SpeakerComponent,
    AdminComponent
  ],
  imports: [
    JSONParseWithDateModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, useHash: true }
    ),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    DragulaModule
  ],
  providers: [
    BrowserService,
    //{ provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    DragulaService,
    MessageService,
    DataService,
    ServerService,
    CordovaService
  ],
  entryComponents: [
    Type1Component,
    Type2Component,
    Type3Component,
    Type4Component,
    Type5Component,
    Type6Component,
    Type7Component,
    Type8Component,
    Type9Component,
    Type10Component,
    Type11Component,
    Type12Component,
    Type13Component,
    DelayComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
