import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import * as fastClick from 'fastclick';
import * as createjs from 'createjs-module';
import * as smoothscroll from 'smoothscroll-polyfill';

// Remove 300ms delay on mobile devices
// fastClick.attach(document.body);

// Add smooth scroll polyfill
smoothscroll.polyfill();

// Attach createjs-custom-module script to the window object, so it can be seen by dynamically loaded scene scripts
let freeGlobal = (typeof window !== 'undefined' ? window : (typeof self !== 'undefined' ? self : {}));
freeGlobal['createjs'] = createjs;


if (environment.production) {
  enableProdMode();
}

let bootstrapAngular = () => {
  platformBrowserDynamic().bootstrapModule(AppModule).catch();
};

if (window.hasOwnProperty('cordova')) {
  document.addEventListener("deviceready", () => {
    bootstrapAngular();
  }, false);
} else {
  bootstrapAngular();
}


