import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import {DataService} from '../../services/data.service';
import {DragulaService} from 'ng2-dragula-base/dist';
import {TaskType10, Type10Word} from "../../models/task-type10.model";
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";

@Component({
  selector: 'app-type10',
  templateUrl: './type10.component.html',
  styleUrls: ['./type10.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        transition(
          ':enter', [
            style({ opacity: 0}),
            animate('500ms', style({'opacity': 1}))
          ]
        ),
        transition(
          ':leave', [
            style({'opacity': 1}),
            animate('500ms', style({'opacity': 0}))
          ]
        )]
    )
  ]
})
export class Type10Component implements OnInit, OnDestroy {

  public task: TaskType10 = null;
  // Display setup
  attempts = 0;
  opacity = 0;
  opacityWords = 0;

  public droppedItem1 = [];
  public displayedItem1: Type10Word = null;
  public droppedItem2 = [];
  public displayedItem2: Type10Word = null;
  combinedWord = "";

  draggingNow = false;
  finishDone = false;
  taskCompleted = false;
  audioPlaying = false;
  capitaliseDroppedItem = false;
  showPlaceholders = true;
  showCombinedWord = false;
  mergeCombinedWord = false;

  private cancelDropEvent: boolean;

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
  result = { correct: 0, of: 2, incorrectAttempts: 0, answer_details: [] };
  words = [];
  audioSentenceQueue = [];
  queuedAudio: HTMLAudioElement = null;

  private dragulaSubscription;
  private dragulaCancelSubscription;
  private dragulaDragSubscription;

  constructor(private dragulaService: DragulaService, private dataService: DataService) {
    this.setupDragula();
    this.cancelDropEvent = false;
  }

  ngOnInit() {

    // Begin the task..
    this.dataService.progressShow({ stars: 2 });
    // GroverService.setControllerState(self);

    this.task = <TaskType10> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnDestroy() {
    this.dragulaSubscription.unsubscribe();
    this.dragulaCancelSubscription.unsubscribe();
    this.dragulaDragSubscription.unsubscribe();
    this.dragulaService.destroy('drag-bag10');
  }

  setupTask() {
    this.finishDone = false;
    this.audioSentenceQueue = [];
    this.displayedItem1 = null;
    this.displayedItem2 = null;
    this.showPlaceholders = true;
    this.showCombinedWord = false;
    this.mergeCombinedWord = false;

    this.audioPlaying = false;
    this.taskCompleted = false;
    this.combinedWord = "";
    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 10 task does not exist - check your Session layout in the CMS');
      return;
    }
    let preselectedWord = this.task.preselected_word;
    if (preselectedWord.text !== '' && preselectedWord.correct !== 0) {
      this['droppedItem' + preselectedWord.correct].push(preselectedWord);
      this['displayedItem' + preselectedWord.correct] = preselectedWord;
    }

    // Capitalise first word if no prior sentence
    if (this.task.sentence2.text_before === '') {
      this.capitaliseDroppedItem = true;
    }

    let tempWords = [];
    for (let i = 1; i < 6; i++) {
      const theWord: Type10Word = this.task['word' + i];
      if (theWord.text !== '') {
        tempWords.push(theWord);
      }
    }
    this.result.of = 1;
    this.dataService.progressShow({stars: this.result.of});
    this.words = Utilities.shuffleArray(tempWords);
    this.introduceChallenge();
  };

  // ---------  Dragula functions -------------------

  private setupDragula() {
    this.dragulaService.setOptions('drag-bag10', {
      revertOnSpill: true,
      direction: 'horizontal',
      accepts: (el, target, source, sibling) => {
        let boxIndex = this.getBoxIndex(target);
        let dropBoxEmpty = !isNaN(boxIndex) && this['droppedItem' + boxIndex].length === 0;
        return target.id !== 'word-source-box' && dropBoxEmpty;
      },
      moves: (el, source, handle, sibling) => {
        return source.id === 'word-source-box' && this.opacityWords === 1 && !this.draggingNow; // don't prevent any drags from initiating by default
      }
    });

    this.dragulaDragSubscription = this.dragulaService.drag.subscribe((value) => {
      this.draggingNow = true;
    });

    this.dragulaCancelSubscription = this.dragulaService.cancel.subscribe((value) => {
      this.draggingNow = false;
    });

    this.dragulaSubscription = this.dragulaService.dropModel.subscribe((value) => {
      if (!this.cancelDropEvent) {
        this.onDrop(value.slice(1));
      } else {
        this.cancelDropEvent = false;
        const [el, target, source, sibling] = value.slice(1);
        const boxIndex = this.getBoxIndex(target);
        const word = this['droppedItem' + boxIndex].pop();
        if (typeof word !== 'undefined') {
          setTimeout(() => {
            this.words.push(word);
            this.draggingNow = false;
          }, 100);
        }
      }
    });
  }

  private getWordIndex(el: any) {
    return parseInt(el.id.substr(11), 10);
  }

  private getBoxIndex(target: any) {
    return parseInt(target.id.substr(9), 10);
  }

  // --------------- Audio -----------------------------


  playWordAudio(word: Type10Word) {
    if (word && !this.audioPlaying) {
      this.audioPlaying = true;
      word.playAudio(() => {
        this.audioPlaying = false;
        if (word === this.displayedItem2) {
          if (typeof this.task.sentence2.audio_after !== 'undefined' && this.task.sentence2.audio_after.url !== null) {
            this.queueAudio(this.task.sentence2.audio_after.url, 500);
          }
        }
      });
    }
    this.dataService.progress.results.use_audio_content_items++;
  }

  clickFirstSentence() {
    if (typeof this.task.sentence1.audio !== 'undefined' && this.task.sentence1.audio.url !== null) {
      this.queueAudio(this.task.sentence1.audio.url);
      this.dataService.progress.results.use_audio_content_items++;
    }
  }
  clickSecondSentenceBefore() {
    if (typeof this.task.sentence2.audio_before !== 'undefined' && this.task.sentence2.audio_before.url !== null) {
      this.queueAudio(this.task.sentence2.audio_before.url);
      this.dataService.progress.results.use_audio_content_items++;
    }
  }
  clickSecondSentenceAfter() {
    if (typeof this.task.sentence2.audio_after !== 'undefined' && this.task.sentence2.audio_after.url !== null) {
      if (this.displayedItem2 !== null) {
        this.queueAudio(this.displayedItem2.audio.url);
      }
      this.queueAudio(this.task.sentence2.audio_after.url);
      this.dataService.progress.results.use_audio_content_items++;
    }
  }
  playCompleteSentence() {
    if (typeof this.task.sentence2.audio_complete !== 'undefined' && this.task.sentence2.audio_complete.url !== null) {

      if (this.queuedAudio !== null) {
        this.queuedAudio.pause();
      }
      this.queuedAudio = new Audio(this.task.sentence2.audio_complete.url);
      this.audioPlaying = true;
      this.queuedAudio.play().catch();
      this.queuedAudio.addEventListener('ended', () => {

        // Check the allowed number of attempts here - cancel the previous audio if ending
        // If number of attempts are limited to correct answers we will finish the task
        if ((this.result.correct === this.result.of) || (this.task.unforgiving && this.attempts === this.result.of && !this.finishDone)) {
          this.checkForWarmup();
        }

      });
    } else {
      if ((this.result.correct === this.result.of) || (this.task.unforgiving && this.attempts === this.result.of && !this.finishDone)) {
        this.checkForWarmup();
      }
    }
  }
  queueAudio(audioUrl, delay = 0) {
    if (this.audioSentenceQueue.length <= 2 && !this.finishDone) {   // Don't allow too many sounds to queue
      if (this.audioPlaying) {
        this.audioSentenceQueue.push(audioUrl);
      } else {
        this.queuedAudio = new Audio(audioUrl);
        this.queuedAudio.addEventListener("ended", () => {
          this.audioPlaying = false;
          if (this.audioSentenceQueue.length > 0) {
            this.queueAudio(this.audioSentenceQueue.pop(), 500);
          }
        });
        this.audioPlaying = true;
        setTimeout(() => {
          this.queuedAudio.play().catch();
        }, delay);
      }
    }
  };

  // --------------------------------------------------

  introduceChallenge() {
    this.attempts = 0;
    this.opacityWords = 1;
    // Introduce with instruction audio
    setTimeout(() => {
      this.opacity = 1;
      if(this.task.introduction_audio.url) {
        let instructionAudio = new Audio(this.task.introduction_audio.url);
        instructionAudio.addEventListener('ended', () => {
          this.audioPlaying = false;
          this.dataService.setSpeakerSound(this.task.introduction_audio.url, null);
          this.dataService.speakerIsPlaying = false;
          this.queueAudio(this.task.sentence1.audio.url, 500);
          this.queueAudio(this.task.sentence2.audio_before.url);
        });
        instructionAudio.play();
        this.audioPlaying = true;
        this.dataService.speakerIsPlaying = true;
      } else {
        this.queueAudio(this.task.sentence1.audio.url, 500);
        this.queueAudio(this.task.sentence2.audio_before.url);
      }
    }, 1000);
  };

  preventDropOnTap(event) {
    this.cancelDropEvent = true;
    setTimeout(() => {
      this.cancelDropEvent = false;
    }, 1000);
  };

  removeAllWords() {
    let removeWord: Type10Word = this.droppedItem1[0];
    if (typeof removeWord !== 'undefined' && removeWord.reference !== this.task.preselected_word.reference) {
      this.words.push(this.droppedItem1.pop());
      this.displayedItem1 = null;
    }
    removeWord = this.droppedItem2[0];
    if (typeof removeWord !== 'undefined' && removeWord.reference !== this.task.preselected_word.reference) {
      this.words.push(this.droppedItem2.pop());
      this.displayedItem2 = null;
    }
    this.words = Utilities.shuffleArray(this.words);
    this.draggingNow = false;
  };

  private onDrop(args) {
    const [el, target, source, sibling] = args;
    this.attempts++;

    let checkWordCombination = (): string => {
      let status = 'incorrect';

      let a = this.droppedItem1.length > 0 ? this.droppedItem1[0].correct : -1;
      let b = this.droppedItem2.length > 0 ? this.droppedItem2[0].correct : -1;
      if ((a === -1 && b === 2) || (a === 1 && b === -1)) {
        status = 'some';
      } else if (a === 1 && b === 2) {
        status = 'correct';
      }
      return status;
    };

    let status = checkWordCombination();

    if (this.task.unforgiving) {

      if (this.droppedItem1.length > 0) {
        this.displayedItem1 = this.droppedItem1[0];
      }
      if (this.droppedItem2.length > 0) {
        this.displayedItem2 = this.droppedItem2[0];
      }
      this.draggingNow = false;
      this.dataService.progress.starData.completed++;

      if (status === 'correct') {
          this.result.correct++;
          this.dataService.progress.results.answer_details.push({
              attempt: (this.droppedItem1.length > 0 ? this.droppedItem1[0].text : '')
              + (this.droppedItem1.length > 0 && this.droppedItem2.length > 0 ? ', ' : '')
              + (this.droppedItem2.length > 0 ? this.droppedItem2[0].text : ''),
              correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall
            });

      } else if (status === 'some') {

      } else if (status === 'incorrect') {
        this.dataService.progress.results.incorrectAttempts++;
        this.dataService.progress.results.answer_details.push({
          attempt: (this.droppedItem1.length > 0 ? this.droppedItem1[0].text : '')
          + (this.droppedItem1.length > 0 && this.droppedItem2.length > 0 ? ', ' : '')
          + (this.droppedItem2.length > 0 ? this.droppedItem2[0].text : ''),
          correct: false
        });
      }

      if (this.attempts === this.result.of && !this.finishDone) {
        if (this.queuedAudio !== null) {
          this.queuedAudio.pause();
        }
        this.fadeOut();
      }

    } else {

      if (status === 'correct') {
        if (this.droppedItem1.length > 0) {
          this.displayedItem1 = this.droppedItem1[0];
        }
        if (this.droppedItem2.length > 0) {
          this.displayedItem2 = this.droppedItem2[0];
        }

        setTimeout(() => {
          this.showPlaceholders = false;
          this.combinedWord = this.task.sentence2.trailing_word + (this.droppedItem1.length > 0 ? this.droppedItem1[0].text : '')
            + (this.droppedItem2.length > 0 ? this.droppedItem2[0].text : '') + this.task.sentence2.second_trailing_word;
          this.result.correct++;
          this.dataService.progress.starData.completed++;
          this.dataService.progress.results.answer_details
            .push({
              attempt: (this.droppedItem1.length > 0 ? this.droppedItem1[0].text : '')
              + (this.droppedItem1.length > 0 && this.droppedItem2.length > 0 ? ', ' : '')
              + (this.droppedItem2.length > 0 ? this.droppedItem2[0].text : ''),
              correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall
            });
          // Fuse into one word
          if ((this.result.correct === this.result.of) || (this.task.unforgiving && this.attempts === this.result.of && !this.finishDone)) {
            this.opacityWords = 0;
          }
          // Read completed word audio
          this.playCompleteSentence();
          setTimeout(() => {
            this.showCombinedWord = true;
            setTimeout(() => {
              this.mergeCombinedWord = true;
            }, 1000)
          }, 1000);
        }, 1000)

      } else if (status === 'some') {

        if (this.droppedItem1.length > 0) {
          this.displayedItem1 = this.droppedItem1[0];
        }
        if (this.droppedItem2.length > 0) {
          this.displayedItem2 = this.droppedItem2[0];
        }
        this.draggingNow = false;
        // Deactivate the boxIndex position

      } else if (status === 'incorrect') {
        this.dataService.progress.results.incorrectAttempts++;
        this.dataService.progress.results.answer_details.push({
          attempt: (this.droppedItem1.length > 0 ? this.droppedItem1[0].text : '')
          + (this.droppedItem1.length > 0 && this.droppedItem2.length > 0 ? ', ' : '')
          + (this.droppedItem2.length > 0 ? this.droppedItem2[0].text : ''),
          correct: false
        });
        setTimeout(() => {
          this.removeAllWords();
        }, 100);
      }
    }
  }

  checkForWarmup() {
    if (this.dataService.taskMode === TaskMode.Warmups) {
      setTimeout(() => {
        const a = new Audio('assets/sounds/task_instructions/warmups/type10.mp3');
        a.addEventListener('ended', () => {
          this.fadeOut();
        });
        a.play();
      }, 1000);
    } else {
      this.fadeOut(true);
    }
  }

  fadeOut(long?: boolean) {
    if (!this.finishDone) {
      this.finishDone = true;
      this.opacityWords = 0;
      setTimeout(() => {
        this.opacity = 0;
        this.completeTask();
      }, long ? 3000 : 1500);
    }
  };

  completeTask() {
    if (!this.taskCompleted) {
      this.taskCompleted = true;
      setTimeout(() => {
        this.dataService.completeTask();
      }, 1000)
    }
  };

}
