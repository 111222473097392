import {Task} from './task.model';
import {Constants} from '../constants';

export class Type10Word {
  text: string;
  type?: string;
  correct: number;
  audio: { url: string; };
  reference?: number;
  displayText?: string;

  constructor(data: {}) {
    this.text = '';
    this.correct = 0;
    this.audio = {url: null};
    if (data) {
      this.text = data['text'];
      // this.type = data['type'];
      this.correct = data['correct'];
      if (data.hasOwnProperty('audio')) {
        this.audio.url = data['audio'].url
      }
      /*    if (this.type === 'prefix') {
            this.displayText = this.text + '-';
          } else if (this.type === 'suffix') {
            this.displayText = '-' + this.text;
          } else {
            this.displayText = this.text;
          }*/
      this.displayText = this.text;
    }
  }

  public playAudio(callback) {
    if (this.audio.url !== null) {
      let a = new Audio(this.audio.url);
      a.addEventListener('ended', () => {
        if (callback) {
          callback();
        }
      });
      a.play();
    } else {
      if (callback) {
        callback();
      }
    }
  }
}

export class TaskType10 extends Task {
  morph: string = "";
  affix: string = "";
  category: string = "";
  morf_category: string;
  unforgiving: boolean;

  introduction_audio: { url: string; };

  sentence1: {
    text: string;
    audio: { url: string; }
  };
  sentence2: {
    text_before: string;
    trailing_word: string;
    audio_before: { url: string; },
    audio_after: { url: string; },
    text_after: string;
    second_trailing_word: string;
    audio_complete: { url: string; }
  };
  word1: Type10Word;
  word2: Type10Word;
  word3: Type10Word;
  word4: Type10Word;
  word5: Type10Word;
  preselected_word: Type10Word;


  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.affix = input['affix'];
    this.morf_category = input['morf_category'];
    this.unforgiving = input['unforgiving'];

    this.introduction_audio = { url: null };
    if (input.hasOwnProperty('introduction_audio')) {
      this.introduction_audio.url = input['introduction_audio'].url
    }

    this.sentence1 = input['sentence1'];
    this.sentence2 = input['sentence2'];
    if (!input['sentence2'].trailing_word) {
      this.sentence2.trailing_word = '';
    }
    if (!input['sentence2'].second_trailing_word) {
      this.sentence2.second_trailing_word = '';
    }
    this.preselected_word = new Type10Word(input['preselected_word']);
    this.preselected_word.reference = 0;

    for (let w = 1; w < 6; w++) {
      this['word' + w] = new Type10Word(input['word' + w]);
      this['word' + w].reference = w;
    }

    return this;
  }


  rewriteUrlsToLocalAssets(input: Object) {

    if (this.word1 && this.word1.audio && this.word1.audio.url) {
      this.word1.audio.url =
        Constants.localAssetUrl + input['word1']['audio']['path'] + '/' + input['word1']['audio']['filename'];
    }
    if (this.word2 && this.word2.audio && this.word2.audio.url) {
      this.word2.audio.url =
        Constants.localAssetUrl + input['word2']['audio']['path'] + '/' + input['word2']['audio']['filename'];
    }
    if (this.word3 && this.word3.audio && this.word3.audio.url) {
      this.word3.audio.url =
        Constants.localAssetUrl + input['word3']['audio']['path'] + '/' + input['word3']['audio']['filename'];
    }
    if (this.word4 && this.word4.audio && this.word4.audio.url) {
      this.word4.audio.url =
        Constants.localAssetUrl + input['word4']['audio']['path'] + '/' + input['word4']['audio']['filename'];
    }
    if (this.word5 && this.word5.audio && this.word5.audio.url) {
      this.word5.audio.url =
        Constants.localAssetUrl + input['word5']['audio']['path'] + '/' + input['word5']['audio']['filename'];
    }
    if (this.preselected_word && this.preselected_word.audio && this.preselected_word.audio.url) {
      this.preselected_word.audio.url =
        Constants.localAssetUrl + input['preselected_word']['audio']['path'] + '/' + input['preselected_word']['audio']['filename'];
    }

    if (this.introduction_audio.url) {
      this.introduction_audio.url =
        Constants.localAssetUrl + input['introduction_audio']['path'] + '/' + input['introduction_audio']['filename'];
    }

    if (this.sentence1 && this.sentence1.audio && this.sentence1.audio.url) {
      this.sentence1.audio.url =
        Constants.localAssetUrl + input['sentence1']['audio']['path'] + '/' + input['sentence1']['audio']['filename'];
    }
    if (this.sentence2) {
      if (this.sentence2.audio_before && this.sentence2.audio_before.url) {
        this.sentence2.audio_before.url =
          Constants.localAssetUrl + input['sentence2']['audio_before']['path'] + '/' + input['sentence2']['audio_before']['filename'];
      }
      if (this.sentence2.audio_after && this.sentence2.audio_after.url) {
        this.sentence2.audio_after.url =
          Constants.localAssetUrl + input['sentence2']['audio_after']['path'] + '/' + input['sentence2']['audio_after']['filename'];
      }
      if (this.sentence2.audio_complete && this.sentence2.audio_complete.url) {
        this.sentence2.audio_complete.url =
          Constants.localAssetUrl + input['sentence2']['audio_complete']['path'] + '/' + input['sentence2']['audio_complete']['filename'];
      }
    }
  }

}
