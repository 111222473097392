import {Task} from './task.model';
import {Constants} from '../constants';

export class TaskType1 extends Task {
  morph: string = "";
  stem: string = "";
  morphedStem: string = "";
  affix: string = "";
  category: string = "";
  example: {
    stemImage: { url: string; };
    stemAudio: { url: string; };
    stemAudioText: string;
    captionBeforeStem: string;
    customStem: string;
    captionAfterStem: string;

    morphedStemImage: { url: string; };
    morphedStemAudio: { url: string; };
    morphedStemAudioText: string;
    captionBeforeMorphedStem: string;
    customMorphedStem: string;
    captionAfterMorphedStem: string;
  };
  challenge: {
    instructionBeforeWord: string;
    word: string;
    wordAudio: { url: string; };
    instructionAfterWord: string;
    instructionAudio: { url: string; };
    instructionAudioText: string;
    image1: { url: string; };
    image2: { url: string; };
    correctImage: string;
  };

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.stem = input['stem'];
    this.morphedStem = input['morphedStem'];
    this.affix = input['affix'];
    this.example = input['example'];
    this.challenge = input['challenge'];
    return this;
  }

  rewriteUrlsToLocalAssets(input: Object) {
    if (this.example) {
      if (this.example.stemImage) {
        this.example.stemImage.url =
          Constants.localAssetUrl + input['example']['stemImage']['path'] + '/' + input['example']['stemImage']['filename'];
      }
      if (this.example.stemAudio) {
        this.example.stemAudio.url =
          Constants.localAssetUrl + input['example']['stemAudio']['path'] + '/' + input['example']['stemAudio']['filename'];
      }
      if (this.example.morphedStemImage) {
        this.example.morphedStemImage.url =
          Constants.localAssetUrl + input['example']['morphedStemImage']['path'] + '/' + input['example']['morphedStemImage']['filename'];
      }
      if (this.example.morphedStemAudio) {
        this.example.morphedStemAudio.url =
          Constants.localAssetUrl + input['example']['morphedStemAudio']['path'] + '/' + input['example']['morphedStemAudio']['filename'];
      }
    }
    if (this.challenge) {
      if (this.challenge.image1) {
        this.challenge.image1.url =
          Constants.localAssetUrl + input['challenge']['image1']['path'] + '/' + input['challenge']['image1']['filename'];
      }
      if (this.challenge.image2) {
        this.challenge.image2.url =
          Constants.localAssetUrl + input['challenge']['image2']['path'] + '/' + input['challenge']['image2']['filename'];
      }
      if (this.challenge.instructionAudio) {
        this.challenge.instructionAudio.url =
          Constants.localAssetUrl + input['challenge']['instructionAudio']['path'] + '/' + input['challenge']['instructionAudio']['filename'];
      }
      if (this.challenge.wordAudio) {
        this.challenge.wordAudio.url =
          Constants.localAssetUrl + input['challenge']['wordAudio']['path'] + '/' + input['challenge']['wordAudio']['filename'];
      }
    }
  }

}
