import { Component, OnInit } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import {DataService} from "../../services/data.service";
import {TaskType12} from "../../models/task-type12.model";
import {TaskLog12} from "../../models/general.model";
import {Utilities} from "../../utilities/utilities";

@Component({
  selector: 'app-type12',
  templateUrl: './type12.component.html',
  styleUrls: ['./type12.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        transition(
          ':enter', [
            style({ opacity: 0}),
            animate('500ms', style({'opacity': 1}))
          ]
        ),
        transition(
          ':leave', [
            style({'opacity': 1}),
            animate('500ms', style({'opacity': 0}))
          ]
        )]
    )
  ]
})
export class Type12Component implements OnInit {

  task: TaskType12;
  result: { correct: number; of: number; incorrectAttempts: number; answer_details: TaskLog12[] };

  unforgivingTestMode = false;
  attempts = 0;
  submitActive = false;
  opacity = 1;
  inputWordForCaret = "";
  wordInput = "";
  caretLocation = "start";
  showNumberCount = false;
  answerList = [];
  inputColour = '#FFFFFF';
  addIsActive = false;
  editingWord = '';

  startTime = Date.now();

  constructor(private dataService: DataService) {
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    // GroverService.setControllerState(self);
    this.result = { correct: 0, of: 1, incorrectAttempts: 0, answer_details: [] };
    this.task = <TaskType12> this.dataService.currentTask;
  }

  ngOnInit() {
    if (this.task.affix === 'prefix') {
      this.caretLocation = 'end';
    } else if (this.task.affix === 'suffix') {
      this.caretLocation = 'start';
    }
    this.dataService.progressShow({ stars: 1 });
    setTimeout(() => {
      this.opacity = 1;
      setTimeout(() => {
        this.showNumberCount = true;
        this.beepTheMorfem(this.task.word);
        setTimeout(() => {
          this.introduceChallenge();
        }, 1000);
      }, 1000);
    }, 1000)
  }

/*  introduceChallenge() {
    this.attempts = 0;
    setTimeout(() => {
      let instructionAudio1 = new Audio('assets/sounds/task_instructions/type12_compound_start.mp3');
      let instructionAudio2;
      let instructionAudio3 = new Audio('assets/sounds/task_instructions/type12_ending_alt2.mp3');
      if (this.task.introduction_audio.url !== null) {
        instructionAudio2 = new Audio(this.task.introduction_audio.url);
        instructionAudio2.addEventListener('ended', () => {
          setTimeout(() => {
            instructionAudio3.play();
          }, 1000);
        });
      }
      if (this.task.word_category === 'Compound word') {
        instructionAudio1.addEventListener('ended', () => {
          if (this.task.introduction_audio.url !== null) {
            instructionAudio2.play();
          } else {
            setTimeout(() => {
              instructionAudio3.play();
            }, 1000);
          }
        });
        instructionAudio3.addEventListener('ended', () => {
          this.dataService.setSpeakerSound('assets/sounds/task_instructions/type12_compound_start.mp3', this.task.introduction_audio.url);
          this.dataService.speakerIsPlaying = false;
        });
        instructionAudio1.play();
      } else {
        instructionAudio3.addEventListener('ended', () => {
          this.dataService.setSpeakerSound(this.task.introduction_audio.url, 'assets/sounds/task_instructions/type12_ending_alt2.mp3');
          this.dataService.speakerIsPlaying = false;
        });
        if (this.task.introduction_audio.url !== null) {
          instructionAudio2.play();
        } else {
          setTimeout(() => {
            instructionAudio3.play();
          }, 1000);
        }
      }
      this.dataService.speakerIsPlaying = true;
    }, 1000);
  };*/

  introduceChallenge() {
    this.attempts = 0;
    setTimeout(() => {
      let instructionAudio2;
      if (this.task.introduction_audio.url !== null) {
        instructionAudio2 = new Audio(this.task.introduction_audio.url);
        instructionAudio2.addEventListener('ended', () => {
          this.dataService.setSpeakerSound(this.task.introduction_audio.url, null);
          this.dataService.speakerIsPlaying = false;
        });
        this.dataService.speakerIsPlaying = true;
        instructionAudio2.play();
      }
    }, 1000);
  };

  editWord(word) {
    this.inputColour = '#00ff45';
    this.editingWord = word;
    this.beepTheMorfem(word);
  }

  confirmEdit(newWord) {
    if (newWord.length > 0 && newWord.length < 30 && this.answerList.indexOf(newWord) === -1) {
      const i = this.answerList.indexOf(this.editingWord);
      this.answerList[i] = newWord;
      const j = this.result.answer_details.find((t) => {
        return t.attempt === this.editingWord;
      });
      j.attempt = newWord;
      j.edited = true;
      j.elapsed = Date.now() - this.startTime;
      this.startTime = Date.now();
    }
    this.cancelEdit();
  }

  cancelEdit() {
    this.editingWord = '';
    this.wordInput = '';
    this.inputColour = '#FFFFFF';
    this.beepTheMorfem(this.task.word);
  }

  filterTextCharacters(text) {
    this.wordInput = Utilities.filterTextCharacters(text);
  }

  scrollResultsToBottom() {
    let el = document.getElementById('scrollingList');
    el.scrollTop = el.scrollHeight;
  }

  showTypedResponse(response: string) {
    this.inputColour = '#FF0000';
    this.wordInput = response;
    setTimeout(() => {
      this.inputColour = '#FFFFFF';
      this.wordInput = '';
      this.beepTheMorfem(this.task.word);
    }, 2000);
  }

  addAnswer(value: string) {
    // vett the answer
    // if ok add to answerList
    if (!this.addIsActive) {
      return;
    } else if (this.editingWord !== '') {
      this.confirmEdit(value);
    } else if (value.length > 0 && value.length < 30 && this.answerList.indexOf(value) === -1) {
      this.addIsActive = false;
      this.dataService.progress.starData.completed = 1;
      this.answerList.push(value.toLowerCase());
      this.result.answer_details.push({attempt: value, edited: false, elapsed: Date.now() - this.startTime});
      this.startTime = Date.now();
      this.inputWordForCaret = '';
      this.wordInput = '';
      if (this.answerList.length > 0) {
        this.submitActive = true;
      }
      this.showNumberCount = true;
      setTimeout(() => {
        this.scrollResultsToBottom();
        this.dataService.progress.starData.completed = 0;
        this.beepTheMorfem(this.task.word);
      }, 1500);
    } else {
      this.addIsActive = false;
      let response;
      if (value.length >= 15) {
        response = "for langt!";
      } else if (value.length === 0) {
        response = "for kort!";
      } else if (this.answerList.indexOf(value) > -1) {
        response = "ordet har blitt brukt!";
      }
      this.showTypedResponse(response);
    }
  }

  beepTheMorfem(sourceWord) {
    let beepAudio = [new Audio("assets/sounds/general/beep.mp3"), new Audio("assets/sounds/general/beep.mp3"), new Audio("assets/sounds/general/beep.mp3")];
    let counter = 0;
    let constructedWord = "";
    let theInterval = setInterval(() => {
      if (counter === sourceWord.length) {
        clearInterval(theInterval);
        this.inputWordForCaret = sourceWord;
        this.showNumberCount = false;
        this.addIsActive = true;
      } else {
        beepAudio[counter % 3].play();
        if (this.caretLocation === 'end') {
          constructedWord += sourceWord.charAt(counter);
        } else {
          constructedWord = sourceWord.charAt(sourceWord.length - counter - 1) + constructedWord;
        }
        this.wordInput = constructedWord;
        counter++;
      }
    }, 200);

  }

  finish() {
    if (this.submitActive) {
      setTimeout(() => {
        this.opacity = 0;
        this.completeTask();
      }, 500);
    }
  };

  focusOnInput(id) {
    let element = document.getElementById(id);
    // let element = document.getElementById('pin1');
    if (element) {
      element.focus();
    }
  };

  completeTask() {
    setTimeout(() => {
      this.dataService.progress.results.answer_details = this.result.answer_details;
      this.dataService.completeTask();
    }, 1000)
  };

}
