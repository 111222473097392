import {Task} from './task.model';
import {Constants} from '../constants';


export class Type13Answer {
  text: string;
  correct: number;
  audio: { url: string; };
  checked?: boolean;
  reference?: number;

  constructor(data: {}) {
    this.text = '';
    this.correct = 0;
    this.audio = {url: null};
    if (data) {
      this.text = data['text'];
      this.correct = data['correct'];
      this.checked = false;
      if (data.hasOwnProperty('audio')) {
        this.audio.url = data['audio'].url
      }
    }
  }

  public playAudio(callback) {
    if (this.audio.url !== null) {
      const a = new Audio(this.audio.url);
      a.addEventListener('ended', () => {
        callback();
      });
      a.play().catch();
    } else {
      callback();
    }
  }
}

export class TaskType13 extends Task {
  morph: string = "";
  affix: string = "";
  category: string = "";
  morf_category: string;

  text_before: string;
  word: string;
  text_after: string;
  audio: { url: string };

  answer1: Type13Answer;
  answer2: Type13Answer;
  answer3: Type13Answer;
  answer4: Type13Answer;
  answer5: Type13Answer;
  answer6: Type13Answer;

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.affix = input['affix'];
    this.morf_category = input['morf_category'];

    this.text_before = input['text_before'];
    this.word = input['word'];
    this.text_after = input['text_after'];
    this.audio = { url: null };
    if (input.hasOwnProperty('audio') && input['audio'].url !== null) {
      this.audio.url = input['audio'].url;
    }

    for (let i = 1; i < 7; i++) {
      this['answer' + i] = new Type13Answer(input['answer' + i]);
      this['answer' + i].reference = i;
    }

    return this;
  }

  rewriteUrlsToLocalAssets(input: Object) {

    if (this.answer1 && this.answer1.audio && this.answer1.audio.url) {
      this.answer1.audio.url =
        Constants.localAssetUrl + input['answer1']['audio']['path'] + '/' + input['answer1']['audio']['filename'];
    }
    if (this.answer2 && this.answer2.audio && this.answer2.audio.url) {
      this.answer2.audio.url =
        Constants.localAssetUrl + input['answer2']['audio']['path'] + '/' + input['answer2']['audio']['filename'];
    }
    if (this.answer3 && this.answer3.audio && this.answer3.audio.url) {
      this.answer3.audio.url =
        Constants.localAssetUrl + input['answer3']['audio']['path'] + '/' + input['answer3']['audio']['filename'];
    }
    if (this.answer4 && this.answer4.audio && this.answer4.audio.url) {
      this.answer4.audio.url =
        Constants.localAssetUrl + input['answer4']['audio']['path'] + '/' + input['answer4']['audio']['filename'];
    }
    if (this.answer5 && this.answer5.audio && this.answer5.audio.url) {
      this.answer5.audio.url =
        Constants.localAssetUrl + input['answer5']['audio']['path'] + '/' + input['answer5']['audio']['filename'];
    }
    if (this.answer6 && this.answer6.audio && this.answer6.audio.url) {
      this.answer6.audio.url =
        Constants.localAssetUrl + input['answer6']['audio']['path'] + '/' + input['answer6']['audio']['filename'];
    }
    if (this.audio && this.audio.url) {
      this.audio.url =
        Constants.localAssetUrl + input['audio']['path'] + '/' + input['audio']['filename'];
    }

  }

}
