import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';

export class Message {

  public _message: string;
  public _args: {};
  constructor(public message: string, public args?: {}) {
    this._message = this.message;
    if (args) {
      this._args = args;
    } else {
      this._args = null;
    }
  }
}


@Injectable()
export class MessageService {

  private _messageSubject: Subject<Message>;
  private _message: Message;

  constructor() {
    this._messageSubject = new Subject();
  }

  public get messageSubject(): Observable<Message> {
    return this._messageSubject.asObservable();
  }

  public sendMessage(message: string, args?: {}): void {
    this._messageSubject.next(new Message(message, args));
  }

}
