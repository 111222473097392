import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import {TaskType1} from '../../models/task-type1.model';
import {TaskMode} from '../../models/general.model';

@Component({
  selector: 'app-type1',
  templateUrl: './type1.component.html',
  styleUrls: ['./type1.component.css']
})
export class Type1Component implements OnInit {

  public task: TaskType1 = null;
  // Display setup
  showing = 'example';
  opacity = 0;
  challengeActive = false;
  playingAudio = false;
  textAudio = null;

  exampleLeft = {
    imageUrl: '',
    audioUrl: '',
    captionBefore: '',
    word: '',
    captionAfter: '',
    borderGreen: false,
    borderRed: false,
    highlight: false,
    opacity: 0
  };
  exampleRight = {
    imageUrl: '',
    audioUrl: '',
    captionBefore: '',
    word: '',
    captionAfter: '',
    borderGreen: false,
    borderRed: false,
    highlight: false,
    opacity: 0
  };
  challengeLeft = {
    imageUrl: null,
    borderGreen: false,
    borderRed: false,
    opacity: 0,
    correct: false
  };
  challengeRight = {
    imageUrl: null,
    borderGreen: false,
    borderRed: false,
    opacity: 0,
    correct: false
  };
  unforgivingTestMode = false;

  constructor(private dataService: DataService) {

    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    this.dataService.progressShow({ stars: 1 });
     // GroverService.setControllerState(self);

    this.task = <TaskType1> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnInit() {
  }


  setupTask() {

    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 1 task does not exist - check your Session layout in the CMS');
      return;
    }
    this.playingAudio = false;
    this.exampleLeft.imageUrl = typeof this.task.example.stemImage !== 'undefined' ? this.task.example.stemImage.url : '';
    this.exampleRight.imageUrl = typeof this.task.example.morphedStemImage !== 'undefined' ? this.task.example.morphedStemImage.url : '';
    this.exampleLeft.audioUrl = typeof this.task.example.stemAudio !== 'undefined' ? this.task.example.stemAudio.url : '';
    this.exampleRight.audioUrl = typeof this.task.example.morphedStemAudio !== 'undefined' ? this.task.example.morphedStemAudio.url : '';
    this.exampleLeft.captionBefore = this.task.example.captionBeforeStem;
    this.exampleRight.captionBefore = this.task.example.captionBeforeMorphedStem;
    this.exampleLeft.word = this.task.example.customStem || this.task.stem;
    this.exampleRight.word = this.task.example.customMorphedStem || this.task.morphedStem;
    this.exampleLeft.captionAfter = this.task.example.captionAfterStem;
    this.exampleRight.captionAfter = this.task.example.captionAfterMorphedStem;

    this.challengeLeft.imageUrl = typeof this.task.challenge.image1 !== 'undefined' ? this.task.challenge.image1.url : '';
    this.challengeRight.imageUrl = typeof this.task.challenge.image2 !== 'undefined' ? this.task.challenge.image2.url : '';
    this.challengeLeft.correct = this.task.challenge.correctImage === '1';
    this.challengeRight.correct = !this.challengeLeft.correct;

    if (typeof this.task.challenge.wordAudio !== 'undefined' && this.task.challenge.wordAudio.url !== null) {
      this.textAudio = new Audio(this.task.challenge.wordAudio.url);
      this.textAudio.addEventListener('ended', () => {
        this.playingAudio = false;
      });
    }

    setTimeout(() => {
      this.opacity = 1;
      this.exampleLeft.opacity = 1;
      this.exampleRight.opacity = 1;
      this.introduceExample();
    }, 500);
  };

  introduceExample = () => {
    this.dataService.speakerIsPlaying = true;
    if (this.exampleLeft.audioUrl === '') {
      setTimeout(() => {
        this.introduceChallenge();
      }, 2000);
    } else {
      setTimeout( () => {
        this.exampleLeft.highlight = true;
        let exampleLeftAudio = new Audio(this.exampleLeft.audioUrl);
        exampleLeftAudio.addEventListener('ended',() => {
          this.exampleLeft.highlight = false;
          if (this.exampleRight.audioUrl === '') {
            setTimeout(() => {
              this.introduceChallenge();
            }, 2000);
          } else {
            setTimeout(() => {
              this.exampleRight.highlight = true;
              let exampleRightAudio = new Audio(this.exampleRight.audioUrl);
              exampleRightAudio.addEventListener('ended', () => {
                setTimeout(() => {
                  this.exampleLeft.opacity = 0;
                  this.exampleRight.opacity = 0;
                  this.exampleRight.highlight = false;
                  setTimeout(() => {
                    this.introduceChallenge();
                  }, 500);
                }, 500);
              });
              exampleRightAudio.play();
            }, 500);
          }
        });
        exampleLeftAudio.play();
      }, 1000);
    }
  };

  introduceChallenge = () => {
    this.showing = 'challenge';
    setTimeout(() => {
      this.challengeLeft.opacity = 1;
      this.challengeRight.opacity = 1;
      this.challengeActive = true;
      if (typeof this.task.challenge.instructionAudio !== 'undefined') {
        setTimeout(() => {
          let introAudio = new Audio(this.task.challenge.instructionAudio.url);
          introAudio.addEventListener('ended', () => {
            this.dataService.speakerIsPlaying = false;
            this.dataService.setSpeakerSound(this.task.challenge.instructionAudio.url, null);
          });
          introAudio.play();
        }, 500);
      }
    }, 500);
  };

  playChallengeAudioWord = () => {
    if (!this.playingAudio && this.textAudio !== null) {
      this.playingAudio = true;
      this.textAudio.play();
      this.dataService.progress.results.use_audio_content_items++;
    }
  };

  clickAnswer(choice) {
    if(this.challengeActive) {
      if(choice === 'left') {
        if(this.challengeLeft.correct) {
          this.dataService.progress.results.answer_details.push({ attempt: "image 1", correct: true });
          this.challengeLeft.borderGreen = true;
          this.dataService.progress.completeAStar();
          this.completeTask();
        } else {
          this.challengeLeft.borderRed = true;
          this.dataService.progress.results.incorrectAttempts++;
          this.dataService.progress.results.answer_details.push({ attempt: "image 1", correct: false });
          this.dataService.tryAgain();
          if(this.unforgivingTestMode) {
            this.completeTask();
          }
        }
      } else {
        if(this.challengeRight.correct) {
          this.dataService.progress.results.answer_details.push({ attempt: "image 2", correct: true });
          this.challengeRight.borderGreen = true;
          this.dataService.progress.completeAStar();
          this.completeTask();
        } else {
          this.challengeRight.borderRed = true;
          this.dataService.progress.results.incorrectAttempts++;
          this.dataService.progress.results.answer_details.push({ attempt: "image 2", correct: false });
          this.dataService.tryAgain();
          if(this.unforgivingTestMode) {
            this.completeTask();
          }
        }
      }
    }
  };

  completeTask() {
    this.challengeActive = false;
    if (this.dataService.taskMode === TaskMode.Warmups) {
      setTimeout(() => {
        const a = new Audio('assets/sounds/task_instructions/warmups/type1.mp3');
        a.addEventListener('ended', () => {
          this.opacity = 0;
          this.dataService.completeTask()
        });
        a.play();
      }, 1000);
    } else {
      setTimeout(() => {
        this.opacity = 0;
        setTimeout(() => {
          this.dataService.completeTask();
        }, 1000)
      }, 1000);
    }
  };



}
