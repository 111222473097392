import { Component, OnInit } from '@angular/core';
import {TaskType6} from '../../models/task-type6.model';
import {DataService} from '../../services/data.service';
import { MessageService} from '../../services/message.service';
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";

@Component({
  selector: 'app-type6',
  templateUrl: './type6.component.html',
  styleUrls: ['./type6.component.css']
})
export class Type6Component implements OnInit {

  public task: TaskType6 = null;

  // Display setup
  unforgivingTestMode = false;
  round = 1;
  opacity = 0;

  challengeActive = false;
  droppedItems1 = [];
  droppedItems2 = [];
  animateDone = false;
  enableZone1 = true;
  enableZone2 = true;

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
  result = { correct: 0, of: 0, incorrectAttempts: 0, answer_details: [] };
  box1Image = "";
  box2Image = "";
  draggableItem = null;
  words = [];

  displayedWords1 = [];
  displayedWords2 = [];

  linkedWord = null;
  tappedFilename = null;

  constructor(private dataService: DataService, private messageService: MessageService) { }

  ngOnInit() {
    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    //GroverService.setControllerState(self);

    this.task = <TaskType6> this.dataService.currentTask;
    this.setupTask();
  }

  setupTask() {
    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 6 task does not exist - check your Session layout in the CMS');
      return;
    }

    this.box1Image = typeof this.task.box1.image !== 'undefined' && this.task.box1.image.url !== null ? this.task.box1.image.url : '';
    this.box2Image = typeof this.task.box2.image !== 'undefined' && this.task.box2.image.url !== null ? this.task.box2.image.url : '';

    let tempWords = [];
    for(let i=1; i < 6; i++) {
      if (this.task['text'+i].text !== '') {
        const correctBox = this.task['text' + i].word === this.task.box1.word ? 'box1' : (this.task['text' + i].word === this.task.box2.word ? 'box2' : 'none');
        let word = {
          text: this.task['text' + i].text,
          correctBox: correctBox,
          audio: typeof this.task['text' + i].audio !== 'undefined' ? this.task['text' + i].audio.url : '',
          enabled: true,
          draggable: true,
          opacity: 0,
          fontSize: this.task['text' + i].text.length > 10 ? '0.75em' : '1em',
          holdPositionInWords: -1   // Track the index of this word in the 'words' array.
                                    // Next time it is shuffled, we have the chance to position correct words at the same index
        };
        tempWords.push(word);
      }
    }
    this.result.of = 2;
    this.dataService.progressShow({ stars: 2 });

    this.words = Utilities.shuffleArray(tempWords);
    this.words.forEach((w, i) => {
      w.holdPositionInWords = i;
    });
    this.displayedWords1 = this.words;
    this.displayedWords2 = this.words.splice(3,2);

    this.introduceChallenge();
  };


  introduceChallenge() {
    this.opacity = 1;
    this.dataService.speakerIsPlaying = true;
    setTimeout(() => {

      let instructionAudio = new Audio('assets/sounds/task_instructions/type6.mp3');
      instructionAudio.play();

      setTimeout(() => {
        this.dataService.setSpeakerSound('assets/sounds/task_instructions/type6.mp3', null);
        this.dataService.speakerIsPlaying = false;
        if (this.dataService.taskMode === TaskMode.Warmups) {
          setTimeout(() => {
            new Audio('assets/sounds/task_instructions/warmups/type6.mp3').play();
          }, 1000);
        }
        this.challengeActive = true;
      }, 2000);

      let showWord = (wordArray, index) => {
        setTimeout(() => {
          wordArray[index].opacity = 1;
          if(index < wordArray.length -1) {
            showWord(wordArray, ++index);
          }
        }, 500)
      };
      showWord(this.displayedWords1.concat(this.displayedWords2), 0);

    }, 1000);
  };

  checkTouchEnd(event) {
    event.preventDefault();
    event.stopPropagation();
    let changedTouch = event.changedTouches[0];
    let element = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY);

    if(element.id === 'box1' || element.id === 'box1img') {
      this.mouseUpOnBox({clientX: changedTouch.clientX, clientY: changedTouch.clientY}, 'box1', element);
    } else if(element.id === 'box2' || element.id === 'box2img') {
      this.mouseUpOnBox({clientX: changedTouch.clientX, clientY: changedTouch.clientY}, 'box2', element);
    } else {
      this.mouseUpOutsideBox();
    }
  };

  checkMouseEnd(event) {
    event.preventDefault();
    event.stopPropagation();
    let element = document.elementFromPoint(event.clientX, event.clientY);

    if(element.id === 'box1' || element.id === 'box1img') {
      this.mouseUpOnBox({clientX: event.clientX, clientY: event.clientY}, 'box1', element);
    } else if(element.id === 'box2' || element.id === 'box2img') {
      this.mouseUpOnBox({clientX: event.clientX, clientY: event.clientY}, 'box2', element);
    } else {
      this.mouseUpOutsideBox();
    }
  }

  mouseMoving(event) {
    this.tappedFilename = null;
    if (this.linkedWord !== null) {
      this.messageService.sendMessage("morfologiTaskMousemove", event);
    }
  };

  mouseDownOnWord(event, item) {
    this.tappedFilename = new Audio(item.audio);
    if (item.enabled) {
      let startX, startY;
      if (typeof event.clientX !== 'undefined'){
        startX = event.clientX;
        startY = event.clientY;
      } else if (this.dataService.isCordovaApp) { // iOS compatibility
        if (event.touches && event.touches[0]) {
          startX = event.touches[0].clientX;
          startY = event.touches[0].clientY;
        }
      }
      let element = document.elementFromPoint(startX, startY);
      this.linkedWord = {
        word: item,
        startX: startX,
        startY: startY,
        startElement: element,
        endElement: null,
        endX: 0,
        endY: 0
      };
      this.messageService.sendMessage("morfologiTaskMousedown", this.linkedWord);
    }
  };

  mouseUpOutsideBox() {
    if (this.tappedFilename !== null) {
      this.tappedFilename.addEventListener('ended', () => {
        this.tappedFilename = null;
      });
      this.tappedFilename.play();
      this.dataService.progress.results.use_audio_content_items++;
    }
    this.messageService.sendMessage("morfologiTaskMouseup");
    this.linkedWord = null;
  };

  mouseUpOnBox(event, boxType, el) {
    this.tappedFilename = null;
    if (this.challengeActive && this.linkedWord !== null && this.linkedWord.word.correctBox === boxType) {		// Correct combination

      if (typeof event.clientX !== 'undefined'){
        this.linkedWord.endX = event.clientX;
        this.linkedWord.endY = event.clientY;
      } else if (this.dataService.isCordovaApp) { // iOS compatibility
        if (event.touches && event.touches[0]) {
          this.linkedWord.endX = event.touches[0].clientX;
          this.linkedWord.endY = event.touches[0].clientY;
        }
      }
      this.linkedWord.endElement = el;

      this.messageService.sendMessage("morfologiTaskLine", this.linkedWord);
      this.result.correct++;
      this.dataService.progress.results.answer_details
        .push({ attempt: this.linkedWord.word.text, correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
      this.dataService.progress.completeAStar();
      this.linkedWord.word.enabled = false;
      if(this.result.of === this.result.correct) {
        setTimeout( () => {
          this.completeTask();
        }, 1500)
      }
    } else {
      if (this.challengeActive && this.linkedWord !== null && this.linkedWord.word.correctBox !== boxType) {
        this.dataService.progress.results.incorrectAttempts++;
        this.dataService.progress.results.answer_details
          .push({ attempt: this.linkedWord.word.text, correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
      }
      this.linkedWord = null;
      this.reshuffleUnusedWords();
      // this.dataService.tryAgain();
    }
    this.messageService.sendMessage("morfologiTaskMouseup");
  };

  reshuffleUnusedWords() {
    let firstCorrectSelection = { index: -1, item: null};
    let tempWords = this.displayedWords1.concat(this.displayedWords2);
    tempWords = Utilities.shuffleArray(tempWords);
    tempWords.forEach((w, i) => {
      if (!w.enabled) {
        firstCorrectSelection = { index: i, item: w }
      } else {
        w.holdPositionInWords = i;
      }
    });
    if (firstCorrectSelection.index > -1) {
      tempWords.splice(firstCorrectSelection.item.holdPositionInWords, 0, tempWords.splice(firstCorrectSelection.index, 1)[0]);
    }
    this.displayedWords1 = tempWords;
    this.displayedWords2 = tempWords.splice(3,2);
  }

  completeTask() {
    this.opacity = 0;
    setTimeout(() => {
      this.dataService.completeTask();
    }, 1000)
  };


}
