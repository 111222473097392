import { Component, OnInit, OnDestroy } from '@angular/core';
import {DataService} from '../../services/data.service';
import {TaskType4} from '../../models/task-type4.model';
import {DragulaService} from 'ng2-dragula-base/dist';
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";
import { NgZone } from '@angular/core';
import {Type10Word} from '../../models/task-type10.model';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-type4',
  templateUrl: './type4.component.html',
  styleUrls: ['./type4.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        transition(
          ':enter', [
            style({ opacity: 0}),
            animate('500ms', style({'opacity': 1}))
          ]
        ),
        transition(
          ':leave', [
            style({'opacity': 1}),
            animate('500ms', style({'opacity': 0}))
          ]
        )]
    )
  ]
})
export class Type4Component implements OnInit, OnDestroy {

  public task: TaskType4 = null;
  // Display setup
  unforgivingTestMode = false;
  attempts = 0;
  opacity = 0;
  opacityWords = 0;
  droppedObject1: { text: string; } = { text: '' };
  droppedObject2: { text: string; } = { text: '' };

  public draggedWord = {};
  public droppedItem1 = [];
  public droppedItem2 = [];

  animateDone = false;
  enableZone1 = true;
  enableZone2 = true;
  dottyText1 = "...";
  dottyText2 = "...";
  draggingNow = false;
  finishDone = false;
  taskCompleted = false;
  audioPlaying = false;
  yellowWord1 = false;
  yellowWord2 = false;
  mergeTrigger1 = false;
  mergeTrigger2 = false;

  // New vars for merging:
  combinedWord1 = '';
  combinedWord2 = '';
  showCombinedWord1 = false;
  showCombinedWord2 = false;
  mergeCombinedWord1 = false;
  mergeCombinedWord2 = false;
  showPlaceholders1 = true;
  showPlaceholders2 = true;
  displayedItem1 = '';
  displayedItem2 = '';

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
  result = { correct: 0, of: 2, incorrectAttempts: 0, answer_details: [] };
  boxImage1 = "";
  boxImage2 = "";
  boxAudio1 = null;
  boxAudio2 = null;
  boxCompleted1 = false;
  boxCompleted2 = false;
  correctAudio1 = null;
  correctAudio2 = null;
  words = [];
  audioSentenceQueue = [];

  private dragulaSubscription;
  private dragulaCancelSubscription;
  private dragulaDragSubscription;

  private cancelDropEvent: boolean;

  constructor(private dragulaService: DragulaService, private dataService: DataService, private zone: NgZone) {
    this.setupDragula();
    this.cancelDropEvent = false;
  }

  ngOnInit() {

    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    this.dataService.progressShow({ stars: 2 });
    // GroverService.setControllerState(self);

    this.task = <TaskType4> this.dataService.currentTask;
    this.combinedWord1 = '';
    this.combinedWord2 = '';
    this.yellowWord1 = false;
    this.yellowWord2 = false;
    this.mergeTrigger1 = false;
    this.mergeTrigger2 = false;

    this.setupTask();
  }

  ngOnDestroy() {
    this.dragulaSubscription.unsubscribe();
    this.dragulaCancelSubscription.unsubscribe();
    this.dragulaDragSubscription.unsubscribe();
    this.dragulaService.destroy("drag-bag4");
  }

  // ---------  Dragula functions -------------------

  private setupDragula() {
    this.dragulaService.setOptions('drag-bag4', {
      revertOnSpill: true,
      direction: 'horizontal',
      accepts: (el, target, source, sibling) => {
        let boxIndex = this.getBoxIndex(target);
        let dropBoxEmpty = !isNaN(boxIndex) && this['droppedItem' + boxIndex].length === 0;
        return target.id !== 'word-source-box' && dropBoxEmpty;
      },
      moves: (el, source, handle, sibling) => {
        return source.id === 'word-source-box' && this.opacityWords === 1 && !this.draggingNow; // don't prevent any drags from initiating by default
      }
    });

    this.dragulaDragSubscription = this.dragulaService.drag.subscribe((value) => {
      this.draggingNow = true;
    });

    this.dragulaCancelSubscription = this.dragulaService.cancel.subscribe((value) => {
      this.draggingNow = false;
    });

    this.dragulaSubscription = this.dragulaService.dropModel.subscribe((value) => {
      if (!this.cancelDropEvent) {
        this.onDrop(value.slice(1));
      } else {
        this.cancelDropEvent = false;
        const [el, target, source, sibling] = value.slice(1);
        const boxIndex = this.getBoxIndex(target);
        const word = this['droppedItem' + boxIndex].pop();
        if (typeof word !== 'undefined') {
          setTimeout(() => {
            this.words.push(word);
            this.draggingNow = false;
          }, 100);
        }
      }
    });
  }

  public dropModelEventListener(value) {
    this.onDrop(value.slice(1));
  }

  private onDrop(args) {
    const [el, target, source, sibling] = args;
    const wordIndex = this.getWordIndex(el);
    const boxIndex = this.getBoxIndex(target);
    const word = this['droppedItem' + boxIndex].pop();
    this.attempts++;
    if (word.correctBox === boxIndex) {
      this['droppedObject' + boxIndex] = word;
      this.onDropComplete(word, boxIndex);
    } else {
      this.dataService.progress.results.incorrectAttempts++;
      this.dataService.progress.results.answer_details
        .push({ attempt: word.text, correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
      setTimeout(() => {
        // this.words.splice(wordIndex, 0, word);    // This puts the word back in the same position

        // Put word back and shuffle
        this.words.push(word);
        this.words = Utilities.shuffleArray(this.words);
        this.draggingNow = false;
      }, 100);
    }
    this['droppedItem' + boxIndex].length = 0;
  }

  private getWordIndex(el: any) {
    return parseInt(el.id.substr(11), 10);
  }

  private getBoxIndex(target: any) {
    return parseInt(target.id.substr(9), 10);
  }

  // --------------------------------------------------

  playCompleteAudio(wordNumber: number) {
    if (!this.audioPlaying) {
      this.audioPlaying = true;
      this.task.playCompleteAudio(wordNumber, () => {
        this.audioPlaying = false;
      });
    }
    this.dataService.progress.results.use_audio_content_items++;
  }

  clickImage(item) {
    if (!this.draggingNow && item['audio'] !== null) {
      item['audio'].play();
      this.dataService.progress.results.use_audio_content_items++;
    } else {
      this.draggingNow = false;
    }
  };

  clickSentence(box) {
    if (this.audioPlaying) {
      this.audioSentenceQueue.push(box);
    } else if (this['boxAudio' + box] !== null) {
      this.audioPlaying = true;
      this['boxAudio' + box].addEventListener('ended', () => {
        this.audioPlaying = false;
      });
      this['boxAudio' + box].play();
      this.dataService.progress.results.use_audio_content_items++;
    } else if (!this.unforgivingTestMode && this.result.of === this.result.correct && !this.finishDone) {
      this.fadeOut();
    }
  };

  setupSentence(box) {

    let playAgainOrFadeOut = () => {
      this.audioPlaying = false;
      if (this.audioSentenceQueue.length > 0) {
        this.clickSentence(this.audioSentenceQueue.pop());
      } else if (!this.unforgivingTestMode && this.result.of === this.result.correct && !this.finishDone) {
        this.fadeOut();
      }
    };

    let before = null, word = null, after = null;
    if (typeof this.task['round' + box].audioBeforeWord !== 'undefined'
      && this.task['round' + box].audioBeforeWord.url !== null) {
      before = new Audio(this.task['round' + box].audioBeforeWord.url);
      before.addEventListener('ended', () => {
        if (word !== null && this['boxCompleted' + box]) {
          word.play();
        } else {
          if (after !== null) {
            setTimeout( () => {
              after.play();
            }, 1000);
          } else {
            playAgainOrFadeOut();
          }
        }
      });
    }
    if (this['correctAudio' + box] !== null) {
      word = this['correctAudio' + box];
      word.addEventListener('ended', () => {
        if (after !== null) {
          after.play();
        } else {
          playAgainOrFadeOut();
        }
      });
    }
    if (typeof this.task['round' + box].audioAfterWord !== 'undefined'
      && this.task['round' + box].audioAfterWord.url !== null) {
      after = new Audio(this.task['round' + box].audioAfterWord.url);
      after.addEventListener('ended', () => {
        playAgainOrFadeOut();
      });
    }

    if (before !== null) {
      this['boxAudio' + box] = before;
    } else if (after !== null) {
      this['boxAudio' + box] = after;
    }
  };

  setupTask() {
    this.finishDone = false;
    this.audioSentenceQueue = [];
    this.audioPlaying = false;
    this.taskCompleted = false;

    this.showPlaceholders1 = true;
    this.showCombinedWord1 = false;
    this.mergeCombinedWord1 = false;
    this.showPlaceholders2 = true;
    this.showCombinedWord2 = false;
    this.mergeCombinedWord2 = false;
    this.displayedItem1 = '';
    this.displayedItem2 = '';

    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 4 task does not exist - check your Session layout in the CMS');
      return;
    }

    this.boxImage1 = typeof this.task.round1.boxImage !== 'undefined' ? this.task.round1.boxImage.url : '';
    this.boxImage2 = typeof this.task.round2.boxImage !== 'undefined' ? this.task.round2.boxImage.url : '';
    let correctWord1 = parseInt(this.task.correctWord1);
    let correctWord2 = parseInt(this.task.correctWord2);
    let tempWords = [];
    for(let i=1; i < 6; i++) {
      if (this.task['text'+i].text !== '') {
        let word = {
          text: this.task['text' + i].text,
          audio: typeof this.task['text' + i].audio !== 'undefined'
          && this.task['text' + i].audio.url !== null ? new Audio(this.task['text' + i].audio.url) : null,
          enabled: true,
          correctBox: 0,
          draggable: true
        };
        if (i === correctWord1) {
          word['correctBox'] = 1;
          this.correctAudio1 = word['audio'];
        } else if (i === correctWord2) {
          word['correctBox'] = 2;
          this.correctAudio2 = word['audio'];
        }
        tempWords.push(word);
      }
    }
    this.words = Utilities.shuffleArray(tempWords);
    this.setupSentence(1);
    this.setupSentence(2);
    this.introduceChallenge();
  };

  introduceChallenge() {
    this.attempts = 0;
    this.dataService.speakerIsPlaying = true;
    setTimeout(() => {
      this.opacity = 1;
      this.opacityWords = 1;
      let instructionAudio = new Audio('assets/sounds/task_instructions/type4.mp3');
      instructionAudio.addEventListener('ended', () => {
        this.dataService.speakerIsPlaying = false;
        this.dataService.setSpeakerSound('assets/sounds/task_instructions/type4.mp3', null);
      });
      instructionAudio.play().catch();
    }, 1000);
  };

  showPlaceholder(number) {
    return this['displayedItem' + number] === null;
  }

  completeTheWord(box, item) {

      this['displayedItem' + box] = item.text;
      if (this.task['round' + box].mergeBefore) {
        this['combinedWord' + box] = this.task['round' + box].sentenceBeforeWord + item.text;
      } else {
        this['combinedWord' + box] = this.task['round' + box].sentenceBeforeWord + ' ' + item.text;
      }
      if (this.task['round' + box].mergeAfter) {
        this['combinedWord' + box] += this.task['round' + box].sentenceAfterWord;
      } else {
        this['combinedWord' + box] += ' ' + this.task['round' + box].sentenceAfterWord;
      }

      setTimeout(() => {
        this['showCombinedWord' + box] = true;
        setTimeout(() => {
          this['mergeCombinedWord' + box] = true;
        }, 1000)
      }, 1000);

  }

  preventDropOnTap(event) {
    this.cancelDropEvent = true;
    setTimeout(() => {
      this.cancelDropEvent = false;
    }, 1000);
  };

  onDropComplete(item, box) {
    this.result.correct++;
    this.dataService.progress.results.answer_details
      .push({ attempt: item.text, correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
    this['boxCompleted' + box] = true;
    item.enabled = false;
    this['dottyText' + box] = "";
    this['enableZone' + box] = false;
    this.dataService.progress.completeAStar();

    let newCompletedSentenceAudioUrl = this.task['correctWord'+ box + 'Audio'].url;

    if ((this.unforgivingTestMode && this.attempts === this.result.of && !this.finishDone) || this.result.of === this.result.correct) {
      this.opacityWords = 0;
    }

    if (newCompletedSentenceAudioUrl !== null) {
      let a = new Audio(newCompletedSentenceAudioUrl);
      a.addEventListener('ended', () => {
        // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
        // If number of attempts are limited to correct answers we will finish the task
        if ((this.unforgivingTestMode && this.attempts === this.result.of && !this.finishDone) || this.result.of === this.result.correct) {
          this.fadeOut();
        }
      });
      if (!this.unforgivingTestMode) {
        a.play();
      }
      this['boxAudio' + box] = a;
    } else {
      if ((this.unforgivingTestMode && this.attempts === this.result.of && !this.finishDone) || this.result.of === this.result.correct) {
        this.fadeOut();
      }
    }

    this.completeTheWord(box, item);
    this.draggingNow = false;
  };

  fadeOut() {
    this.finishDone = true;
    this.opacityWords = 0;
    if (this.dataService.taskMode === TaskMode.Warmups) {
      setTimeout(() => {
        const a = new Audio('assets/sounds/task_instructions/warmups/type4.mp3');
        a.addEventListener('ended', () => {
          this.finish();
        });
        a.play();
      }, 1000);
    } else {
      this.finish();
    }
  };

  finish() {
      setTimeout(() => {
        this.opacity = 0;
        this.completeTask();
      }, 500);
  };

  completeTask() {
    if (!this.taskCompleted) {
      this.taskCompleted = true;
      setTimeout(() => {
        this.dataService.completeTask();
      }, 1000)
    }
  };

}
