import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {TaskType2} from '../../models/task-type2.model';
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from '../../models/general.model';

@Component({
  selector: 'app-type2',
  templateUrl: './type2.component.html',
  styleUrls: ['./type2.component.css']
})
export class Type2Component implements OnInit {

  public task: TaskType2 = null;
  // Display setup
  unforgivingTestMode = false;
  round = 1;
  attempts = 0;
  opacity = 0;
  finished = false;
  playingAudio = false;
  textAudio = null;

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
  result = { round1: { correct: 0, of: 0 }, round2: { correct: 0, of: 0 }, incorrectAttempts: 0, answer_details: [] };
  images = [[],[]];


  constructor(private dataService: DataService) {
    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    // GroverService.setControllerState(self);
    this.task = <TaskType2> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnInit() {
  }

  setupTask() {

    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
    this.finished = false;
    this.playingAudio = false;
    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 2 task does not exist - check your Session layout in the CMS');
      return;
    }

    let tempImages = [];
    for(let i=1; i < 7; i++) {
      let image = {
        imageUrl: typeof this.task['image'+i].file !== 'undefined' ? this.task['image'+i].file.url : '',
        imageFilename: typeof this.task['image'+i].file !== 'undefined' ? this.task['image'+i].file.filename : '',
        correct1: this.task['image'+i].correct1,
        correct2: this.task['image'+i].correct2,
        enabled: true,
        borderGreen: false,
        borderRed: false
      };

      if(image.correct1 && this.round === 1) {
        this.result['round'+1].of++;
      }
      if(image.correct2 && this.round === 2) {
        this.result['round'+2].of++;
      }
      tempImages.push(image);
    }
    tempImages = Utilities.shuffleArray(tempImages);
    this.images[0] = tempImages.splice(0,3);
    this.images[1] = tempImages;

    this.dataService.progressShow({ stars: this.result['round' + this.round].of });

    if (typeof this.task['round' + this.round].wordAudio !== 'undefined' && this.task['round' + this.round].wordAudio.url !== null) {
      this.textAudio = new Audio(this.task['round'+this.round].wordAudio.url);
      this.textAudio.addEventListener('ended', () => {
        this.playingAudio = false;
      });
    }

    setTimeout(() => {
      this.opacity = 1;
      this.opacity = 1;
      this.introduceChallenge();
    }, 1000);
  };

  introduceChallenge() {
    this.attempts = 0;
    this.dataService.speakerIsPlaying = true;
    setTimeout(() => {
      if(typeof this.task['round'+this.round].instructionAudio !== 'undefined' && this.task['round' + this.round].instructionAudio.url !== null) {
        let instructionAudio = new Audio(this.task['round' + this.round].instructionAudio.url);
        instructionAudio.addEventListener('ended', () => {
          this.dataService.setSpeakerSound(this.task['round' + this.round].instructionAudio.url, null);
          this.dataService.speakerIsPlaying = false;
        });
        instructionAudio.play();
      }
    }, 500);
  };

  playWordAudio() {
    if (!this.playingAudio && this.textAudio !== null) {
      this.playingAudio = true;
      this.textAudio.play();
      this.dataService.progress.results.use_audio_content_items++;
    }
  };

  clickAnswer(item) {
    if(item.enabled && !this.finished) {
      this.attempts++;
      if (item.correct1 && this.round === 1) {
        this.result['round' + 1].correct++;
        this.dataService.progress.results.answer_details
          .push({ attempt: 'CMS image: ' + item.imageFilename + ' (round 1)', correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
        this.dataService.progress.completeAStar();
        item.borderGreen = true;
      } else if (item.correct2 && this.round === 2) {
        this.result['round' + 2].correct++;
        this.dataService.progress.results.answer_details
          .push({ attempt: 'CMS image: ' + item.imageFilename + ' (round 2)', correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
        this.dataService.progress.completeAStar();
        item.borderGreen = true;
      } else {
        item.borderRed = true;
        this.dataService.progress.results.incorrectAttempts++;
        this.dataService.progress.results.answer_details
          .push({ attempt: 'CMS image: ' + item.imageFilename + ' (round ' + this.round + ')', correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
        this.dataService.tryAgain();
      }
      item.enabled = false;

      // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
      if ((this.unforgivingTestMode && this.attempts === this.result['round' + this.round].of) ||
        (!this.unforgivingTestMode && this.result['round' + this.round].of === this.result['round' + this.round].correct)) {
        this.finished = true;
        this.nextRound();
      }
    }
  };

  nextRound() {
    setTimeout(() => {
      this.opacity = 0;
      setTimeout(() => {
        if (this.round === 1) {
          this.round = 2;
          this.setupTask();
        } else {
          if (this.dataService.taskMode === TaskMode.Warmups) {
            setTimeout(() => {
              const a = new Audio('assets/sounds/task_instructions/warmups/type2.mp3');
              a.addEventListener('ended', () => {
                this.opacity = 0;
                this.dataService.completeTask();
              });
              a.play();
            }, 1000);
          } else {
            this.opacity = 0;
            this.completeTask();
          }
        }
      }, 500);
    }, 500);
  };

  completeTask() {
    setTimeout(() => {
      this.dataService.completeTask();
    }, 1000)
  };


}
