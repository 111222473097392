import { Constants } from "../constants";
import { Task } from "./task.model";
import { TaskType1 } from "./task-type1.model";
import { TaskType4 } from "./task-type4.model";
import { TaskType2 } from "./task-type2.model";
import { TaskType3 } from "./task-type3.model";
import { TaskType5 } from "./task-type5.model";
import { TaskType6 } from "./task-type6.model";
import { TaskType7 } from "./task-type7.model";
import { TaskType8 } from "./task-type8.model";
import { TaskType9 } from "./task-type9.model";
import { TaskType10 } from "./task-type10.model";
import { TaskType11 } from "./task-type11.model";
import { TaskType13 } from "./task-type13.model";
import { Utilities } from "../utilities/utilities";
import { TaskType12 } from "./task-type12.model";
import { Moment } from "moment";

declare global {
  interface Array<T> {
    find(predicate: (value: T) => boolean): T;

    findIndex(
      predicate: (value: T, index: number, obj: Array<T>) => boolean,
      thisArg?: any
    ): number | -1;

    // find(predicate: (value: T, index: number, obj: Array<T>) => boolean, thisArg?: any): T | undefined;
  }
}

export class AppStorage {
  demoMode: boolean;
  broadReleasePretestMode: boolean;
  currentBackgroundColour: string;
  isOnline: boolean;
  unforgivingTestMode: boolean;
  student: Student;
  activity: Activity;
  sessionLogs: { [id: string]: SessionLog };

  selectedEpisode: Episode;
  selectedSession: Session;
  selectedTask: Task;
  selectedSceneSubpath: string;
  selectedSessionLog: SessionLog;

  constructor() {
    this.demoMode = false;
    this.broadReleasePretestMode = false;
    this.currentBackgroundColour = "#f77d4c";
    this.isOnline = true;
    this.unforgivingTestMode = false;
    this.sessionLogs = {};

    this.student = null;
    this.activity = null;

    this.selectedEpisode = null;
    this.selectedSession = null;
    this.selectedTask = null;
    this.selectedSceneSubpath = "";
    this.selectedSessionLog = null;
  }

  resetStorage() {
    this.demoMode = false;
    this.sessionLogs = {};
    this.student = null;
    this.activity = null;

    this.selectedEpisode = null;
    this.selectedSession = null;
    this.selectedTask = null;
    this.selectedSessionLog = null;
  }

  // This retrieves the next uncompleted episode. Based on the Student's progress.
  public get nextUncompletedEpisode(): Episode {
    // If we logged in, but there are no available episodes, go to the Delay route
    if (this.activity === null) {
      return null;
    }

    let completedEpisodeIDs = this.student
      .getCompletedEpisodes(this.activity._id)
      .map(e => {
        return e._id;
      });

    // Exclude episodes that are in the Student's completed list
    let uncompletedEpisodes = this.activity.episodes.filter(episode => {
      return completedEpisodeIDs.indexOf(episode._id) === -1;
    });

    if (uncompletedEpisodes.length > 0) {
      // Broad release has only two episodes, and they may not have been done in order
      if (this.activity.searchKey === Constants.activityPhase_broad_release || this.activity.searchKey === Constants.activityPhase_broad_release_testing) {
        if (this.broadReleasePretestMode) {
          // Regardless of completion, return the Pretest again if requested, as long as Main is not complete
          return this.activity.episodes[0]
        } else {
          return this.activity.episodes[1]
        }
      }
      return uncompletedEpisodes[0];
    } else {
      return null;
    }
  }

  // This retrieves the next uncompleted session
  public get nextUncompletedSession(): Session {
    // If there are no sessions assigned, we can't continue
    if (!this.selectedEpisode || this.selectedEpisode.sessions.length === 0) {
      return;
    }

    // Returns a list of session IDs for a particular episode:  string[]
    let completedSessionIDs: SessionCompletion[] = this.student.getCompletedSessions(
      this.activity._id,
      this.selectedEpisode._id
    );

    // Exclude sessions that are in the Student's completed list
    let uncompletedSessions = this.selectedEpisode.sessions.filter(es => {
      return (
        completedSessionIDs.length === 0 ||
        completedSessionIDs.every(cs => {
          return cs._id !== es._id;
        })
      );
    });

    if (uncompletedSessions.length > 0) {
      return uncompletedSessions[0];
    } else {
      return null;
    }
  }

  public completeASession(
    activityId: string,
    episodeId: string,
    session: Session,
    skipped: boolean,
    taskData: { [id: string]: TaskLog }
  ) {
    this.student.completeASession(activityId, episodeId, session._id, skipped, taskData);
    if (
      this.selectedEpisode.sessions.indexOf(this.selectedSession) ===
      this.selectedEpisode.sessions.length - 1
    ) {
      this.student.completeAnEpisode(activityId, episodeId);
      if (
        this.activity.episodes.indexOf(this.selectedEpisode) ===
        this.activity.episodes.length - 1
      ) {
        this.student.completeAnActivity(activityId);
      }
    }
    if (skipped) {
      session.skipped = true;
    } else {
      session.completed = true;
    }
  }

  get activityPhase(): ActivityPhase {
    const searchKey = this.activity ? this.activity.searchKey : "";
    if (
      searchKey === Constants.activityPhase_rct ||
      searchKey === Constants.activityPhase_rct_testing ||
      searchKey === Constants.activityPhase_regular
    ) {
      return ActivityPhase.RCT;
    } else if (searchKey === Constants.activityPhase_broad_release ||
      searchKey === Constants.activityPhase_broad_release_testing) {
      return ActivityPhase.BroadRelease;
    } else if (searchKey === Constants.activityPhase_demonstration) {
      return ActivityPhase.Demo;
    } else {
      return ActivityPhase.None;
    }
  }

  get episodePhase(): EpisodePhase {
    return this.activity
      ? this.activity.episodes.indexOf(this.selectedEpisode) + 1
      : EpisodePhase.None; // For RCT:  1: Training on home planet    2:  Earth   3,4,5: Past-Tests
  }

  get sessionIndex() {
    return this.selectedEpisode.sessions.indexOf(this.selectedSession);
  }

  // Session numbers are fixed to ISP's requirements
  getSessionPhase(session = null): SessionPhase {
    let theSession = session ? session : this.nextUncompletedSession;
    const si = this.selectedEpisode.sessions.indexOf(theSession) + 1;
    // const sessionCount = this.selectedEpisode.sessions.length;

    if (this.selectedEpisode.sessions.length === 1) {
      return SessionPhase.FirstAndLast;
    } else if (si === 1) {
      return SessionPhase.First;
    } else if (si > 1 && si <= 5) {
      // Includes first consolidation session
      return SessionPhase.One;
    } else if (si > 5 && si <= 10) {
      return SessionPhase.Two;
    } else if (si > 10 && si <= 15) {
      return SessionPhase.Three;
    } else if (si > 15 && si <= 20) {
      return SessionPhase.Four;
    } else if (si > 20 && si <= 25) {
      return SessionPhase.Five;
    } else if (si > 25 && si <= 30) {
      return SessionPhase.Six;
    } else if (si > 30 && si <= 35) {
      return SessionPhase.Seven;
    } else if (si > 35 && si <= 38) {
      return SessionPhase.Eight;
    } else if (si == 39) {
      return SessionPhase.Penultimate;
    } else if (si == 40) {
      return SessionPhase.Last;
    }
  }
}

export class Results {
  correct: number;
  of: number;
  incorrectAttempts: number;
  answer_details: {}[];
  use_audio_instructions: number;
  use_audio_content_items: number;

  startTime: number; // USed to assist calculation of 'elapsed' inside 'answer_details'

  constructor(of?: number) {
    this.correct = 0;
    this.of = of || 0;
    this.incorrectAttempts = 0;
    this.answer_details = [];
    this.use_audio_instructions = 0;
    this.use_audio_content_items = 0;
    this.startTime = Date.now();
  }

  get elapsedTimeSinceLastCall(): number {
    const t = Date.now() - this.startTime;
    this.startTime = Date.now();
    return t;
  }
}

//  ************  States refactored 24 October 2017 ****************

export enum ViewState {
  None,
  Login,
  Loggedin,
  Ship,
  Map,
  Delay,
  Tasks
}
export enum LoginMode {
  AppStarted,
  LoggedOut
}

export enum ShipMode {
  SessionLocked,
  SessionUnlocked,
  SessionEnding,
  SessionCompleted,
  ReturnedFromInteractiveMap
}
export enum TaskMode {
  Tests,
  Warmups,
  Sample
}
export enum DelayMode {
  None,
  AwaitingDownload,
  NoActivitiesFound,
  NoSessionsFound,
  DemoComplete,
  WarmupsStarting,
  WarmupsFinished,
  NoWarmups,
  AccessDenied
}
export enum SceneMode {
  Ready,
  InProgress,
  Finished
}
export enum SceneType {
  None,
  GeneralScene,
  EpisodeInScene,
  EpisodeOutScene,
  SessionInScene,
  ConsolidationInScene,
  MorfologicalScene
}

// ******************************************************************

export class State {
  viewState: ViewState;
  loginMode: LoginMode;
  shipMode: ShipMode;
  taskMode: TaskMode;
  delayMode: DelayMode;
  sceneMode: SceneMode;
  sceneType: SceneType;

  constructor() {
    this.viewState = ViewState.None;
    this.loginMode = LoginMode.AppStarted;
    this.shipMode = ShipMode.SessionLocked;
    this.taskMode = TaskMode.Warmups;
    this.delayMode = DelayMode.None;
    this.sceneMode = SceneMode.Ready;
    this.sceneType = SceneType.GeneralScene;
  }
}

enum SpeakerType {
  narrator,
  computer
}

export enum ActivityPhase {
  None,
  Demo,
  RCT,
  BroadRelease
}

export enum EpisodePhase {
  First = 1, // Training on home planet
  Second, // Earth
  Third, // Post Test
  Fourth, // Post Test
  Fifth, // Post Test
  None
}

export enum SessionPhase {
  First, // First session - 1
  Penultimate, // Second last (final 'normal' session) - Session 39
  Last, // Consolidation 8 (last consolidation (8), final session - 40)
  FirstAndLast, // Only one session in the set (e.g. Episode 1, Session 1)

  One, // Sessions 2 - 5 inclusive (includes consolidation 1)
  Two, // Sessions 6 - 10 inclusive (includes consolidation 2)
  Three, // Sessions 11 - 15 inclusive (includes consolidation 3)
  Four, // Sessions 16 - 20 inclusive (includes consolidation 4)
  Five, // Sessions 21 - 25 inclusive (includes consolidation 5)
  Six, // Sessions 26 - 30 inclusive (includes consolidation 6)
  Seven, // Sessions 31 - 35 inclusive (includes consolidation 7)
  Eight // Sessions 36 - 38 inclusive
}

export class Narration {
  _computerAnimating: boolean;
  _audio: HTMLAudioElement;
  _onEndCallback: () => void;

  constructor() {
    this._computerAnimating = false;
    this._audio = new Audio();
    this._audio.addEventListener("ended", () => {
      this._computerAnimating = false;
      if (this._onEndCallback) {
        this._onEndCallback();
      }
    });
  }

  speak(
    url: string,
    callback: () => void,
    delay: number,
    animateComputer: boolean
  ) {
    this._audio.src = "assets/sounds/computer_narrator/" + url;
    this._onEndCallback = callback || null;
    try {
      this._audio.load();
    } catch (error) {
      console.log(error);
      if (this._onEndCallback) {
        this._onEndCallback();
      }
    }
    setTimeout(() => {
      this._computerAnimating = animateComputer;
      try {
        this._audio.play();
      } catch (error) {
        if (this._onEndCallback) {
          this._onEndCallback();
        }
      }
    }, delay);
  }

  get computerAnimating() {
    return this._computerAnimating;
  }
}

export class Progress {
  starData: {
    stars: number;
    completed: number;
  };
  barData: {
    completedPercent: number;
  };
  shipBarData: {
    completedPercent: number;
  };
  opacity: number;
  visible: boolean;
  state: State;
  _results: Results;
  randomMorfPlays: number;

  private starPopSound;

  constructor() {
    this.starData = {
      stars: 0,
      completed: 0
    };
    this.barData = {
      completedPercent: 1
    };
    this.shipBarData = {
      completedPercent: 1
    };
    this.opacity = 0;
    this.visible = false;
    this.state = new State();
    this._results = new Results();
    this.starPopSound = new Audio("assets/sounds/general/StarPopping.mp3");
    this.randomMorfPlays = 0;
  }

  public get results(): Results {
    return this._results;
  }
  public set results(r: Results) {
    this._results = r;
  }

  public completeAStar() {
    this.starData.completed++;
    // this.starPopSound.play();
  }

  // Decides whether to show an interlude scene
  public get randomMorphTime() {
    if (
      this.randomMorfPlays < 1 &&
      Math.random() < this.barData.completedPercent / 100
    ) {
      this.randomMorfPlays++;
      return true;
    } else {
      return false;
    }
  }

  public reset() {
    this.resetBar();
    this.resetStars();
    this.randomMorfPlays = 0;
  }

  public resetBar() {
    this.barData.completedPercent = 0;
  }

  public resetStars() {
    this.starData.stars = 0;
    this.starData.completed = 0;
  }

  public resetRandomMorf() {
    this.randomMorfPlays = 0;
  }
}

/*export class StagePosition {
  top: number;  // percent
  left: number;  // percent

  constructor(left, top) {
    this.left = left;
    this.top = top;
  }

  get leftPC() {
    return this.left + '%';
  }

  get topPC() {
    return this.top + '%';
  }

}

export class Stage {
  name: string;
  symbolImage: string;
  backgroundImage: string;
  backgroundSound: string;
  position: StagePosition;
  active: boolean;

  constructor(name, symbol, bgI, bgS, p: StagePosition) {
    this.name = name;
    this.symbolImage = symbol;
    this.backgroundImage = bgI;
    this.backgroundSound = bgS;
    this.position = p;
    this.active = false;
  }

  get symbolUrl() {
    return 'assets/map/stages/' + this.name + '/' + this.symbolImage;
  }

  get backgoundImageUrl() {
    return 'assets/map/stages/' + this.name + '/' + this.backgroundImage;
  }

  get backgroundSoundUrl() {
    return 'assets/map/stages/' + this.name + '/' + this.backgroundSound;
  }
}*/

export class Location {
  value: number;
  label: string;
  x: number;
  y: number;
  filename: string;

  constructor(data: {}) {
    this.value = data["value"];
    this.label = data["label"];
    this.x = data["x"];
    this.y = data["y"];
    this.filename = data["filename"];
  }

  get leftPC() {
    return this.x + "%";
  }

  get topPC() {
    return this.y + "%";
  }
}

export class Session {
  _id: string;
  name: string;
  description: string;
  searchKey: string;
  category: string;
  sortIndex: number;
  password: string;
  consolidation: boolean;
  locationValue: number;
  morfologicalIntro: number;
  location?: Location;
  activated?: boolean;
  completed?: boolean;
  skipped?: boolean;

  scenes: {
    ins: number[];
    morphologicals: number[];
    outs: number[];
  };

  warmups: {
    type1: TaskType1[];
    type2: TaskType2[];
    type3: TaskType3[];
    type4: TaskType4[];
    type5: TaskType5[];
    type6: TaskType6[];
    type7: TaskType7[];
    type8: TaskType8[];
    type9: TaskType9[];
    type10: TaskType10[];
    type11: TaskType11[];
    type12: TaskType12;
    type13: TaskType13[];
  };
  shuffleWarmups: boolean;
  warmupTaskCount: number;

  tests: {
    type1: TaskType1[];
    type2: TaskType2[];
    type3: TaskType3[];
    type4: TaskType4[];
    type5: TaskType5[];
    type6: TaskType6[];
    type7: TaskType7[];
    type8: TaskType8[];
    type9: TaskType9[];
    type10: TaskType10[];
    type11: TaskType11[];
    type12: TaskType12;
    type13: TaskType13[];
  };
  shuffleTests: boolean;
  testTaskCount: number;

  constructor(data: {}, setTasks) {
    this.activated = false;
    this.completed = false;
    this.skipped = false;
    this.morfologicalIntro = 0;
    this.scenes = {
      ins: [],
      morphologicals: [],
      outs: []
    };
    this.warmups = {
      type1: [],
      type2: [],
      type3: [],
      type4: [],
      type5: [],
      type6: [],
      type7: [],
      type8: [],
      type9: [],
      type10: [],
      type11: [],
      type12: null,
      type13: []
    };
    this.shuffleWarmups = true;
    this.tests = {
      type1: [],
      type2: [],
      type3: [],
      type4: [],
      type5: [],
      type6: [],
      type7: [],
      type8: [],
      type9: [],
      type10: [],
      type11: [],
      type12: null,
      type13: []
    };
    this.shuffleTests = true;

    this.testTaskCount = 0;
    this.warmupTaskCount = 0;

    this.setSession(data, setTasks);
  }

  private setSession(data, setTasks) {
    this._id = data._id;
    this.name = data.name;
    this.description = data.description;
    this.searchKey = data.searchKey;
    this.category = data.category;
    this.sortIndex = data.sortIndex;
    this.password = data.password;
    this.morfologicalIntro = data.morfologicalIntro || 0;
    /*   if (this.password === '') {
      this.activated = true;
    }*/
    this.consolidation = data.consolidation;
    this.locationValue = data.location;

    if (setTasks) {
      let tr = new TaskResponse();
      if (data["warmups"]) {
        data.warmups.map(task => {
          tr.taskType = task.taskType.toString();
          tr.taskData = task.taskData;
          if (tr.taskType === "12") {
            this.warmups.type12 = <TaskType12>Session.getAsTypedClass(tr);
          } else {
            this.warmups["type" + tr.taskType].push(
              Session.getAsTypedClass(tr)
            );
          }
          this.warmupTaskCount++;
        });
      }
      if (data["tests"]) {
        data.tests.map(task => {
          tr.taskType = task.taskType.toString();
          tr.taskData = task.taskData;
          if (tr.taskType === "12") {
            this.tests.type12 = <TaskType12>Session.getAsTypedClass(tr);
          } else {
            this.tests["type" + tr.taskType].push(Session.getAsTypedClass(tr));
          }
          this.testTaskCount++;
        });
      }
    }

    this.shuffleWarmups = data["shuffleWarmups"];
    this.shuffleTests = data["shuffleTests"];
  }

  public getTotalTaskCount() {
    return this.testTaskCount + this.warmupTaskCount;
  }

  public getShuffledTasks(setName) {
    //  setName is 'warmups' or 'tests'
    let tasksToShuffle = [];
    let allTasks = [];

    for (let i = 2; i < 14; i++) {
      if (i !== 12) {
        // Type 12 should be the last item in the test run
        const moreTasks = this[setName]["type" + i];
        tasksToShuffle.push(...moreTasks);
      }
    }

    let shuffledTasks = tasksToShuffle;
    if (
      (setName === "warmups" && this.shuffleWarmups) ||
      (setName === "tests" && this.shuffleTests)
    ) {
      shuffledTasks = Utilities.pseudoShuffleTasks(tasksToShuffle);
    }

    // Add Type 1 tasks to the beginning if the exist
    if (this[setName].type1 !== null) {
      const type1Tasks = this[setName].type1;
      allTasks.push(...type1Tasks);
    }

    // Add shuffled tasks in-between
    allTasks.push(...shuffledTasks);

    // Add Type 12 to the end if it exists
    if (this[setName].type12 !== null) {
      allTasks.push(this[setName].type12);
    }

    return allTasks;
  }

  public activateSession(password): boolean {
    if (password === this.password) {
      return (this.activated = true);
    } else {
      return false;
    }
  }

  public get hasPassword(): boolean {
    return !!this.password
  }

  public static getAsTypedClass(task: TaskResponse): Task {
    const type = parseInt(task.taskType);
    switch (type) {
      case 1:
        return new TaskType1(type, task.taskData);
      case 2:
        return new TaskType2(type, task.taskData);
      case 3:
        return new TaskType3(type, task.taskData);
      case 4:
        return new TaskType4(type, task.taskData);
      case 5:
        return new TaskType5(type, task.taskData);
      case 6:
        return new TaskType6(type, task.taskData);
      case 7:
        return new TaskType7(type, task.taskData);
      case 8:
        return new TaskType8(type, task.taskData);
      case 9:
        return new TaskType9(type, task.taskData);
      case 10:
        return new TaskType10(type, task.taskData);
      case 11:
        return new TaskType11(type, task.taskData);
      case 12:
        return new TaskType12(type, task.taskData);
      case 13:
        return new TaskType13(type, task.taskData);
    }
  }
}

export interface SessionCompletion {
  _id: string;
  name?: string;
  completed?: boolean;
  skipped: boolean;
  taskData?: { [id: string]: TaskLog };
}

export interface EpisodeCompletion {
  _id: string;
  name?: string;
  completed: boolean;
  sessions: SessionCompletion[];
}

export interface ActivityCompletion {
  _id: string;
  completed: boolean;
  episodes: EpisodeCompletion[];
}

export class StudentGroup {
  _id: string;
  name: string;
  pin: string;
  activity: Activity;
  students: Student[];

  constructor(data) {
    this._id = data._id;
    this.name = data.name;
    this.pin = data.pin;
    this.activity = data.activity || null;
    this.students = data.students || [];
  }
}

export class Student {
  _id: string;
  user_id: string;
  userName: string;
  password: string;
  completions: {
    activities: ActivityCompletion[];
  };
  isOrganiser: boolean;
  isFeide: boolean;
  introSeen: boolean;
  lastActive: number;
  myGroup: string; // ID of StudentGroup
  lastCompletion: number;
  demo?: boolean;
  surveyData: {
    ageGroup: number;
    norsk: string;
    annetSprak: string
  };

  constructor(data) {
    this._id = data._id;
    this.user_id = data.user_id;
    this.userName = data.userName || "(unknown)";
    this.isOrganiser = data.isOrganiser;
    this.isFeide = data.isFeide;
    this.completions = data.completions;
    this.lastActive = data.lastActive;
    this.myGroup = data.myGroup;
    this.lastCompletion = data.lastCompletion || null;
    this.introSeen = data.introSeen;
    this.demo = false;
    this.surveyData = data.surveyData;
    if (data["demo"]) {
      this.demo = data.demo;
    }
  }

  /**
   *
   * @param activityId
   * @param episodeId
   * @param sessionId
   * @param skipped     Set to true if the session was skipped
   */
  public completeASession(
    activityId: string,
    episodeId: string,
    sessionId: string,
    skipped: boolean,
    taskData: { [id: string]: TaskLog }
  ) {
    let a: ActivityCompletion = this.completions.activities.find(activity => {
      return activity._id === activityId;
    });
    if (a) {
      let e: EpisodeCompletion = a.episodes.find(episode => {
        return episode._id === episodeId;
      });
      if (e) {
        let s: SessionCompletion = e.sessions.find(session => {
          return session._id === sessionId;
        });
        if (!s) {
          e.sessions.push({ _id: sessionId, skipped: skipped, taskData });
        }
      } else {
        a.episodes.push({
          _id: episodeId,
          completed: false,
          sessions: [{ _id: sessionId, skipped, taskData }]
        });
      }
    } else {
      this.completions.activities.push({
        _id: activityId,
        completed: false,
        episodes: [
          {
            _id: episodeId,
            completed: false,
            sessions: [{ _id: sessionId, skipped, taskData }]
          }
        ]
      });
    }
  }
  public completeAnEpisode(activityId: string, episodeId: string) {
    let a: ActivityCompletion = this.completions.activities.find(activity => {
      return activity._id === activityId;
    });
    if (a) {
      let e: EpisodeCompletion = a.episodes.find(episode => {
        return episode._id === episodeId;
      });
      if (e) {
        e.completed = true;
      }
    }
  }
  public completeAnActivity(activityId: string) {
    let a: ActivityCompletion = this.completions.activities.find(activity => {
      return activity._id === activityId;
    });
    if (a) {
      a.completed = true;
    }
  }

  public getCompletedEpisodes(activityId): EpisodeCompletion[] {
    let activity: ActivityCompletion = this.completions.activities.find(a => {
      return a._id === activityId;
    });
    if (activity) {
      return activity.episodes.filter(e => {
        return e.completed;
      });
    } else {
      return [];
    }
  }

  public getCompletedSessions(activityId, episodeId): SessionCompletion[] {
    let a: ActivityCompletion = this.completions.activities.find(a => {
      return a._id === activityId;
    });
    if (a) {
      let e: EpisodeCompletion = a.episodes.find(e => {
        return e._id === episodeId;
      });
      if (e) {
        return e.sessions;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
}

export class Episode {
  _id: string;
  name: string;
  slug: string;
  description: string;
  location: number;
  openDateTime: Date;
  closeDateTime: Date;
  password: string;
  scenes: {
    ins: number[];
    outs: number[];
  };
  sessions: Session[];
  completed?: boolean;

  constructor(data) {
    this._id = data._id;
    this.name = data.name;
    this.location = data.location;
    this.description = data.description;
    this.slug = data.slug;
    this.openDateTime = data.openDateTime;
    this.closeDateTime = data.closeDateTime;
    this.password = data.password;
    this.scenes = { ins: [], outs: [] };
    if (data.scenes && data.scenes.ins) {
      this.scenes.ins = data.scenes.ins.split(",");
    }
    if (data.scenes && data.scenes.outs) {
      this.scenes.outs = data.scenes.outs.split(",");
    }
    this.sessions = [];
    if (data.hasOwnProperty("sessions")) {
      data.sessions.forEach(session => {
        this.sessions.push(new Session(session, false));
      });
    }
  }
}

export class Activity {
  _id: string;
  name: string;
  description: string;
  searchKey: string;
  episodes: Episode[];
  beginDate: Date;
  finishDate: Date;
  openingHour: number;
  closingHour: number;
  sessionLocations: Location[];
  episodeLocations: Location[];

  sessionLocationDict?: { [id: string]: Location };
  episodeLocationDict?: { [id: string]: Location };

  constructor(data) {
    this._id = data._id;
    this.name = data.name;
    this.description = data.description;
    this.searchKey = data.searchKey;
    this.episodes = [];
    if (data.hasOwnProperty("episodes")) {
      data.episodes.forEach(episode => {
        this.episodes.push(new Episode(episode));
      });
    }
    this.sessionLocations = [];
    this.sessionLocationDict = {};
    if (data.hasOwnProperty("sessionLocations")) {
      data.sessionLocations.forEach(location => {
        const l = new Location(location);
        this.sessionLocations.push(l);
        this.sessionLocationDict[l.value] = l;
      });
    }
    this.episodeLocations = [];
    this.episodeLocationDict = {};
    if (data.hasOwnProperty("episodeLocations")) {
      data.episodeLocations.forEach(location => {
        const l = new Location(location);
        this.episodeLocations.push(l);
        this.episodeLocationDict[l.value] = l;
      });
    }
    this.beginDate = data.beginDate;
    this.finishDate = data.finishDate;
    this.openingHour = data.openingHour;
    this.closingHour = data.closingHour;
  }
}

export class Log {
  log_id: string;
  student_id: string;
  session_id: string;
  logType: string;
  synced: boolean;
  completed: boolean;

  constructor(student_id, session_id, log_id, synced, completed) {
    this.student_id = student_id;
    this.session_id = session_id;
    this.log_id = log_id;
    this.synced = synced;
    this.completed = completed;
  }
}

export class TaskLog extends Log {
  task_id: string;
  task_reference: string;
  task_type: number;
  position: number;
  shown_at: Date;
  incorrect: number;
  duration: number;
  category: string; // 'warmup' or 'test'
  answer_details: string;
  use_audio_instructions: number;
  use_audio_content_items: number;

  constructor(
    task_id: string,
    student_id: string,
    session_id: string,
    task_reference: string,
    task_type: number,
    position: number,
    log_id = Utilities.v4(),
    synced = false,
    completed = false,
    shown_at = new Date(),
    incorrect = 0,
    duration = 0,
    answer_details = "",
    use_audio_instructions = 0,
    use_audio_content_items = 0
  ) {
    super(student_id, session_id, log_id, synced, completed);
    this.task_id = task_id;
    this.task_reference = task_reference;
    this.task_type = task_type;
    this.position = position;
    this.shown_at = shown_at;
    this.incorrect = incorrect;
    this.duration = duration;
    this.logType = "task";
    this.answer_details = answer_details;
    this.use_audio_instructions = use_audio_instructions;
    this.use_audio_content_items = use_audio_content_items;
  }
}

export class SessionLog extends Log {
  session_id: string;
  student_id: string;
  session_reference: string;
  tasks_total: number;
  start_time: Date;
  duration: number;
  inactive_count: number;
  inactive_duration: number;
  tasks_completed: number;

  taskLogs: { [id: string]: TaskLog };
  selectedTaskLog: TaskLog;

  constructor(
    session_id: string,
    student_id: string,
    session_reference: string,
    tasks_total: number,
    log_id = Utilities.v4(),
    synced = false,
    completed = false,
    start_time: Date = new Date(),
    duration: number = 0,
    inactive_count: number = 0,
    inactive_duration: number = 0,
    tasks_completed: number = 0,
    taskLogs?: {}
  ) {
    super(student_id, session_id, log_id, synced, completed);
    this.session_reference = session_reference;
    this.tasks_total = tasks_total;
    this.start_time = start_time;
    this.duration = duration;
    this.inactive_count = inactive_count;
    this.inactive_duration = inactive_duration;
    this.tasks_completed = tasks_completed;

    // Other data
    this.taskLogs = {};
    if (taskLogs) {
      for (let tl in taskLogs) {
        if (taskLogs.hasOwnProperty(tl)) {
          let tlObj = taskLogs[tl];
          let newTaskLog = new TaskLog(
            tlObj["task_id"],
            tlObj["student_id"],
            tlObj["session_id"],
            tlObj["task_reference"],
            tlObj["task_type"],
            tlObj["position"],
            tlObj["log_id"],
            tlObj["synced"],
            tlObj["completed"],
            tlObj["shown_at"],
            tlObj["incorrect"],
            tlObj["duration"],
            tlObj["answer_details"],
            tlObj["use_audio_instructions"],
            tlObj["use_audio_content_items"]
          );
          this.taskLogs[newTaskLog.task_id] = newTaskLog;
        }
      }
    }
    this.selectedTaskLog = null;
    this.logType = "session";
  }

  addAndSelectTaskLog(log: TaskLog) {
    this.taskLogs[log.task_id] = log;
    this.selectedTaskLog = log;
  }
}

export interface TaskLog1to11 {
  attempt: string;
  correct: boolean;
}

export interface TaskLog12 {
  attempt: string;
  edited: boolean;
  elapsed: number;
}

export interface TaskLog13 {
  attempt: string;
  elapsed: number;
}

export class GroupResponse {
  group: StudentGroup;
}

export class ConnectStudentResponse {
  groupid: string;
  message: string;
}

export class TaskResponse {
  taskData: {}; // Raw task data from server
  taskType: string; // The specific type of this task
}

export class SessionResponse {
  session: Session;
}

/*export interface TasksResponse extends Response {
  data: TaskResponse[];
}*/

export interface ActivityResponse extends Response {
  name: string;
  description: string;
  searchKey: string;
  sessions: Session[];
}

export interface LoginResponse extends Response {
  student: Student;
  activity: Activity;
}

export interface StudentsResponse extends Response {
  students: Student[];
  activity: Activity;
}

export interface TestStudentsResponse extends Response {
  students: Student[];
  activity: Activity;
}

export interface GroupActivityResponse extends Response {
  groups: StudentGroup[];
  activities: Activity[];
}

export interface StudentDetailResponse extends Response {
  student: Student;
}

export interface LogResponse extends Response {
  statusMessage: string;
  data: Log[];
}
