import { Injectable, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import * as moment from "moment";
import { Constants, EpisodeScenes } from "../constants";
import { ServerService } from "./server.service";
import {
  Activity,
  AppStorage,
  LoginResponse,
  Progress,
  Session,
  SessionLog,
  Student,
  TaskLog,
  TaskResponse,
  Episode,
  SessionResponse,
  ViewState,
  DelayMode,
  SceneType,
  SceneMode,
  LogResponse,
  EpisodeCompletion,
  SessionCompletion,
  ActivityPhase,
  SessionPhase,
  EpisodePhase,
  Location,
  Narration,
  TaskMode,
  ShipMode,
  LoginMode,
  Results
} from "../models/general.model";
import { CordovaService } from "./cordova.service";
import { Router } from "@angular/router";
import { BrowserService } from "./browser.service";

import { Utilities } from "../utilities/utilities";
import { BehaviorSubject } from "rxjs";
import { myWindow } from "../../typings/index";

declare let Connection;
declare let window: myWindow;

@Injectable()
export class DataService {
  private errorMessage: any;
  private _appStorage: AppStorage;
  private _progress: Progress;

  private _speakerSound = null;
  private _speakerIsPlaying: boolean;
  private _inactivityTimestamp = null;
  private _sceneInProgress = false;
  private _speechController: Narration;

  private _usingWebAudio: boolean;
  private _audioContext: AudioContext;

  private _currentTaskSet;
  private _currentTaskIndex = -1;
  private _backgroundImage = "";
  private _savedBackgroundImage = "";
  private _backgroundOpacity = 0;

  private _postLogsAttempts = 0;

  private selectedTaskTypeSubject: BehaviorSubject<
    number
  > = new BehaviorSubject<number>(0);

  private audioStore = {
    media: {}
  };

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private browser: BrowserService,
    private serverService: ServerService,
    private cordovaService: CordovaService
  ) {
    this._appStorage = new AppStorage();
    this._progress = new Progress();
    this._speechController = new Narration();
    this._usingWebAudio = false;

    document.addEventListener("offline", this.onOffline, false);
    document.addEventListener("online", this.onOnline, false);
    window.addEventListener("offline", this.onOffline, false);
    window.addEventListener("online", this.onOnline, false);

    // this.refreshSessionListing();

    window.playSound = this.playSound.bind(this);

    this._speakerIsPlaying = false;

    if (this.isCordovaApp) {
      this.cordovaService.loadConfig();
    }

    if ("webkitAudioContext" in window) {
      // @ts-ignore
      this._audioContext = new window.webkitAudioContext();
      this._usingWebAudio = true;
    } else if ("AudioContext" in window) {
      // @ts-ignore
      this._audioContext = new window.AudioContext();
      this._usingWebAudio = true;
    }
  }

  /* ------------------- Data Getters / Setters ---------------------- */

  public get currentTask() {
    return this._appStorage.selectedTask;
  }

  public get currentEpisode() {
    return this._appStorage.selectedEpisode;
  }

  public get selectedTaskType() {
    return this.selectedTaskTypeSubject;
  }

  public setSelectedTaskType(type: number) {
    this.selectedTaskTypeSubject.next(type);
  }

  public get unforgivingTestMode() {
    return this._appStorage.unforgivingTestMode;
  }

  public get backgroundImage() {
    return this._backgroundImage;
  }

  public get backgroundOpacity() {
    return this._backgroundOpacity;
  }

  public set backgroundImage(src: string) {
    this._backgroundImage = src;
  }

  public set backgroundOpacity(value: number) {
    this._backgroundOpacity = value;
  }

  public get student() {
    return this._appStorage.student;
  }

  public get isCordovaApp() {
    return !!this.browser.window["cordova"];
  }

 /*  public get isIOS() {
    const ua = this.browser.window.navigator.userAgent;
    return /(iPad|iPhone|iPod).*WebKit/.test(ua) && !/(CriOS|OPiOS)/.test(ua);
  } */

  public get userIsOrganiser() {
    return this._appStorage.student ? this._appStorage.student.isOrganiser : false;
  }

  public get isOnline() {
    return this._appStorage.isOnline;
  }

  public get demoMode() {
    return this._appStorage.demoMode;
  }

  public get broadReleasePretestMode() {
    return this._appStorage.broadReleasePretestMode;
  }

  public set broadReleasePretestMode(newState: boolean) {
    this._appStorage.broadReleasePretestMode = newState;
  }

  public get sceneSubpath() {
    return this._appStorage.selectedSceneSubpath; // This is the number of the folder on disk at  assets/scenes/
  }

  public get currentSession() {
    return this._appStorage.selectedSession;
  }

  public get nextUncompletedSession() {
    return this._appStorage.nextUncompletedSession;
  }

  // Some Activities, Episodes and Sessions are special cases, we need these functions to check
  public get activityPhase() {
    return this._appStorage.activityPhase;
  }

  public get episodePhase() {
    return this._appStorage.episodePhase;
  }

  public get sessionPhase() {
    return this._appStorage.getSessionPhase();
  }

  public getSessionPhaseForSession(session) {
    return this._appStorage.getSessionPhase(session);
  }

  public get speech(): Narration {
    return this._speechController;
  }

  // Access to state / mode management enums
  public get viewState() {
    return this._progress.state.viewState;
  }

  public set viewState(state: ViewState) {
    this._progress.state.viewState = state;
  }

  public get loginMode(): LoginMode {
    return this._progress.state.loginMode;
  }

  public set loginMode(mode: LoginMode) {
    this._progress.state.loginMode = mode;
  }

  public get shipMode(): ShipMode {
    return this._progress.state.shipMode;
  }

  public set shipMode(mode: ShipMode) {
    this._progress.state.shipMode = mode;
  }

  public get taskMode(): TaskMode {
    return this._progress.state.taskMode;
  }

  public set taskMode(mode: TaskMode) {
    this._progress.state.taskMode = mode;
  }

  public get delayMode(): DelayMode {
    return this._progress.state.delayMode;
  }

  public set delayMode(mode: DelayMode) {
    this._progress.state.delayMode = mode;
  }

  public get sceneMode(): SceneMode {
    return this._progress.state.sceneMode;
  }

  public set sceneMode(mode: SceneMode) {
    this._progress.state.sceneMode = mode;
  }

  public get sceneType(): SceneType {
    return this._progress.state.sceneType;
  }

  public set sceneType(mode: SceneType) {
    this._progress.state.sceneType = mode;
  }

  public get sceneInProgress() {
    return this._sceneInProgress;
  }

  public set sceneInProgress(p: boolean) {
    this._sceneInProgress = p;
  }

  public setTestStudentDetails(student: Student, activity: Activity) {
    this._appStorage.student = new Student(student);
    this._appStorage.activity = new Activity(activity);
  }

  /* ------------------- State Machine ---------------------- */

  // Determine which scene to play
  // Remember: By default scenes play before advancing the ViewState, so NEXT ViewState should be set before playing the scene
  private getSceneSet(type: SceneType): string[] {
    let sceneSet = [];
    switch (type) {
      case SceneType.GeneralScene:
        if (this.viewState === ViewState.Map) {
          sceneSet = ["general/2/"];
        } else if (this.viewState === ViewState.Ship) {
          sceneSet = ["general/1/"];
        } else if (this.viewState === ViewState.Tasks) {
          sceneSet = ["general/3/"];
        }
        break;
      case SceneType.EpisodeInScene:
        if (this._appStorage.activityPhase === ActivityPhase.Demo) {
          sceneSet = EpisodeScenes[0].intros;
        }/*  else if (this._appStorage.activityPhase === ActivityPhase.BroadRelease) {
          sceneSet = this._appStorage.broadReleasePretestMode ? EpisodeScenes[1].intros : EpisodeScenes[2].intros;
        } */ else if (this._appStorage.episodePhase === EpisodePhase.First) {
          sceneSet = EpisodeScenes[1].intros;
        } else if (
          this._appStorage.episodePhase === EpisodePhase.Second &&
          (this.sessionPhase === SessionPhase.First ||
            this.sessionPhase === SessionPhase.FirstAndLast)
        ) {
          sceneSet = EpisodeScenes[2].intros;
        } else if (
          [EpisodePhase.Third, EpisodePhase.Fourth, EpisodePhase.Fifth].indexOf(
            this._appStorage.episodePhase
          ) > -1
        ) {
          sceneSet = EpisodeScenes[this._appStorage.episodePhase].intros;
        }
        break;
      case SceneType.EpisodeOutScene:
        if (this._appStorage.activityPhase === ActivityPhase.Demo) {
          sceneSet = EpisodeScenes[0].outros;
        }/*  else if (this._appStorage.activityPhase === ActivityPhase.BroadRelease) {
          sceneSet = this._appStorage.broadReleasePretestMode ? EpisodeScenes[1].outros : EpisodeScenes[2].outros;
        } */ else {
          sceneSet = EpisodeScenes[this._appStorage.episodePhase].outros;
        }
        break;
      case SceneType.MorfologicalScene:
        sceneSet = [
          "morfological/" +
            this._appStorage.selectedSession.morfologicalIntro +
            "/"
        ];
        break;
      case SceneType.ConsolidationInScene:
        switch (this.sessionPhase) {
          case SessionPhase.One:
            sceneSet = ["sessions/2/"];
            break;
          case SessionPhase.Two:
          case SessionPhase.Three:
          case SessionPhase.Four:
          case SessionPhase.Five:
          case SessionPhase.Six:
          case SessionPhase.Seven:
          case SessionPhase.Eight:
            sceneSet = ["sessions/3/"];
            break;
          case SessionPhase.Last:
            sceneSet = ["sessions/4/"];
            break;
        }
        break;
      case SceneType.SessionInScene:
        sceneSet = ["sessions/1/"];
        break;
    }
    return sceneSet;
  }

  // Secondary state function called to play scenes.  At the end of scene sequence, we update the Data State once again
  private playScenes(dontFade: boolean) {
    let nextScene = (scenes: string[]): string => {
      let furtherScenes = scenes.filter((scene, index) => {
        return index > scenes.indexOf(this._appStorage.selectedSceneSubpath);
      });
      if (furtherScenes.length > 0) {
        return furtherScenes[0];
      } else {
        return "";
      }
    };

    let s = nextScene(this.getSceneSet(this.sceneType));

    // If scene is 'transparent' use the location background, otherwise use a black background
    if (this._savedBackgroundImage === "") {
      this._savedBackgroundImage = this._backgroundImage;
    }
    if (
      this.sceneType !== SceneType.SessionInScene &&
      this.sceneType !== SceneType.MorfologicalScene &&
      !(
        this.sceneType === SceneType.GeneralScene &&
        this.viewState === ViewState.Tasks
      )
    ) {
      this._backgroundImage = "assets/images/BlackBackground@2x.png";
    }
    this._backgroundOpacity = 1;

    // There are more scenes to play... so go ahead and play them
    if (s !== "") {
      this._appStorage.selectedSceneSubpath = s;
      this.sceneMode = SceneMode.InProgress;
      this.navigateToView("/scene/" + Date.now());
    } else {
      // We are done with scenes, go back to the main state update
      this._appStorage.selectedSceneSubpath = "";
      this.sceneMode = SceneMode.Finished;
      this._backgroundImage = this._savedBackgroundImage;
      this._savedBackgroundImage = "";
      this.updateState(dontFade);
    }
  }

  // Main state function called when Data State needs to be updated
  public updateState(dontFade?: boolean) {
    if (!dontFade) {
      this._backgroundOpacity = 0;
    }

    setTimeout(() => {
      // There may be more scenes to play
      if (this.sceneMode !== SceneMode.Finished) {
        setTimeout(() => {
          this.playScenes(dontFade);
        }, dontFade ? 0 : 500);

        // Otherwise decide which view to navigate to
      } else {
        switch (this.viewState) {
          // The app has just finished loading
          case ViewState.None:
            this.loginMode = LoginMode.AppStarted;
            this.viewState = ViewState.Login;
            this.updateState();
            break;

          case ViewState.Login:
            this._backgroundImage =
              "assets/images/login/LogInBackground@2x.png";
            this.navigateToView("/login");
            this._appStorage.resetStorage();
            this._progress.reset();
            break;

          case ViewState.Loggedin:
              this._backgroundImage =
                "assets/images/login/LogInBackground@2x.png";
              break;

          case ViewState.Ship:
            this._backgroundImage =
              "assets/images/map/locations/" +
              this._appStorage.activity.episodeLocationDict[
                this._appStorage.selectedEpisode.location
              ].filename;

            // After playing an 'outro' we don't want to see the Ship view again
            if (
              this.shipMode === ShipMode.SessionCompleted &&
              this.activityPhase === ActivityPhase.RCT &&
              this.episodePhase !== EpisodePhase.First &&
              this._appStorage.getSessionPhase(
                this._appStorage.selectedSession
              ) === SessionPhase.Last
            ) {
              this.exitToLogin();
            } else {
              this.navigateToView("/ship");
            }
            break;

          case ViewState.Map:
            this.navigateToView("/map");
            break;

          case ViewState.Delay:
            // Why are we here?

            this._backgroundOpacity = 1;
            this.navigateToView("/delay");
            break;

          case ViewState.Tasks:
            // Starting the first task in the session
            if (this._currentTaskIndex === 0) {
              // Begin a new Session Log if it doesn't exist from a previous attempt, or we're starting a new session.
              // We begin here rather than in configureSession to avoid timers including Scenes
              if (
                !this._appStorage.sessionLogs.hasOwnProperty(
                  this._appStorage.selectedSession._id
                )
              ) {
                this._appStorage.sessionLogs[
                  this._appStorage.selectedSession._id
                ] = new SessionLog(
                  this._appStorage.selectedSession._id,
                  this._appStorage.student.user_id,
                  this._appStorage.selectedSession.name,
                  this._appStorage.selectedSession.getTotalTaskCount()
                );
              }
              this._appStorage.selectedSessionLog = this._appStorage.sessionLogs[
                this._appStorage.selectedSession._id
              ];

              this._progress.resetRandomMorf();

              // Starting the last task in the session
            } else if (
              this._currentTaskIndex ===
              this._currentTaskSet.length - 1
            ) {
              // Starting a task somewhere in the middle
            } else {
              // At any time except warmups and the first time we change to Tasks: Play a random Morf popup scene if needed
              if (
                this._progress.randomMorphTime &&
                this.taskMode === TaskMode.Tests
              ) {
                this.sceneType = SceneType.GeneralScene;
                this.sceneMode = SceneMode.Ready;
                this.playScenes(false);
                break;
              }
            }

            this._inactivityTimestamp = moment();
            this._appStorage.selectedTask = this._currentTaskSet[
              this._currentTaskIndex
            ];

            // Begin a new Task Log
            this._appStorage.selectedSessionLog.addAndSelectTaskLog(
              new TaskLog(
                this._appStorage.selectedTask._id,
                this._appStorage.student.user_id,
                this._appStorage.selectedSession._id,
                this._appStorage.selectedTask.reference,
                this._appStorage.selectedTask.taskType,
                this._currentTaskIndex
              )
            );

            // Reset the results container for the new task
            this._progress.results = new Results();

            this.navigateToView("/task");
            this.setSelectedTaskType(this._appStorage.selectedTask.taskType);
            break;
        }
        this._backgroundOpacity = 1;
      }
    }, dontFade ? 0 : 500);
  }

  public activateSession(session: Session, password: string, callback) {
    if (session.activateSession(password)) {
      this.shipMode = ShipMode.SessionUnlocked;

      // Go back to the server and load detailed Session and Task data for the NEXT session.
      // ** This call is asynchronous **
      this.getSessionWithTasks(session, callback);
      return true;
    } else {
      return false;
    }
  }

  skipSession(nextSession, passwordText) {
    if (Constants.SKIP_SESSION_PASSWORD === passwordText) {
      this._appStorage.completeASession(
        this._appStorage.activity._id,
        this._appStorage.selectedEpisode._id,
        nextSession,
        true,
        {}
      );
      return true;
    } else {
      return false;
    }
  }

  // Run when the Student first enters the Ship view, this checks their completed sessions and marks the main Session collection accordingly
  private markCompletedAndAddLocationsSessions() {
    let completedSessionIDs: SessionCompletion[] = this._appStorage.student.getCompletedSessions(
      this._appStorage.activity._id,
      this._appStorage.selectedEpisode._id
    );

    this._appStorage.selectedEpisode.sessions.forEach(es => {
      es.location = new Location(
        this._appStorage.activity.sessionLocationDict[es.locationValue]
      );
      completedSessionIDs.forEach(cs => {
        if (cs._id === es._id) {
          es.completed = true;
          es.skipped = cs.skipped;
        }
      });
    });
  }

  public locationForSession(sessionId): Location {
    return this._appStorage.activity.sessionLocationDict[sessionId];
  }

  private navigateToView(viewPath) {
    this.ngZone.run(() => {
      this.router.navigate([viewPath]);
    });
  }

  private navigateToMapView() {
    this.navigateToView("/map");
  }

  private navigateToShipView() {
    this.navigateToView("/ship");
  }

  private navigateToLoginView() {
    this.navigateToView("/login");
  }

  // --------------  Demo functions -------------------

  /* skipAheadDemo() {
     progress.visible = false;
     this._appStorage.student.completed.push(this._appStorage.selectedSession._id);
     $location.path('/sessions');
   };*/

  exitToLogin() {
    this.viewState = ViewState.Login;
    this.loginMode = LoginMode.LoggedOut;
    this.sceneMode = SceneMode.Finished;
    this.updateState();
  }

  /* ------------------- Progress Bar ---------------------- */

  public get progress() {
    return this._progress;
  }

  public set progress(p: Progress) {
    this.ngZone.run(() => {
      this._progress = p;
    });
  }

  public progressShow(data) {
    if (!this._progress.visible) {
      this._progress.visible = true;
      setTimeout(() => {
        this._progress.opacity = 1;
      }, 1000);
    }
    if (typeof data.stars !== "undefined") {
      this._progress.starData.stars = data.stars;
      this._progress.starData.completed = 0;
    }
  }

  public progressHide() {
    if (this._progress.visible) {
      this._progress.opacity = 0;
      setTimeout(() => {
        this._progress.visible = false;
      }, 1000);
    }
  }

  private calculateEpisodeProgress(): number {
    if (
      ((this._appStorage.activityPhase === ActivityPhase.RCT ||
        this._appStorage.activityPhase === ActivityPhase.BroadRelease) &&
        this._appStorage.episodePhase === EpisodePhase.Second) ||
      this._appStorage.activityPhase === ActivityPhase.None
    ) {
      let completed = 0,
        total = 0;
      this._appStorage.selectedEpisode.sessions.forEach(s => {
        total++;
        completed += s.completed ? 1 : 0;
      });
      const percentage = completed / total * 100;
      return percentage === 0 ? 1 : percentage;
    } else if (
      ((this._appStorage.activityPhase === ActivityPhase.RCT  ||
        this._appStorage.activityPhase === ActivityPhase.BroadRelease) &&
        this._appStorage.episodePhase !== EpisodePhase.Second) ||
      this._appStorage.activityPhase === ActivityPhase.Demo
    ) {
      return 100;
    }
  }

  /* ------------------- Audio ---------------------- */

  public playSound(id, loop) {
    if (this._usingWebAudio) {
      let cachedBuffer = this.audioStore.media[id];
      if (cachedBuffer) {
        let bufferedSound: AudioBufferSourceNode = this._audioContext.createBufferSource();
        bufferedSound.buffer = cachedBuffer;
        bufferedSound.connect(this._audioContext.destination);
        bufferedSound.start(0);
      }
    } else {
      this.ngZone.run(() => {
        let sound: HTMLAudioElement = this.audioStore.media[id];
        if (sound) {
          sound.currentTime = 0;
          sound.play();
        } else {
          console.log("Sound id " + id + " is not registered");
        }
      });
    }
  }

  public registerSound(src, id, resolve, reject) {
    if (this._usingWebAudio) {
      let request = new XMLHttpRequest();
      request.open("GET", src, true);
      request.responseType = "arraybuffer";
      request.onload = () => {
        this._audioContext.decodeAudioData(
          request.response,
          buffer => {
            this.audioStore.media[id] = buffer;
            if (resolve) {
              resolve(id);
            }
          },
          error => {
            if (reject) {
              reject();
            }
            console.log(error.toString());
          }
        );
      };
      request.send();
    } else {
      let newAudio = new Audio(src);
      newAudio.load();
      this.audioStore.media[id] = newAudio;
    }
  }

  public unregisterSound(id) {
    if (this._usingWebAudio) {
      if (typeof this.audioStore.media[id] !== "undefined") {
        delete this.audioStore.media[id];
      }
    } else {
      let sound: HTMLAudioElement = this.audioStore.media[id];
      if (sound != null) {
        delete this.audioStore.media[id];
      }
    }
  }

  public speakerClick() {
    if (this._speakerSound !== null && !this._speakerIsPlaying) {
      this._speakerSound.play();
      this._speakerIsPlaying = true;
      this._progress.results.use_audio_instructions++;
    }
  }

  public get speakerIsPlaying() {
    return this._speakerIsPlaying;
  }

  public set speakerIsPlaying(on: boolean) {
    this._speakerIsPlaying = on;
  }

  public setSpeakerSound(url1, url2) {
    if (url1 !== null && url1 !== "") {
      this._speakerSound = new Audio(url1);
      if (url2 !== null && url2 !== "") {
        let secondSound = new Audio(url2);
        secondSound.addEventListener("ended", () => {
          this.ngZone.run(() => {
            this._speakerIsPlaying = false;
          });
        });
        this._speakerSound.addEventListener("ended", () => {
          setTimeout(() => {
            secondSound.play();
          }, 500);
        });
      } else {
        this._speakerSound.addEventListener("ended", () => {
          this.ngZone.run(() => {
            this._speakerIsPlaying = false;
          });
        });
      }
    } else if (url1 === null && url2 !== null && url2 !== "") {
      this._speakerSound = new Audio(url2);
      this._speakerSound.addEventListener("ended", () => {
        this.ngZone.run(() => {
          this._speakerIsPlaying = false;
        });
      });
    } else {
      this._speakerSound = null;
    }
  }

  tryAgain() {
    //new Audio('audio/tryagain1.mp3').play();
  }

  /* ------------------- On / Offline ---------------------- */

  onOffline() {
    setTimeout(() => {
      this._appStorage.isOnline = false;
    }, 0);
  }

  onOnline() {
    if (
      typeof navigator["connection"] === "undefined" ||
      navigator["connection"].type !== Connection.NONE
    ) {
      setTimeout(() => {
        this._appStorage.isOnline = true;
      }, 0);
    }
  }

  /* ------------------- Logging ---------------------- */

  // Run each time a Task is completed
  updateSessionLog() {
    let log = this._appStorage.selectedSessionLog;
    log.duration = moment().diff(moment(log.start_time), "seconds");
    log.tasks_completed++;
  }

  // Run each time a Task is completed
  completeTaskLog() {
    let log = this._appStorage.selectedSessionLog.selectedTaskLog;
    log.incorrect = this._progress.results.incorrectAttempts;
    log.use_audio_content_items = this._progress.results.use_audio_content_items;
    log.use_audio_instructions = this._progress.results.use_audio_instructions;
    log.answer_details = JSON.stringify(this._progress.results.answer_details);
    log.duration = moment().diff(moment(log.shown_at), "seconds");
    log.completed = true;
  }

  // Process and post the tracking data to Nettskjema
  prepareAndPostUsageData() {
    let logs = [];

    // Include only un-synced Sessions and Tasks in a flattened array. A synced Session does not mean all its Tasks are synced
    for (let s in this._appStorage.sessionLogs) {
      if (this._appStorage.sessionLogs.hasOwnProperty(s)) {
        let sessionLog: SessionLog = this._appStorage.sessionLogs[s];
        for (let t in sessionLog.taskLogs) {
          if (
            sessionLog.taskLogs.hasOwnProperty(t) &&
            !sessionLog.taskLogs[t].synced
          ) {
            logs.push(sessionLog.taskLogs[t]);
          }
        }
        if (!sessionLog.synced && sessionLog.completed) {
          logs.push(sessionLog);
        }
      }
    }

    if (logs.length > 0) {
      this.serverService
        .postToNettskjema(
          logs,
          this._appStorage.activity._id,
          this.cordovaService.tabletPasscode,
          this._appStorage.student
        )
        .subscribe(
          (result: LogResponse) => {
            // Check for failed logs submissions in response
            logs.forEach(
              log =>
                (log.synced =
                  Array.isArray(result.data) &&
                  result.data.indexOf(log.log_id) === -1)
            );
            // Clear the logs that were successfully sent
            // Disabled pending resolution of missing log files in TSD
            // this.clearCompletedSessionLogs();
            this._postLogsAttempts = 0;
            // Save remaining logs
            if (this.isCordovaApp && !this._appStorage.demoMode) {
              this.cordovaService.saveLogs(this._appStorage.sessionLogs);
            }
          },
          () => {
            this._postLogsAttempts++;
            if (this._postLogsAttempts < 3) {
              setTimeout(() => {
                this.prepareAndPostUsageData();
              }, 3000);
            } else {
              // Give up for now..
              this._postLogsAttempts = 0;
            }
          }
        );
    }
  }

  clearCompletedSessionLogs() {
    for (let s in this._appStorage.sessionLogs) {
      let taskLogCount = -1;
      if (this._appStorage.sessionLogs.hasOwnProperty(s)) {
        taskLogCount = Object.keys(this._appStorage.sessionLogs[s].taskLogs)
          .length;
        for (let t in this._appStorage.sessionLogs[s].taskLogs) {
          if (this._appStorage.sessionLogs[s].taskLogs.hasOwnProperty(t)) {
            if (
              this._appStorage.sessionLogs[s].taskLogs[t].synced &&
              this._appStorage.sessionLogs[s].taskLogs[t].completed
            ) {
              delete this._appStorage.sessionLogs[s].taskLogs[t];
              taskLogCount--;
            }
          }
        }
        if (
          this._appStorage.sessionLogs[s].synced &&
          this._appStorage.sessionLogs[s].completed &&
          taskLogCount === 0
        ) {
          delete this._appStorage.sessionLogs[s];
        }
      }
    }
  }

  resetInactiveTimer() {
    if (
      this._inactivityTimestamp !== null &&
      this.taskMode !== TaskMode.Sample
    ) {
      let diff = moment().diff(this._inactivityTimestamp, "seconds");
      if (
        diff > 30 &&
        this._appStorage.sessionLogs.hasOwnProperty(
          this._appStorage.selectedSession._id
        )
      ) {
        let log = this._appStorage.sessionLogs[
          this._appStorage.selectedSession._id
        ];
        log.inactive_count++;
        log.inactive_duration += diff;
      }
    }
    this._inactivityTimestamp = moment();
  }

  /* ------------------- Task Runner ---------------------- */

  completeTask() {
    let dontFade = true;
    if (this.taskMode !== TaskMode.Sample) {
      this._speakerSound = null;
      let taskData: { [id: string]: TaskLog } = {}

      // Finalise the logs
      this.completeTaskLog();
      this.updateSessionLog();
      if (
        (this.taskMode !== TaskMode.Warmups ||
          this._appStorage.selectedSession.testTaskCount === 0) &&
        this._currentTaskIndex === this._currentTaskSet.length - 1
      ) {
        this._appStorage.selectedSessionLog.completed = true;
      }
      taskData = this._appStorage.selectedSessionLog.taskLogs

      // Save the log files if running on a mobile device
      if (this.isCordovaApp && !this._appStorage.demoMode) {
        this.cordovaService.saveLogs(this._appStorage.sessionLogs);
      }

      // Advance the task index
      if (this._currentTaskIndex < this._currentTaskSet.length) {
        this._currentTaskIndex++;
      }

      // Advance the progress bar
      //this._progress.barData.completedPercent =
      //  this._currentTaskIndex / this._currentTaskSet.length * 100;

      let fullSesisonTaskLength =
        this.taskMode === TaskMode.Warmups
          ? this._appStorage.selectedSession.warmupTaskCount
          : this._appStorage.selectedSession.testTaskCount;
      this._progress.barData.completedPercent =
        (fullSesisonTaskLength -
          this._currentTaskSet.length +
          this._currentTaskIndex) /
        fullSesisonTaskLength *
        100;

      // End of the task set - all tasks complete. Allow the progress bar time to fill
      setTimeout(() => {
        // Was that the final Task?
        if (this._currentTaskIndex === this._currentTaskSet.length) {
          dontFade = false;

          // After finishing warmup tasks, go to the next delay screen
          if (this.taskMode === TaskMode.Warmups) {
            this.delayMode = DelayMode.WarmupsFinished;
            this.taskMode = TaskMode.Tests;
            this.configureSession();
          } else {
            // Or finish and go back to the Map (unless this was a consolidation Session)
            // Complete the logs for this group of tasks
            this._appStorage.completeASession(
              this._appStorage.activity._id,
              this._appStorage.selectedEpisode._id,
              this._appStorage.selectedSession,
              false,
              taskData
            );

            // Recalculate stardust level
            this._progress.shipBarData.completedPercent = this.calculateEpisodeProgress();

            // Post usage data to Nettskjema
            if (!this._appStorage.demoMode) {
              this._appStorage.student.lastCompletion = Date.now();
              this.updateStudent(() => {
                this.prepareAndPostUsageData();
              });
            }

            // Reset session log for next round
            this._appStorage.selectedSessionLog = null;

            // Pre-test & post-test: Go directly to exit scenes
            if (
              this.activityPhase === ActivityPhase.RCT &&
              this.episodePhase !== EpisodePhase.Second
            ) {
              this._appStorage.selectedSession.activated = false;
              this.viewState = ViewState.Ship;
              this.sceneMode = SceneMode.Ready;
              this.sceneType = SceneType.EpisodeOutScene;
            } else if (this._appStorage.selectedSession.consolidation) {
              // After Consolidation session: go directly to the Ship
              this._appStorage.selectedSession.activated = false;
              this.sceneMode = SceneMode.Ready;
              this.sceneType = SceneType.GeneralScene;
              this.viewState = ViewState.Ship;
            } else {
              // Otherwise, to the Map
              this.viewState = ViewState.Map;
            }
            this.shipMode = ShipMode.SessionEnding;
          }
        }
        // ( Else, there are more tasks to go; continue..)

        this.updateState(dontFade);
      }, this._currentTaskIndex === this._currentTaskSet.length ? 3000 : 1500);
    }
  }

  /* ------------------- Server Calls ---------------------- */

  sampleTask(a: { tasktype: string; taskid: string }, callbackFn) {
    this.serverService
      .getOneTaskById(a.tasktype, a.taskid)
      .subscribe((res: TaskResponse) => {
        this._appStorage.selectedTask = Session.getAsTypedClass(res);
        this.taskMode = TaskMode.Sample;
        this._backgroundOpacity = 1;
        callbackFn(this._appStorage.selectedTask.taskType);
      });
  }

  private loginSuccess(response: LoginResponse, pass: string, loginSuccessCallback: any) {
    if (response.student["demo"]) {
      this._appStorage.demoMode = response.student["demo"];
    }
    this._appStorage.student = new Student(response.student);
    this._appStorage.student.password = pass;

    // Set the activity
    if (typeof response.activity !== "undefined") {
      this._appStorage.activity = new Activity(response.activity);
    } else {
      this.viewState = ViewState.Delay;
      this.delayMode = DelayMode.NoActivitiesFound;
      this.updateState();
      return;
    }

    // If we achieved a login, we are online now so send and clear any un-synced tracking logs
    if (this.isCordovaApp && !this._appStorage.demoMode) {
      this.cordovaService.loadLogs(
        result => {
          // It's possible that un-synced Tasks and Session exist from previous failed attempts
          // If an incomplete Session log exists we can also use it to recover progress
          // Set up the old logs here..
          // Later we will filter out completed tasks for current session once tasks are loaded
          this._appStorage.sessionLogs = {};
          for (let logKey in result) {
            if (result.hasOwnProperty(logKey)) {
              let logEntry = result[logKey];
              this._appStorage.sessionLogs[logKey] = new SessionLog(
                logEntry["session_id"],
                logEntry["student_id"],
                logEntry["session_reference"],
                logEntry["tasks_total"],
                logEntry["log_id"],
                logEntry["synced"],
                logEntry["completed"],
                logEntry["start_time"],
                logEntry["duration"],
                logEntry["inactive_count"],
                logEntry["inactive_duration"],
                logEntry["tasks_completed"],
                logEntry["taskLogs"]
              );
            }
          }

          // Attempt to re-post any un-synced log data
          this.prepareAndPostUsageData();
        },
        error => {
          console.log(error.toString());
        }
      );
    }

    // Select Episode ready to play any intro scenes, check completions, then move to Ship (Home)
    let episode: Episode = this._appStorage.nextUncompletedEpisode;
    if (episode !== null) {
      this._appStorage.selectedEpisode = episode;
      this.markCompletedAndAddLocationsSessions();
      this._progress.shipBarData.completedPercent = this.calculateEpisodeProgress();

      // If we already completed an episode today, don't allow another login
      // Indicated by an existing completion + starting the first session of an Episode on the same day
      let now = moment(),
        previous = moment(this._appStorage.student.lastCompletion);
      if (
        this._appStorage.student.user_id &&
        this._appStorage.student.user_id.indexOf("TT") === -1 &&
        !this._appStorage.student.isFeide &&
        now.isSame(previous, "day") &&
        this.sessionPhase === SessionPhase.First
      ) {
        this.viewState = ViewState.Delay;
        this.delayMode = DelayMode.AccessDenied;
        this.updateState();
        return;
      }

      this.viewState = ViewState.Loggedin;
    } else {
      this.viewState = ViewState.Delay;
      this.delayMode =
        this._appStorage.activityPhase === ActivityPhase.Demo
          ? DelayMode.DemoComplete
          : DelayMode.NoActivitiesFound;
      this.updateState();
      return;
    }
    loginSuccessCallback();
  }

  continueLogin() {
    let episode: Episode = this._appStorage.nextUncompletedEpisode;
    this._appStorage.selectedEpisode = episode;
    this.markCompletedAndAddLocationsSessions();
    this._progress.shipBarData.completedPercent = this.calculateEpisodeProgress();

    this.viewState = ViewState.Ship;
    this.shipMode = ShipMode.SessionLocked;
    this.sceneType = SceneType.EpisodeInScene;
    this.sceneMode = SceneMode.Ready;
    this.updateState();
  }

  private loginError(error, loginFailedCallback: any) {
    if (error.error instanceof Error) {
      console.log("An error occurred:", error.error.message);
    } else {
      console.log(
        `Backend returned code ${error.status}, body was: ${error.error}`
      );
      this.errorMessage = <any>error;
    }
    loginFailedCallback();
  }

  acceptToken(code, loginSuccessCallback, loginFailedCallback) {
    this.serverService
        .acceptToken(code)
        .subscribe(
          (response: LoginResponse) => this.loginSuccess(
            response,
            '',
            loginSuccessCallback),
          (error: HttpErrorResponse) => this.loginError(
            error,
            loginFailedCallback)
        )
  }

  login(type, pin, pass, loginSuccessCallback, loginFailedCallback) {
    if (type == 'saml') {
      this.serverService.loginSAML(false)
    } else {
      this.serverService
        .login(type, pin, pass, this.cordovaService.tabletPasscode)
        .subscribe(
          (response: LoginResponse) => this.loginSuccess(
            response,
            pass,
            loginSuccessCallback),
          (error: HttpErrorResponse) => this.loginError(
            error,
            loginFailedCallback)
        )
    }
  }

  public startSession() {
    this.delayMode = DelayMode.None;
    this.taskMode = TaskMode.Warmups;
    this.configureSession();
    this.updateState();
  }

  // After a password is entered correctly, get the details for the session
  private getSessionWithTasks(session: Session, callback) {
    this.serverService.getSessionWithTasks(session._id).subscribe(
      (response: SessionResponse) => {
        if (response !== null) {
          let newSession = new Session(response.session, true);
          let sessionIndex = this._appStorage.selectedEpisode.sessions.findIndex(
            session => {
              return session._id === newSession._id;
            }
          );
          this._appStorage.selectedEpisode.sessions[sessionIndex] = newSession;
          this._appStorage.selectedSession = newSession;
          this._appStorage.selectedSession.activated = true;
          this.markCompletedAndAddLocationsSessions();
          this._progress.shipBarData.completedPercent = this.calculateEpisodeProgress();
          callback();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        } else {
          console.log(
            `Backend returned code ${err.status}, body was: ${err.error}`
          );
          this.errorMessage = <any>err;
          callback();
        }
      }
    );
  }

  // Check for Warmups and Test tasks, allocate them and determine appropriate states
  // If warmup tasks exist, function will be called a second time, controlled by TaskMode
  private configureSession() {
    let shuffledTasks;

    this._currentTaskIndex = 0;
    this._backgroundImage =
      "assets/images/map/locations/" +
      this._appStorage.selectedSession.location.filename;

    // First entry only - Possible warmups, log, set up introductions and move to Test tasks
    if (this.taskMode === TaskMode.Warmups) {
      // Play a Morfological Introduction + delay screens if called for
      // Else play a general intro scene and move directly to begin the Test tasks
      if (this._appStorage.selectedSession.morfologicalIntro > 0) {
        this.sceneType = SceneType.MorfologicalScene;
        this.delayMode = DelayMode.NoWarmups;
      } else {
        if (this._appStorage.selectedSession.consolidation) {
          this.sceneType = SceneType.ConsolidationInScene;
        } else {
          this.sceneType = SceneType.SessionInScene;
        }
      }
      this.sceneMode = SceneMode.Ready;

      // Check now for Warmup tasks
      shuffledTasks = this._appStorage.selectedSession.getShuffledTasks(
        "warmups"
      );
      if (shuffledTasks.length > 0) {
        this._currentTaskSet = shuffledTasks;

        // Exceptions for Pre-test and Post-test Episodes - no scenes or delay, go directly to warmup tasks
        if (
          this.activityPhase === ActivityPhase.RCT &&
          this.episodePhase !== EpisodePhase.Second
        ) {
          this.sceneMode = SceneMode.Finished;
          this.viewState = ViewState.Tasks;
        } else {
          this.viewState = ViewState.Delay;
          this.delayMode = DelayMode.WarmupsStarting;
        }

        // If there is no Morfological intro, but we are showing warmups, then turn off the intro scene
        if (
          this.sceneType === SceneType.SessionInScene ||
          this.sceneType === SceneType.ConsolidationInScene
        ) {
          this.sceneMode = SceneMode.Finished;
        }
      } else {
        this.taskMode = TaskMode.Tests;
      }
    }

    // Test tasks - may be used on first or second entry to this function
    if (this.taskMode === TaskMode.Tests) {
      shuffledTasks = this._appStorage.selectedSession.getShuffledTasks(
        "tests"
      );
      if (shuffledTasks.length > 0) {
        this._currentTaskSet = shuffledTasks;
        if (
          this.delayMode === DelayMode.WarmupsFinished ||
          this.delayMode === DelayMode.NoWarmups
        ) {
          this.viewState = ViewState.Delay;
        } else {
          this.viewState = ViewState.Tasks;
        }
      } else {
        // If no tasks exist, consider the Session finished
        this.viewState = ViewState.Delay;
        this.delayMode = DelayMode.NoSessionsFound;
      }

      // Filter out any tasks that have already been done (in the case that this is a resumed Session)
      // If there are logs available, either they have not been synced or the session was not completed
      // Only recovering from a restart during test tasks, not warm-ups
      if (
        this._appStorage.sessionLogs.hasOwnProperty(
          this._appStorage.selectedSession._id
        ) &&
        !this._appStorage.sessionLogs[this._appStorage.selectedSession._id]
          .completed &&
        this._appStorage.sessionLogs[this._appStorage.selectedSession._id]
          .student_id == this._appStorage.student.user_id
      ) {
        this._appStorage.selectedSessionLog = this._appStorage.sessionLogs[
          this._appStorage.selectedSession._id
        ];
        let filteredTaskSet = [];
        this._currentTaskSet.forEach(task => {
          if (
            !this._appStorage.selectedSessionLog.taskLogs.hasOwnProperty(
              task._id
            ) ||
            !this._appStorage.selectedSessionLog.taskLogs[task._id].completed
          ) {
            filteredTaskSet.push(task);
          }
        });
        this._currentTaskSet = filteredTaskSet;
      }
    }

    setTimeout(() => {
      let fullSesisonTaskLength =
        this.taskMode === TaskMode.Warmups
          ? this._appStorage.selectedSession.warmupTaskCount
          : this._appStorage.selectedSession.testTaskCount;
      this._progress.barData.completedPercent =
        (fullSesisonTaskLength - this._currentTaskSet.length) /
        fullSesisonTaskLength *
        100;
    }, 500);
  }

  modifyStudentData(data) {
    const newStudent = new Student({ ...this._appStorage.student, ...data })
    this._appStorage.student = newStudent
  }

  updateStudent(callbackFn) {
    this.serverService
      .updateStudent(
        this._appStorage.student,
        this._appStorage.activity._id,
        this.cordovaService.tabletPasscode
      )
      .subscribe(
        res => {
          if (callbackFn !== null) {
            callbackFn(res);
          }
        },
        (err: HttpErrorResponse) => {
          console.log(
            "Failed to update student session completions. Error: " +
              err.message
          );
        }
      );
  }
}
