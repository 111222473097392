import { Component, OnInit } from '@angular/core';
import {TaskType13, Type13Answer} from "../../models/task-type13.model";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-type13',
  templateUrl: './type13.component.html',
  styleUrls: ['./type13.component.css']
})
export class Type13Component implements OnInit {

  public task: TaskType13 = null;
  // Display setup
  unforgivingTestMode = true;
  answer: Type13Answer;
  opacity = 1;
  submitActive = false;
  soundPlaying = false;
  result = { correct: 0, of: 1, incorrectAttempts: 0, answer_details: { attempt: '', elapsed: 0 } };

  constructor(private dataService: DataService) {
    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    // GroverService.setControllerState(self);
    this.task = <TaskType13> this.dataService.currentTask;
    this.answer = null;
  }

  ngOnInit() {
    this.dataService.progressShow({ stars: 0 });

    if (typeof this.task.audio !== 'undefined' && this.task.audio.url !== null) {
      let instructionAudio = new Audio(this.task.audio.url);
      this.soundPlaying = true;
      instructionAudio.addEventListener('ended', () => {
        this.dataService.setSpeakerSound(this.task.audio.url, null);
        this.dataService.speakerIsPlaying = false;
        this.soundPlaying = false;
      });
      this.dataService.speakerIsPlaying = true;
      instructionAudio.play().catch();
    }
  }

  chooseAnswer(answer: Type13Answer) {
    // this.dataService.progress.starData.completed = 1;
    if (this.answer !== null && this.answer.checked) {
      this.answer.checked = false;
    }
    if (!this.soundPlaying) {
      this.soundPlaying = true;
      answer.playAudio(() => {
        this.soundPlaying = false;
      });
    }
    this.dataService.progress.results.answer_details
      .push({ attempt: answer.text, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
    this.answer = answer;
    this.answer.checked = true;
    this.submitActive = true;
  }

  finish() {
    if (this.submitActive) {
      this.result.answer_details.attempt = this.answer.text;
      setTimeout(() => {
        this.opacity = 0;
        this.completeTask();
      }, 500);
    }
  };

  completeTask() {
    setTimeout(() => {
      this.dataService.completeTask();
    }, 1000)
  };

}
