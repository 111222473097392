import { Component, OnInit, OnDestroy } from '@angular/core';
import {DataService} from '../../services/data.service';
import {DragulaService} from 'ng2-dragula-base/dist';
import {TaskType5} from '../../models/task-type5.model';
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";

@Component({
  selector: 'app-type5',
  templateUrl: './type5.component.html',
  styleUrls: ['./type5.component.css']
})
export class Type5Component implements OnInit, OnDestroy {

  public task: TaskType5 = null;
  // Display setup
  unforgivingTestMode = false;
  round = 1;
  opacity = 0;
  dragImageOpacity = 0;
  highlight1 = false;
  highlight2 = false;

  // Real baskets for items
  items = [];
  movedItems1 = [];
  movedItems2 = [];

  // Temp baskets for dragging only
  public draggableItem = [];
  public droppedItem1 = [];
  public droppedItem2 = [];

  private draggingNow = false;
  animateDone = false;
  enableZone1 = true;
  enableZone2 = true;
  boxAudio1 = null;
  boxAudio2 = null;

  blueLights = false;
  yellowLights = false;

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
  result = { correct: 0, of: 6, incorrectAttempts: 0, answer_details: [] };
  box1 = "";
  box2 = "";

  private dragulaSubscription;
  private dragulaCancelSubscription;
  private dragulaDragSubscription;

  constructor(private dragulaService: DragulaService, private dataService: DataService) {
    this.setupDragula();
  }

  ngOnInit() {
    // Begin the task..

    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    // GroverService.setControllerState(self);

    this.task = <TaskType5> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnDestroy() {
    this.dragulaSubscription.unsubscribe();
    this.dragulaCancelSubscription.unsubscribe();
    this.dragulaDragSubscription.unsubscribe();
    this.dragulaService.destroy('drag-bag5');
  }

  // ---------  Dragula functions -------------------

  private setupDragula() {
    this.dragulaService.setOptions('drag-bag5', {
      revertOnSpill: true,
      direction: 'horizontal',
      accepts: (el, target, source, sibling) => {
        return el.id !== 'word-source-box';
      },
      moves: (el, source, handle, sibling) => {
        return source.id === 'word-source-box'; // don't prevent any drags from initiating by default
      }
    });

    this.dragulaDragSubscription = this.dragulaService.drag.subscribe((value) => {
      this.draggingNow = true;
    });

    this.dragulaCancelSubscription = this.dragulaService.cancel.subscribe((value) => {
      this.draggingNow = false;
    });

    this.dragulaSubscription = this.dragulaService.dropModel.subscribe((value) => {
      this.onDrop(value.slice(1));
    });
  }

  private onDrop(args) {
    const [el, target, source, sibling] = args;
    const wordIndex = this.getWordIndex(el);
    const boxIndex = this.getBoxIndex(target);
    this.dragImageOpacity = 0;

    let correctItem = (item, boxId) => {
      this.result.correct++;
      this.dataService.progress.results.answer_details
        .push({ attempt: item.word, correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
      this.dataService.progress.completeAStar();
      item.enabled = false;
      item.visible = false;
      this['movedItems' + boxId].push(item);

      setTimeout(() =>  {
        if(this.items.length > 0) {
          let newItem = this.items.pop();
          newItem.visible = true;
          this.draggableItem.push(newItem);
          setTimeout(() =>  {
            this.blueLights = false;
            this.yellowLights = false;
            this.dragImageOpacity = 1;
          }, 1000)
        } else {
          if (this.dataService.taskMode === TaskMode.Warmups) {
            setTimeout(() => {
              const a = new Audio('assets/sounds/task_instructions/warmups/type5.mp3');
              a.addEventListener('ended', () => {
                this.completeTask();
              });
              a.play();
            }, 1000);
          } else {
            this.completeTask();
          }
        }
        this.draggingNow = false;
      }, 500)
    };

    let incorrectItem = (item, boxId) => {
      this.dataService.progress.results.incorrectAttempts++;
      this.dataService.progress.results.answer_details
        .push({ attempt: item.text, correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
      this.items.push(item);
      this.items = Utilities.shuffleArray(this.items);
      this.dragImageOpacity = 0;
      setTimeout(() => {
        let newItem = this.items.pop();
        this.draggableItem.push(newItem);
        this.dragImageOpacity = 1;
        this.draggingNow = false;
      }, 1000);
    };


    if (this.droppedItem1.length > 0) {
      // Item landed in box 1
      const item = this.droppedItem1.pop();
      // The item moved matches box1 ('stem' box)
      if (item.word === 'stem' && item.enabled) {
        this.yellowLights = true;
        correctItem(item, 1);
      } else {
        incorrectItem(item, 1);
      }
    } else if (this.droppedItem2.length > 0) {
      // Item landed in box 1
      const item = this.droppedItem2.pop();
      // The item moved matches box2 ('morphedStem' box)
      if (item.word === 'morphedStem' && item.enabled) {
        this.blueLights = true;
        correctItem(item, 2);
      } else {
        incorrectItem(item, 2);
      }
    }

  }

  private getWordIndex(el: any) {
    return parseInt(el.id.substr(11), 10);
  }

  private getBoxIndex(target: any) {
    return parseInt(target.id.substr(9), 10);
  }

  // --------------------------------------------------



  clickImage(boxAudioIndex) {
    if(this['boxAudio' + boxAudioIndex] !== null) {
      this['boxAudio' + boxAudioIndex].play();
      this.dataService.progress.results.use_audio_content_items++;
    }
  };

  setupTask() {
    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 5 task does not exist - check your Session layout in the CMS');
      return;
    }

    this.box1 = 'assets/images/tasks/type5/Type5_YellowBox@2x.png';
    this.box2 = 'assets/images/tasks/type5/Type5_BlueBox@2x.png';
    // this.box1 = typeof this.task.box1.image !== 'undefined' && this.task.box1.image.url !== null ? this.task.box1.image.url : 'assets/images/tasks/type5/Type5_YellowBox@2x.png';
    // this.box2 = typeof this.task.box2.image !== 'undefined' && this.task.box2.image.url !== null ? this.task.box2.image.url : 'assets/images/tasks/type5/Type5_BlueBox@2x.png';
    this.boxAudio1 = typeof this.task.box1.audio !== 'undefined' && this.task.box1.audio.url !== null ? new Audio(this.task.box1.audio.url) : null;
    this.boxAudio2 = typeof this.task.box2.audio !== 'undefined' && this.task.box2.audio.url !== null ? new Audio(this.task.box2.audio.url) : null;

    let items = [];
    for(let i=1; i < 7; i++) {
      if (typeof this.task['image'+i].image !== 'undefined' && this.task['image'+i].image.url !== null) {
        let item = {
          keyItem: i,
          image: this.task['image' + i].image.url || '',
          audio: typeof this.task['image' + i].audio !== 'undefined' ? this.task['image' + i].audio.url : '',
          word: this.task['image' + i].word || '',
          enabled: true,
          visible: false,
          draggable: true
        };
        items.push(item);
      }
    }
    this.items = Utilities.shuffleArray(items);
    this.dataService.progressShow({ stars: items.length });

    let newItem = this.items.pop();
    newItem.visible = true;
    this.draggableItem.push(newItem);
    this.introduceChallenge();
  };

  introduceChallenge() {
    this.dragImageOpacity = 1;
    this.opacity = 1;
    if(typeof this.task.instruction1Audio === 'undefined' || typeof this.task.instruction2Audio === 'undefined'){
      setTimeout(() =>  {
        setTimeout(() =>  {
          this.highlight1 = false;
          this.highlight2 = true;
          setTimeout(() =>  {
            this.highlight2 = false;
          }, 1000);
        }, 1000);
        this.highlight1 = true;
      }, 1000);
    }
    setTimeout(() =>  {
      if (typeof this.task.instruction1Audio !== 'undefined' && this.task.instruction1Audio.url !== null) {
        let instructionAudio = new Audio(this.task.instruction1Audio.url);
        instructionAudio.addEventListener('ended', () =>  {
          if (typeof this.task.instruction2Audio !== 'undefined' && this.task.instruction2Audio.url !== null) {
            let instructionAudio2 = new Audio(this.task.instruction2Audio.url);
            instructionAudio2.addEventListener('ended', () =>  {
              this.dataService.setSpeakerSound(this.task.instruction1Audio.url, this.task.instruction2Audio.url);
              this.dataService.speakerIsPlaying = false;
              this.highlight2 = false;
              // $scope.$digest();
            });
            this.highlight1 = false;
            setTimeout(() =>  {
              this.highlight2 = true;
              instructionAudio2.play();
            }, 500);
          } else {
            this.dataService.setSpeakerSound(this.task.instruction1Audio.url, null);
            this.dataService.speakerIsPlaying = false;
          }
        });
        this.highlight1 = true;
        this.dataService.speakerIsPlaying = true;
        instructionAudio.play();
      }
    }, 1000);
  };


  completeTask() {
    setTimeout(() =>  {
      this.opacity = 0;
      this.dataService.completeTask();
    }, 1000)
  };

}
