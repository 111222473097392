import {Task} from './task.model';
import {Constants} from '../constants';

export class TaskType12 extends Task {
  morph: string = "";
  affix: string = "";
  category: string = "";
  morf_category: string;

  word: string;
  // word_category: string;
  introduction_audio: { url: string };

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  deserialize(input: Object) {
    this.morph = input['morph'];
    this.affix = input['affix'];
    this.category = input['category'];
    this.morf_category = input['morf_category'];

    this.word = input['word'].toLowerCase();
    // this.word_category = input['word_category'];
    this.introduction_audio = { url: null };
    if (input.hasOwnProperty('introduction_audio') && input['introduction_audio'].url !== null) {
      this.introduction_audio.url = input['introduction_audio'].url;
    }

    return this;
  }

  rewriteUrlsToLocalAssets(input: Object) {
    if (this.introduction_audio && this.introduction_audio.url) {
      this.introduction_audio.url =
        Constants.localAssetUrl + input['introduction_audio']['path'] + '/' + input['introduction_audio']['filename'];
    }
  }
}
