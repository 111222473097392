import {Task} from './task.model';
import {Constants} from '../constants';


export class TaskType11 extends Task {
  morph: string = "";
  stem: string;
  morphedStem: string;
  affix: string = "";
  category: string = "";
  morf_category: string;

  word: string;
  word_audio: { url: string; };
  instruction_audio: { url: string; };
  unforgiving: boolean;

  image1: {
    file: { url: string; filename: string },
    correct: boolean;
  };
  image2: {
    file: { url: string; filename: string },
    correct: boolean;
  };
  image3: {
    file: { url: string; filename: string },
    correct: boolean;
  };
  image4: {
    file: { url: string; filename: string },
    correct: boolean;
  };
  image5: {
    file: { url: string; filename: string },
    correct: boolean;
  };
  image6: {
    file: { url: string; filename: string },
    correct: boolean;
  };

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.affix = input['affix'];
    this.morf_category = input['morf_category'];
    this.unforgiving = input['unforgiving'];

    this.word = input['word'];
    this.word_audio = input['word_audio'];
    this.instruction_audio = input['instruction_audio'];
    this.image1 = input['image1'];
    this.image2 = input['image2'];
    this.image3 = input['image3'];
    this.image4 = input['image4'];
    this.image5 = input['image5'];
    this.image6 = input['image6'];
    return this;
  }

  rewriteUrlsToLocalAssets(input: Object) {

    if (this.instruction_audio) {
      this.instruction_audio.url =
        Constants.localAssetUrl + input['instruction_audio']['path'] + '/' + input['instruction_audio']['filename'];
    }
    if (this.word_audio) {
      this.word_audio.url =
        Constants.localAssetUrl + input['word_audio']['path'] + '/' + input['word_audio']['filename'];
    }

    if (this.image1 && this.image1.file) {
      this.image1.file.url =
        Constants.localAssetUrl + input['image1']['file']['path'] + '/' + input['image1']['file']['filename'];
    }
    if (this.image2 && this.image2.file) {
      this.image2.file.url =
        Constants.localAssetUrl + input['image2']['file']['path'] + '/' + input['image2']['file']['filename'];
    }
    if (this.image3 && this.image3.file) {
      this.image3.file.url =
        Constants.localAssetUrl + input['image3']['file']['path'] + '/' + input['image3']['file']['filename'];
    }
    if (this.image4 && this.image4.file) {
      this.image4.file.url =
        Constants.localAssetUrl + input['image4']['file']['path'] + '/' + input['image4']['file']['filename'];
    }
    if (this.image5 && this.image5.file) {
      this.image5.file.url =
        Constants.localAssetUrl + input['image5']['file']['path'] + '/' + input['image5']['file']['filename'];
    }
    if (this.image6 && this.image6.file) {
      this.image6.file.url =
        Constants.localAssetUrl + input['image6']['file']['path'] + '/' + input['image6']['file']['filename'];
    }
  }
}
