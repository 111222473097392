import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "../../services/data.service";
import {LoginMode, ShipMode, ViewState, ActivityPhase} from '../../models/general.model';

declare let Media: any;
declare var window: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  codeInput;
  pin = '';
  loginEnabled = false;
  autofocusEnabled = false;
  isOnline = false;
  isDemoMode = false;
  checkboxImage = 'img/uncheckedbox.png';
  beepAudio;
  autoSelectTimeout = null;
  showDashboardButton = false;
  loggedIn = false;
  pinLoginMode = false;
  surveyData: {
    ageGroup: number,
    norsk: string,
    annetSprak: string,
  }

  // Introduced to satisfy app store approval requirements
  userId;
  password;

  constructor(private dataService: DataService, private router: Router, private activatedRoute: ActivatedRoute, private zone: NgZone) {
    dataService.registerSound('assets/sounds/general/beep.mp3', 'beep', null, null);

    const continueLogin = (code) => {
      dataService.acceptToken(code, () => {
        this.zone.run(() => {
          this.loggedIn = true;
          this.showDashboardButton = this.dataService.userIsOrganiser;
          this.setupSurveyData()
        });
      }, () => {
        this.userId = '';
        this.password = '';
        this.focusOnInput('userId');
      })
    }

    let params: any = this.activatedRoute.snapshot.queryParams;
    if (params.mode == 'login') {
      continueLogin(params.code)
    }

    window.addEventListener('message', (event) => {
      if (typeof event.data === 'string' && event.data.match(/^oauth::/)) {
        const data = JSON.parse(event.data.substring(7))
        if (data.mode == 'login' && data.code && data.code !== 'undefined') {
          continueLogin(data.code)
        }
      }
    })
  }

  setupSurveyData() {
    if (this.dataService.student && this.dataService.student.surveyData) {
      if (this.dataService.student.surveyData.ageGroup) {
        this.surveyData.ageGroup = this.dataService.student.surveyData.ageGroup
      }
      if (this.dataService.student.surveyData.norsk) {
        this.surveyData.norsk = this.dataService.student.surveyData.norsk
      }
      if (this.dataService.student.surveyData.annetSprak) {
        this.surveyData.annetSprak = this.dataService.student.surveyData.annetSprak
      }
    }
  }

  ngOnInit() {
    this.isOnline = this.dataService.isOnline;
    this.isDemoMode = this.dataService.demoMode;
    this.loggedIn = false;

    this.codeInput = {
      pin1: '',
      pin2: '',
      pin3: '',
      pin4: '',
      pin5: '',
      pin6: '',
    };

    this.userId = '';
    this.password = '';

    this.surveyData = {
      ageGroup: null,
      norsk: '',
      annetSprak: '',
    }

    this.autoSelectTimeout = setTimeout(() => {
       if (this.dataService.loginMode === LoginMode.AppStarted) {
        this.focusOnInput('userId');
       }
    }, 4000);
  }

  public get studentSurveyData() {
    return this.dataService.student && this.dataService.student.surveyData ? this.dataService.student.surveyData : { ageGroup: 10, norsk: 'norsk', annetSprak: '' };
  }

  public get isBroadRelease() {
    return this.dataService.activityPhase === ActivityPhase.BroadRelease;
  }

  togglePinMode() {
    this.pinLoginMode = !this.pinLoginMode
  }

  ngOnDestroy() {
    this.dataService.unregisterSound('beep');
  }

  dashboard() {
    this.router.navigateByUrl("/admin");
  }

  public get isMobileApp() {
    return this.dataService.isCordovaApp;
  }

  pinBoxSelected(boxNumber: number) {
    clearTimeout(this.autoSelectTimeout);
    switch(boxNumber) {
      case 1:
        this.codeInput.pin1 = ''; this.codeInput.pin2 = ''; this.codeInput.pin3 = ''; this.codeInput.pin4 = ''; this.codeInput.pin5 = ''; this.codeInput.pin6 = '';
        break;
      case 2:
        this.codeInput.pin2 = ''; this.codeInput.pin3 = ''; this.codeInput.pin4 = ''; this.codeInput.pin5 = ''; this.codeInput.pin6 = '';
        break;
      case 3:
        this.codeInput.pin3 = ''; this.codeInput.pin4 = ''; this.codeInput.pin5 = ''; this.codeInput.pin6 = '';
        break;
      case 4:
        this.codeInput.pin4 = ''; this.codeInput.pin5 = ''; this.codeInput.pin6 = '';
        break;
      case 5:
        this.codeInput.pin5 = ''; this.codeInput.pin6 = '';
        break;
      case 6:
        this.codeInput.pin6 = '';
        break;
    }
  }

  digitEntered(prevEl, pinEl, nextEl, digitType, digitKey) {
    if (digitKey === 'Backspace') {
      if (prevEl !== null) {
        pinEl.value = '';
        prevEl.focus();
        prevEl.value = '';
      }
    } else {
      this.dataService.playSound('beep', false);
      if (pinEl.value && this.validateEntry(pinEl, digitType)) {
        if (nextEl !== null) {
          nextEl.focus();
        } else {
          this.login('pin');
        }
      }
    }
  }

  startAfterLogin() {
    if (this.loggedIn) {
      if (this.pinLoginMode) {
        this.dataService.continueLogin();
      } else {
        this.dataService.modifyStudentData({ surveyData: this.surveyData })
        this.dataService.updateStudent(() => {
          this.dataService.continueLogin();
        })
      }
    }
  }

  testAfterLogin() {
    if (this.loggedIn) {
      this.dataService.broadReleasePretestMode = true;
      if (this.pinLoginMode) {
        this.dataService.continueLogin();
      } else {
        this.dataService.modifyStudentData({ surveyData: this.surveyData })
        this.dataService.updateStudent(() => {
          this.dataService.continueLogin();
        })
      }
    }
  }

  login(type: String) {
      this.userId = this.userId.toUpperCase();
      this.dataService.login(type, this.userId, this.password, () => {
        this.zone.run(() => {
          this.loggedIn = true;
          this.setupSurveyData()
        })
      }, () => {
        this.userId = '';
        this.password = '';
        this.focusOnInput('userId');
      })
    // }
  };

  validateEntry(pinEl, digitType): boolean {
    if(digitType === "number") {
      pinEl.value = pinEl.value.replace(/^[^0-9]?$/g, '');
    } else if (digitType === "letter") {
      pinEl.value = pinEl.value.replace(/^[^a-zA-Z]?$/g, '');
    }
    return pinEl.value !== '';
  };

  focusOnInput(id) {
    let element = document.getElementById(id);
    if (element) {
      element.focus();
    }
  };


}
