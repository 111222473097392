import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { DataService } from "../../services/data.service";
import { ServerService } from "../../services/server.service";
import {
  ActivityPhase,
  DelayMode,
  EpisodePhase,
  SceneMode,
  SceneType,
  Session,
  SessionPhase,
  ShipMode,
  ViewState
} from "../../models/general.model";
import { SpeechSounds } from "../../constants";

@Component({
  selector: "app-ship",
  templateUrl: "./ship.component.html",
  styleUrls: ["./ship.component.css"]
})
export class ShipComponent implements OnInit, OnDestroy {
  _selectedSession: Session = null;
  _nextSession: Session = null;
  progress = 1;
  joystickAnimating = false;
  joystickStarVisible = true;
  passwordOverlayActive = false;
  linkOverlayActive = false;
  passwordInputElement = null;
  linkInputElement = null;
  linkText = "";
  delayActive = false;
  passwordText = "";
  computerSpeaking = false;
  idleTimer = null;

  constructor(private dataService: DataService, private serverService: ServerService, private zone: NgZone) {}

  ngOnInit() {
    this.joystickStarVisible = true;
    this.computerSpeaking = true;

    // This will be reached during page reload - exit gracefully to login page
    if (this.dataService.viewState === ViewState.None) {
      this.dataService.sceneMode = SceneMode.Finished;
      this.dataService.updateState(true);
    } else {
      this._selectedSession = this.dataService.currentSession;
      this._nextSession = this.dataService.nextUncompletedSession;

      // We are starting a session..
      if (this.dataService.shipMode === ShipMode.SessionLocked) {
        // If this is the first session of the RCT, activate it automatically & introduce it
        if (
          this._selectedSession === null &&
          this.dataService.activityPhase === ActivityPhase.RCT &&
          this.dataService.episodePhase === EpisodePhase.First
        ) {
          this.dataService.speech.speak(
            SpeechSounds.computer.C10,
            () => {
              this.dataService.speech.speak(
                SpeechSounds.narrator.N11,
                () => {
                  this.computerSpeaking = false;
                  this.passwordEntered();
                },
                1000,
                false
              );
            },
            1000,
            true
          );
        } else {
          // Try to unlock in case there is an empty password
          const helloSound = this._nextSession.hasPassword ? SpeechSounds.computer.C55 : SpeechSounds.computer.C41;
          this.dataService.speech.speak(
            helloSound,
            () => {
              this.computerSpeaking = false;
              if (!this._nextSession.hasPassword) this.passwordEntered();
            },
            1000,
            true
          );
        }
      }

      // If we are in the process of finishing a Session (progress update), speak 'progress update' feedback then show scenes
      // NOTE: The current session was just marked as complete. So the sessionPhase should be explicitly queried with the current Session
      if (this.dataService.shipMode === ShipMode.SessionEnding) {
        // We arrived here in pre/post test mode
        if (
          (this.dataService.activityPhase === ActivityPhase.RCT &&
            this.dataService.episodePhase !== EpisodePhase.Second) ||
          this.dataService.activityPhase === ActivityPhase.Demo ||
          this.dataService.broadReleasePretestMode
        ) {
          this.dataService.shipMode = ShipMode.SessionCompleted;
        } else {
          // Else we need to update progress to the user, then possibly play an 'out' scene
          if (this.idleTimer !== null) {
            clearTimeout(this.idleTimer);
          }
          let voiceKey = "C46"; // SessionPhase.One
          if (this.dataService.currentSession.consolidation) {
            if (
              this.dataService.getSessionPhaseForSession(
                this._selectedSession
              ) === SessionPhase.Last
            ) {
              voiceKey = "C54";
            } else {
              voiceKey = "C53";
            }
          } else {
            switch (this.dataService.getSessionPhaseForSession(
              this._selectedSession
            )) {
              case SessionPhase.Two:
                voiceKey = "C47";
                break;
              case SessionPhase.Three:
                voiceKey = "C59";
                break;
              case SessionPhase.Four:
                voiceKey = "C63";
                break;
              case SessionPhase.Five:
                voiceKey = "C57";
                break;
              case SessionPhase.Six:
                voiceKey = "C58";
                break;
              case SessionPhase.Seven:
                voiceKey = "C65";
                break;
              case SessionPhase.Eight:
                voiceKey = "C48";
                break;
              case SessionPhase.Penultimate:
                voiceKey = "C49";
                break;
            }
          }

          let continueAfterComputerVoice = () => {
            this.dataService.shipMode = ShipMode.SessionCompleted;
            this.computerSpeaking = false;
            let sessionPhase = this.dataService.getSessionPhaseForSession(
              this._selectedSession
            );
            if (
              sessionPhase === SessionPhase.Last ||
              sessionPhase === SessionPhase.FirstAndLast
            ) {
              this.dataService.sceneMode = SceneMode.Ready;
              this.dataService.sceneType = SceneType.EpisodeOutScene;
              if (this.dataService.demoMode) {
                this.dataService.sceneMode = SceneMode.Finished;
                this.dataService.delayMode = DelayMode.DemoComplete;
                this.dataService.viewState = ViewState.Delay;
              }
              this.dataService.updateState();
            } else {
              this.dataService.sceneMode = SceneMode.Finished;
              this.resetIdleTimeout();
            }
          };

          this.dataService.speech.speak(
            SpeechSounds.computer[voiceKey],
            () => {
              if (
                this.dataService.getSessionPhaseForSession(
                  this._selectedSession
                ) !== SessionPhase.Last
              ) {
                this.dataService.speech.speak(
                  SpeechSounds.computer.C60,
                  () => {
                    continueAfterComputerVoice();
                  },
                  1000,
                  true
                );
              } else {
                continueAfterComputerVoice();
              }
            },
            1000,
            true
          );
        }
      }

      // If the session has completed, we are back to the Ship view in interactive mode after progress update and scenes
      // This also collects fallthrough from ShipMode.SessionEnding
      if (this.dataService.shipMode === ShipMode.SessionCompleted) {
        // Start the logout timer
        this.resetIdleTimeout();

        // We arrived here in pre/post test mode
        if (
          (this.dataService.activityPhase === ActivityPhase.RCT &&
          this.dataService.episodePhase === EpisodePhase.First) ||
          this.dataService.broadReleasePretestMode
        ) {
          this.dataService.speech.speak(
            SpeechSounds.computer.C32,
            () => {
              this.computerSpeaking = false;
            },
            1000,
            true
          );
        } else if (
          this.dataService.activityPhase === ActivityPhase.RCT &&
          (this.dataService.episodePhase === EpisodePhase.Third ||
            this.dataService.episodePhase === EpisodePhase.Fourth)
        ) {
          this.dataService.speech.speak(
            SpeechSounds.computer.C69,
            () => {
              this.computerSpeaking = false;
            },
            1000,
            true
          );
        } else if (
          this.dataService.activityPhase === ActivityPhase.RCT &&
          this.dataService.episodePhase === EpisodePhase.Fifth
        ) {
          this.dataService.speech.speak(
            SpeechSounds.computer.C70,
            () => {
              this.computerSpeaking = false;
            },
            1000,
            true
          );
        } else {
          this.dataService.speech.speak(
            SpeechSounds.computer.C60,
            () => {
              this.computerSpeaking = false;
            },
            1000,
            true
          );
        }
      }

      // After we returned from the Interactive Map, we don't play any computer sounds
      if (this.dataService.shipMode === ShipMode.ReturnedFromInteractiveMap) {
        // Start the logout timer
        this.resetIdleTimeout();
        this.computerSpeaking = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.idleTimer !== null) {
      clearTimeout(this.idleTimer);
    }
  }

  get nextSession() {
    return this._nextSession;
  }

  resetIdleTimeout() {
    if (this.idleTimer !== null) {
      clearTimeout(this.idleTimer);
    }
    this.idleTimer = setTimeout(() => {
      this.logout();
    }, 300000);
  }

  startSelectedSession() {
    this.joystickAnimating = false; // :(
    this.joystickStarVisible = false;

    if (
      (this.dataService.activityPhase === ActivityPhase.RCT &&
      this.dataService.episodePhase !== EpisodePhase.Second) ||
      this.dataService.broadReleasePretestMode
    ) {
      this.joystickAnimating = true;
      new Audio("assets/sounds/scenes/pull.mp3").play().catch();
      setTimeout(() => {
        this.joystickAnimating = false;
        this.dataService.startSession();
      }, 1000);
    } else {
      this.dataService.progress.state.sceneType = SceneType.GeneralScene;
      this.dataService.progress.state.sceneMode = SceneMode.InProgress;
      this.dataService.viewState = ViewState.Map;
      // Update state only to play exit the ship (scene)
      this.dataService.updateState(true);
    }
  }

  barHeight() {
    this.progress = this.dataService.progress.shipBarData.completedPercent;
    return this.progress + "%";
  }

  barColour() {
    // let green = Math.floor(this.progress / 100 * 255);
    // let red: number = 255 - green;
    // return 'rgb(' + red + ',' + green + ',0)';

    let r =
      this.progress < 50
        ? 255
        : Math.floor(255 - (this.progress * 2 - 100) * 255 / 100);
    let g =
      this.progress > 50 ? 255 : Math.floor(this.progress * 2 * 255 / 100);
    return "rgb(" + r + "," + g + ",0)";
  }

  computerAnimating() {
    return this.dataService.speech.computerAnimating;
  }

  joystickActive() {
    return (
      this._selectedSession !== null &&
      this._selectedSession.activated &&
      !this._selectedSession.completed &&
      this.joystickStarVisible
    );
  }

  passwordButtonActive() {
    return (
      this.dataService.shipMode !== ShipMode.SessionCompleted &&
      !this.computerSpeaking && this._nextSession && this._nextSession.hasPassword
    );
  }

  linkButtonActive() {
    return !this.computerSpeaking && !this.dataService.student.myGroup && this.dataService.activityPhase === ActivityPhase.BroadRelease
  }

  // Map button is not available in RCT Episode 1, or when the session has not yet been completed
  mapButtonActive() {
    return (
      !(
        this.dataService.activityPhase === ActivityPhase.RCT &&
        this.dataService.episodePhase !== EpisodePhase.Second
      ) &&
      this.dataService.shipMode === ShipMode.SessionCompleted &&
      !this.computerSpeaking
    );
  }

  logoutButtonActive() {
    return !this.computerSpeaking;
  }

  falseStart() {
    if (!this.joystickActive() && !this.computerSpeaking) {
      this.dataService.speech.speak(
        SpeechSounds.computer.C61,
        () => {},
        0,
        true
      );
    }
  }

  // ---------------   Button controls

  enterPassword() {
    if (this.passwordButtonActive()) {
      this.linkOverlayActive = false;
      this.passwordOverlayActive = !this.passwordOverlayActive;
      if (this.passwordOverlayActive) {
        setTimeout(() => {
          this.passwordInputElement = document.getElementById("passwordBox");
          if (this.passwordInputElement) {
            this.passwordInputElement.focus();
          }
        }, 500);
      }
    }
  }

  enterLink() {
    if (this.linkButtonActive()) {
      this.passwordOverlayActive = false;
      this.linkOverlayActive = !this.linkOverlayActive;
      if (this.linkOverlayActive) {
        setTimeout(() => {
          this.linkInputElement = document.getElementById("linkBox");
          if (this.linkInputElement) {
            this.linkInputElement.focus();
          }
        }, 500);
      }
    }
  }

  logout() {
    this.passwordOverlayActive = false;
    if (
      !(
        this.dataService.activityPhase === ActivityPhase.RCT &&
        this.dataService.episodePhase === EpisodePhase.Fifth
      )
    ) {
      this.dataService.speech.speak(
        SpeechSounds.computer.C62,
        () => {
          this.dataService.exitToLogin();
        },
        0,
        true
      );
    } else {
      this.dataService.exitToLogin();
    }
  }

  visitMap() {
    if (this.mapButtonActive()) {
      this.dataService.sceneMode = SceneMode.Finished;
      this.dataService.viewState = ViewState.Map;
      this.dataService.updateState();
    }
  }

  linkEntered() {
    this.serverService.connectStudent(this.linkText, this.dataService.student._id).subscribe(response => {
      if (response.message != 'Done') {
        const t = this.linkText;
        this.linkText = response.message
        setTimeout(() => {
          this.linkText = t
        }, 2000)
      } else {
        this.dataService.student.myGroup = response.groupid;
        this.linkOverlayActive = false;
      }
    })
  }

  passwordEntered() {
    if (this.passwordInputElement) {
      this.passwordInputElement.blur();
    }
    this.delayActive = true;
    if (
      this.dataService.activateSession(
        this._nextSession,
        this.passwordText,
        () => {
          this.zone.run(() => {
            this.delayActive = false;
            this._selectedSession = this.dataService.currentSession;
            this._nextSession = this.dataService.nextUncompletedSession;
          })
        }
      )
    ) {
      this.passwordOverlayActive = false;
    }
    // Skip Session function removed at ISP's request
    /* else if (
      this.dataService.shipMode === ShipMode.SessionLocked &&
      this.dataService.skipSession(this._nextSession, this.passwordText)
    ) {
      this.dataService.updateStudent(() => {
        this._nextSession = this.dataService.nextUncompletedSession;
        this.passwordOverlayActive = false;
        this.delayActive = false;
      });
    } */
    else {
      this.delayActive = false;
    }
    this.passwordText = "";
  }
}
