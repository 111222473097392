import { Injectable } from "@angular/core";
import { JSONParseWithDateModule } from "../jsonparse-with-date/json-parse-with-date.module";
import { BrowserService } from "./browser.service";
import { DirectoryEntry, FileEntry } from "../../typings/index";

declare let cordova: any;

@Injectable()
export class CordovaService {
  private logFilename = "logs.txt";
  private configFilename = "morfConfig.json";
  private inProgress: boolean;
  private maxAttempts = 3;
  private attempts: number;
  private tabletConfig: any;

  constructor(
    private Json: JSONParseWithDateModule,
    private browser: BrowserService
  ) {
    this.inProgress = false;
    this.attempts = 0;
    this.tabletConfig = {};
  }

  get tabletPasscode() {
    if (this.tabletConfig.hasOwnProperty("passcode")) {
      return this.tabletConfig["passcode"];
    } else {
      return "none";
    }
  }

  writeFile(fileEntry, dataObj) {
    // Create a FileWriter object for our FileEntry (logs.txt)
    fileEntry.createWriter(fileWriter => {
      fileWriter.onwriteend = () => {
        console.log("Successful log file write");
        this.inProgress = false;
      };

      fileWriter.onerror = e => {
        console.log("Failed log file write: " + e.toString());
        this.inProgress = false;
      };

      fileWriter.write(JSON.stringify(dataObj));
    }, null);
  }

  readFile(fileEntry, sFunc, eFunc) {
    fileEntry.file(
      file => {
        let reader = new FileReader();

        reader.onloadend = evt => {
          console.log("Successful log file read");
          this.inProgress = false;
          if (evt.target["result"] !== "") {
            sFunc(this.Json.parseWithDate(evt.target["result"]));
          } else {
          }
        };

        reader.readAsText(file);
      },
      () => {
        eFunc("Error reading file");
      }
    );
  }

  getFileEntry(sFunc, eFunc) {
    window["resolveLocalFileSystemURL"](
      cordova.file.dataDirectory,
      (dir: DirectoryEntry) => {
        dir.getFile(
          this.logFilename,
          { create: true, exclusive: false },
          (fileEntry: FileEntry) => {
            if (fileEntry.isFile) {
              sFunc(fileEntry);
            }
          },
          () => {
            this.inProgress = false;
            eFunc("Error creating file");
          }
        );
      },
      () => {
        this.inProgress = false;
        eFunc("Error loading file");
      }
    );
  }

  saveLogs(sessionLogs) {
    if (!this.inProgress) {
      this.attempts = 0;
      this.inProgress = true;
      this.getFileEntry(
        fileEntry => {
          this.writeFile(fileEntry, sessionLogs);
        },
        errorMessage => {
          console.log(errorMessage);
        }
      );
    } else {
      // Attempt again
      if (this.attempts < this.maxAttempts) {
        this.attempts++;
        setTimeout(() => {
          this.saveLogs(sessionLogs);
        }, 500);
      } else {
        this.attempts = 0;
      }
    }
  }

  loadLogs(sFunc, eFunc) {
    if (!this.inProgress) {
      this.attempts = 0;
      this.inProgress = true;
      this.getFileEntry(fileEntry => {
        this.readFile(fileEntry, sFunc, eFunc);
      }, eFunc);
    } else {
      // Attempt again..
      if (this.attempts < this.maxAttempts) {
        this.attempts++;
        setTimeout(() => {
          this.loadLogs(sFunc, eFunc);
        }, 500);
      } else {
        this.attempts = 0;
        eFunc();
      }
    }
  }

  loadConfig() {
    window["resolveLocalFileSystemURL"](
      cordova.file.applicationDirectory,
      (dir: DirectoryEntry) => {
        dir.getFile(
          this.configFilename,
          {},
          (fileEntry: FileEntry) => {
            if (fileEntry.isFile) {
              fileEntry.file(
                file => {
                  let reader = new FileReader();

                  reader.onloadend = evt => {
                    console.log("Successful config file read");
                    this.tabletConfig = this.Json.parse(evt.target["result"]);
                  };
                  reader.readAsText(file);
                },
                () => {
                  console.log("Error reading file");
                }
              );
            }
          },
          () => {
            console.log("Error creating file");
          }
        );
      },
      () => {
        console.log("Error loading file");
      }
    );
  }
}
