import {Component, OnInit} from '@angular/core';
import {TaskType9, Type9Correct, Type9Word} from "../../models/task-type9.model";
import {DataService} from "../../services/data.service";
import {MessageService} from "../../services/message.service";
import {Utilities} from "../../utilities/utilities";
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import {TaskMode} from "../../models/general.model";

@Component({
  selector: 'app-type9',
  templateUrl: './type9.component.html',
  styleUrls: ['./type9.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        transition(
          ':enter', [
            style({ opacity: 0}),
            animate('500ms', style({'opacity': 1}))
          ]
        ),
        transition(
          ':leave', [
            style({'opacity': 1}),
            animate('500ms', style({'opacity': 0}))
          ]
        )]
    )
  ]
})
export class Type9Component implements OnInit {

  public task: TaskType9 = null;
  words = [];

  linkedUnconfirmedItem1: Type9Word = null;
  linkedUnconfirmedItem2: Type9Word = null;
  linkedUnconfirmedItem3: Type9Word = null;
  linkedUnconfirmedItem4: Type9Word = null;

  combinedWord = "";
  discoveredCombinations = [];

  linkedWord = null;
  move = 1;
  tappedItem = null;
  challengeActive = false;
  opacity = 0;
  unforgivingTestMode = false;
  audioPlaying = false;
  result = {correct: 0, of: 0, incorrectAttempts: 0, answer_details: []};

  constructor(private dataService: DataService, private messageService: MessageService) {
  }

  ngOnInit() {
    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    this.audioPlaying = false;
    this.move = 1;
    //GroverService.setControllerState(self);

    this.task = <TaskType9> this.dataService.currentTask;
    this.setupTask();
  }

  setupTask() {
    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 9 task does not exist - check your Session layout in the CMS');
      return;
    }

    // Categorise words by order to enforce order of selection

    let tempWords = [];
    for (let i = 1; i < 7; i++) {
      const word = this.task['word' + i];
      if (word.text !== '') {
        tempWords.push(word);
      }
    }
    this.result.of = this.task.total_correct;
    this.dataService.progressShow({stars: this.result.of});
    this.words = Utilities.shuffleArray(tempWords);
    this.words.sort((a, b) => {
      if (a.type === b.type) {
        return 0;
      } else if ((a.type === 'prefix' && b.type === 'root') || (a.type === 'root' && b.type === 'suffix')) {
        return -1;
      } else {
        return 1;
      }
    });
    this.challengeActive = true;

    // Introduce with instruction audio
    setTimeout(() => {
      this.opacity = 1;
      this.audioPlaying = true;
      let instructionAudio = new Audio('assets/sounds/task_instructions/type9.mp3');
      instructionAudio.addEventListener('ended', () => {
        this.dataService.setSpeakerSound('assets/sounds/task_instructions/type9.mp3', null);
        this.dataService.speakerIsPlaying = false;
        this.audioPlaying = false;
      });
      this.dataService.speakerIsPlaying = true;
      instructionAudio.play();
    }, 1000);
  };


  checkTouchEnd(event) {
    event.preventDefault();
    event.stopPropagation();
    let changedTouch = event.changedTouches[0];
    let element = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY);
    const id = parseInt(element.id.substr(4, 1)) - 1;

    if (-1 <= id && id < 6) {
      this.mouseUpOnWord({clientX: changedTouch.clientX, clientY: changedTouch.clientY}, this.words[id], element);
    } else {
      this.mouseUpOutsideBox();
    }
  };

  checkMouseEnd(event) {
    event.preventDefault();
    event.stopPropagation();
    let element = document.elementFromPoint(event.clientX, event.clientY);
    const id = parseInt(element.id.substr(4, 1)) - 1;

    if (-1 < id && id < 6) {
      this.mouseUpOnWord({clientX: event.clientX, clientY: event.clientY}, this.words[id], element);
    } else {
      this.mouseUpOutsideBox();
    }
  }

  isInOrder(item: Type9Word): boolean {
    if (!this.task.enforce_order) {
      return true;
    } else {
      return (this.move === 1 && this.task.firstWords.indexOf(item.text) > -1)
        || (this.move === 2 && this.task.secondWords.indexOf(item.text) > -1)
        || (this.move === 3 && this.task.thirdWords.indexOf(item.text) > -1)
        || (this.move === 4 && this.task.fourthWords.indexOf(item.text) > -1)
    }
  }

  mouseMoving(event) {
    if (this.linkedWord !== null) {
      this.messageService.sendMessage("morfologiTaskMousemove", event);
    }
  };

  mouseDownOnWord(event, item: Type9Word) {
    this.tappedItem = item;
    if (this.isInOrder(item)) {
      let startX, startY;
      if (typeof event.clientX !== 'undefined'){
        startX = event.clientX;
        startY = event.clientY;
      } else if (this.dataService.isCordovaApp) { // iOS compatibility
        if (event.touches && event.touches[0]) {
          startX = event.touches[0].clientX;
          startY = event.touches[0].clientY;
        }
      }
      let element = document.elementFromPoint(startX, startY);
      this.linkedWord = {
        item: item,
        startX: startX,
        startY: startY,
        startElement: element,
        endElement: null,
        endX: 0,
        endY: 0
      };
      if (this.move === 1) {        // We won't see the first item again after mouseDown
        this.attemptToAddNewItem(item);
      }
      this.messageService.sendMessage("morfologiTaskMousedown", this.linkedWord);
    }
  };

  playWordAudio() {
    if (this.tappedItem !== null && !this.audioPlaying) {
      this.audioPlaying = true;
      this.tappedItem.playAudio(() => {
        this.tappedItem = null;
        this.audioPlaying = false;
      })
    }
  }

  mouseUpOutsideBox() {
    this.messageService.sendMessage("morfologiTaskMouseup");
    if (this.move === 1) {
      this.linkedUnconfirmedItem1 = this.linkedUnconfirmedItem2 = this.linkedUnconfirmedItem3 = this.linkedUnconfirmedItem4 = null;
    }
    this.linkedWord = null;
  };

  mouseUpOnWord(event, item: Type9Word, el) {
    let foundASlot = false;

    this.messageService.sendMessage("morfologiTaskMouseup");

    // Mouse up was on the same item as mouse down
    if (this.tappedItem === item) {
      this.playWordAudio();
      this.dataService.progress.results.use_audio_content_items++;
      this.linkedWord = null;
      this.linkedUnconfirmedItem1 = this.linkedUnconfirmedItem2 = this.linkedUnconfirmedItem3 = this.linkedUnconfirmedItem4 = null;
    }

    // Mouse down item was not the same item as the last item in the arrow trail
    if (this.linkedWord && this.linkedWord.item !== this['linkedUnconfirmedItem' + this.move]) {
      this.linkedWord = null;
    }

    if (this.linkedWord) {
      foundASlot = this.attemptToAddNewItem(item);

      if (foundASlot) {

        let cc: Type9Correct = new Type9Correct({
          firstWord: this.linkedUnconfirmedItem1 !== null ? this.linkedUnconfirmedItem1.reference : 0,
          secondWord: this.linkedUnconfirmedItem2 !== null ? this.linkedUnconfirmedItem2.reference : 0,
          thirdWord: this.linkedUnconfirmedItem3 !== null ? this.linkedUnconfirmedItem3.reference : 0,
          fourthWord: this.linkedUnconfirmedItem4 !== null ? this.linkedUnconfirmedItem4.reference : 0,
          audio: null
        });

        let result: { status: string, correctRef: Type9Correct } = this.task.hasCombinationStatus(cc, this.discoveredCombinations);    // Result is the correct combination including audio

        if (result.status === 'correct') {		// Correct combination

          if (typeof event.clientX !== 'undefined') {
            this.linkedWord.endX = event.clientX;
            this.linkedWord.endY = event.clientY;
          } else if (this.dataService.isCordovaApp) { // iOS compatibility
            if (event.touches && event.touches[0]) {
              this.linkedWord.endX = event.touches[0].clientX;
              this.linkedWord.endY = event.touches[0].clientY;
            }
          }
          this.linkedWord.endElement = el;

          this.messageService.sendMessage("morfologiTaskLine", this.linkedWord);
          this.linkedUnconfirmedItem1 = this.linkedUnconfirmedItem2 = this.linkedUnconfirmedItem3 = this.linkedUnconfirmedItem4 = null;
          this.move = 1;
          this.combinedWord = this.task.combinedWordFromCC(result.correctRef);
          if (this.discoveredCombinations.indexOf(this.combinedWord) === -1) {      // Did we already find this word?
            this.result.correct++;
            this.dataService.progress.results.answer_details
              .push({
                attempt: this.task.wordsFromCC(cc),
                correct: true,
                elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall
              });
            this.dataService.progress.starData.completed++;
            // Fuse into one word
            // ToDo: animate...
            // Read completed word audio
            result.correctRef.playAudio();
            // Add completed word to completed list
            this.discoveredCombinations.push(this.combinedWord);
          }
          // Remove lines from screen
          setTimeout(() => {
            this.combinedWord = "";
            this.messageService.sendMessage("morfologiTaskLineClear");
          }, 2000);

          if (this.result.of === this.result.correct) {
            setTimeout(() => {
              if (this.dataService.taskMode === TaskMode.Warmups) {
                setTimeout(() => {
                  const a = new Audio('assets/sounds/task_instructions/warmups/type9.mp3');
                  a.addEventListener('ended', () => {
                    this.completeTask();
                  });
                  a.play();
                }, 1000);
              } else {
                this.completeTask();
              }
            }, 1500)
          }
        } else if (result.status === 'some') {
          // Allow user to continue adding to the unconfirmed set..
          if (typeof event.clientX !== 'undefined') {
            this.linkedWord.endX = event.clientX;
            this.linkedWord.endY = event.clientY;
          } else if (this.dataService.isCordovaApp) { // iOS compatibility
            if (event.touches && event.touches[0]) {
              this.linkedWord.endX = event.touches[0].clientX;
              this.linkedWord.endY = event.touches[0].clientY;
            }
          }
          this.linkedWord.endElement = el;
          this.messageService.sendMessage("morfologiTaskLine", this.linkedWord);
          this.move++;
        } else {
          //Incorrect choice
          this.messageService.sendMessage("morfologiTaskLineClear");
          this.linkedWord = null;
          this.dataService.progress.results.incorrectAttempts++;
          if (result.status !== 'duplicate') {
            this.dataService.progress.results.answer_details
              .push({
                attempt: this.task.wordsFromCC(cc),
                correct: false,
                elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall
              });
          }
          this.linkedUnconfirmedItem1 = this.linkedUnconfirmedItem2 = this.linkedUnconfirmedItem3 = this.linkedUnconfirmedItem4 = null;
          this.move = 1;
        }
      }
    }
  };

  // Ensure each linked item is a different word
  attemptToAddNewItem(item: Type9Word): boolean {
    let success = false;
    if (this.linkedUnconfirmedItem1 === null) {
      this.linkedUnconfirmedItem1 = item;
      success = true;
    } else if (this.linkedUnconfirmedItem1 === item) {
      success = false;
      // this.linkedUnconfirmedItem1 = null;
    } else if (this.linkedUnconfirmedItem2 === null) {
      if (this.linkedUnconfirmedItem1.reference !== item.reference) {
        this.linkedUnconfirmedItem2 = item;
      }
      success = true;
    }  else if (this.linkedUnconfirmedItem2 === item) {
      success = false;
      // this.linkedUnconfirmedItem2 = null;
    } else if (this.linkedUnconfirmedItem3 === null) {
      if (this.linkedUnconfirmedItem1.reference !== item.reference && this.linkedUnconfirmedItem2.reference !== item.reference) {
        this.linkedUnconfirmedItem3 = item;
      }
      success = true;
    }  else if (this.linkedUnconfirmedItem3 === item) {
      success = false;
      // this.linkedUnconfirmedItem3 = null;
    } else if (this.linkedUnconfirmedItem4 === null) {
      if (this.linkedUnconfirmedItem1.reference !== item.reference && this.linkedUnconfirmedItem2.reference !== item.reference && this.linkedUnconfirmedItem3.reference !== item.reference) {
        this.linkedUnconfirmedItem4 = item;
      }
      success = true;
    } else if (this.linkedUnconfirmedItem4 === item) {
      success = false;
      //this.linkedUnconfirmedItem4 = null;
    }
    if (!success && this.move === 1) {
      this.linkedUnconfirmedItem1 = null;
    }
    return success;
  }

  completeTask() {
    this.opacity = 0;
    setTimeout(() => {
      this.dataService.completeTask();
    }, 1000)
  };


}
