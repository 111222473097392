import {Component, OnInit, ViewEncapsulation, NgZone} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {ServerService} from '../../services/server.service';
import {
  Activity, Episode, EpisodeCompletion, Session, SessionCompletion, Student,
  TaskMode, StudentGroup
} from '../../models/general.model';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-testing',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit {

  students: Student[] = [];
  groups: StudentGroup[] = [];
  allActivities: Activity[] = [];
  selectedGroup: StudentGroup = null;
  editingGroup: StudentGroup = new StudentGroup({});
  selectedStudent: Student = null;
  editingStudent: Student = null
  selectedActivity: Activity = null;
  computedEpisodes: EpisodeCompletion[];
  editing: boolean = false;

  message = "";

  activity: Activity = null;

  episodeDict: { [id: string]: Episode } = {};
  sessionDict: { [id: string]: Session } = {};

  episodeIndexOfLastCompletedSession = -1;
  sessionIndexOfLastCompletion = -1;

  saveActive: boolean;
  showRemoveWarning: boolean;

  constructor(private serverService: ServerService, private dataService: DataService, private router: Router, private zone: NgZone) {
    dataService.taskMode = TaskMode.Sample;
    dataService.backgroundOpacity = 1;
    this.editing = false;
    this.showRemoveWarning = false;
  }

  ngOnInit() {
    this.getGroupsAndActivities();
  }

  getGroupsAndActivities() {
    this.serverService.getGroupsAndActivities().subscribe((response) => {
      this.zone.run(() => {
        this.groups = response.groups;
        this.allActivities = response.activities;
      })
    })
  }

  getSelectedStudentDetails() {
    this.serverService.getDetailsForStudent(this.selectedStudent._id).subscribe((response) => {
      this.zone.run(() => {
        this.selectedStudent = response.student;
      })
    })
  }

  backToGroups() {
    this.editingStudent = null;
    this.selectedStudent = null;
    this.selectedGroup = null;
  }
  deleteGroup(group) {
    const index = this.groups.findIndex(g => g._id == group._id)
    this.serverService.deleteGroup(group._id).subscribe(response => {
      this.zone.run(() => {
        this.groups.splice(index, 1);
        this.editing = false;
      })
    })
  }
  addGroup() {
    const activity = this.allActivities.length > 0 ? this.allActivities.find(a => a.searchKey == 'Broad Release') : null;
    const group = new StudentGroup({ name: 'New Group', activity })
    this.serverService.updateGroup(group).subscribe(response => {
      this.zone.run(() => {
        group._id = response.group._id;
        group.pin = response.group.pin;
        this.groups.push(group)
        this.editingGroup = group;
        this.editing = true;
      })
    })
  }
  updateGroup(group) {
    this.serverService.updateGroup(group).subscribe(response => {
      this.zone.run(() => {
        this.editingGroup = new StudentGroup({});
        this.editing = false;
      })
    })
  }
  selectGroup(group: StudentGroup) {
    this.selectedGroup = group
    this.getStudentsForGroup(group._id)
  }
  editGroup(group: StudentGroup) {
    this.editingGroup = group
    this.editing = true;
  }

  addStudent() {
    this.editingStudent = new Student({})
  }
/*   connectStudent() {
    this.serverService.connectStudent(this.selectedGroup, this.editingStudent).subscribe(response => {
      if (response.message != 'Done') {
        this.message = response.message
      } else {
        this.message = 'Student added'
        const s = new Student(response.student)
        this.selectedGroup.students.push(s)
      }
    })
  }
  removeStudent() {
    const i = this.selectedGroup.students.findIndex(s => this.editingStudent.user_id == s.user_id);
    this.selectedGroup.students.splice(i, 1);
    this.serverService.updateGroup(this.selectedGroup);
  } */
  cancelEditStudent() {
    this.editingStudent = null;
  }

  getStudentsForGroup(id) {
    this.serverService.getStudentsForGroup(id).subscribe((response) => {
      this.zone.run(() => {
        this.students = response.students;
        this.activity = response.activity;
        this.resetDictionaries();
      });
    })
  }

  resetDictionaries() {
    if (this.activity.episodes) {
      this.activity.episodes.forEach((e) => {
        this.episodeDict[e._id] = e;
        e.sessions.forEach((s) => {
          this.sessionDict[s._id] = s;
        })
      })
    }
  }

  cancelChanges() {
    this.selectedStudent = null;
    this.resetDictionaries();
    this.saveActive = false;
  }

  confirmRemoveStudentFromGroup() {
    this.showRemoveWarning = true
  }

  removeStudentFromGroup() {
    this.showRemoveWarning = false
    this.serverService.removeStudentFromGroup(
      { groupId: this.selectedGroup._id, studentId: this.selectedStudent._id }
    ).subscribe(response => {
      this.getStudentsForGroup(this.selectedGroup._id)
      this.resetDictionaries();
      this.cancelChanges()
    })
  }

  saveSelectedStudent() {
    let newEpisodes = [];
    this.computedEpisodes.forEach((e) => {
      let newEpisode: EpisodeCompletion = {
        _id: e._id,
        completed: e.completed,
        sessions: []
      };
      e.sessions.forEach((s) => {
        if (s.completed) {
          let newSession: SessionCompletion = {
            _id: s._id,
            skipped: s.skipped
          };
          newEpisode.sessions.push(newSession);
        }
      });
      newEpisodes.push(newEpisode);
    });
    if (this.selectedStudent.completions.activities.length === 0) {
      this.selectedStudent.completions.activities.push({
        _id: this.activity._id,
        completed: false,
        episodes: null
      })
    }
    this.selectedStudent.completions.activities[0].episodes = newEpisodes;
    this.selectedStudent.lastCompletion = 0;
    this.serverService.updateStudentAdmin(this.selectedStudent).subscribe(() => {
      this.zone.run(() => {
        this.selectedStudent = null;
        this.getStudentsForGroup(this.selectedGroup._id)
        this.resetDictionaries();
        this.saveActive = false;
      })
    })
  }

  clearCompletionsForStudent() {
    this.selectedStudent.completions.activities = [];
    this.selectedStudent.lastCompletion = 0;
    this.serverService.updateStudentAdmin(this.selectedStudent).subscribe(() => {
      this.zone.run(() => {
        this.selectedStudent = null;
        this.getStudentsForGroup(this.selectedGroup._id)
        this.resetDictionaries();
        this.saveActive = false;
      })
    })
  }

  logout() {
    this.router.navigateByUrl("/");
  }

  selectStudent(student: Student) {

    // Before setting the student, discover the details about Session and Task completions
    this.serverService.getDetailsForStudent(student._id).subscribe(response => {

      // Configure the DataService with these details so we can use its contained functions for processing
      this.dataService.setTestStudentDetails(response.student, this.activity);
      this.zone.run(() => {
        this.selectedStudent = this.dataService.student;

        // First find completed episodes
        this.computedEpisodes = this.selectedStudent.getCompletedEpisodes(this.activity._id).map((e) => {
          if (this.episodeDict.hasOwnProperty(e._id)) {
            e.name = this.episodeDict[e._id].name;
          } else {
            e.name = '(missing episode)';
          }
          return e;
        });

        // Append uncompleted episodes
        this.computedEpisodes = this.computedEpisodes.concat(this.activity.episodes.filter((e) => {
          return this.computedEpisodes.every((ce) => {
            return ce._id !== e._id;
          })
        }).map((e) => {
          return {
            _id: e._id,
            name: e.name,
            completed: false,
            sessions: []
          };
        }));

        // Accumulate the completed sessions until reaching the latest
        this.computedEpisodes.forEach((e, index) => {
          e.sessions = this.selectedStudent.getCompletedSessions(this.activity._id, e._id);
          e.sessions.forEach((s) => {
            if (this.sessionDict.hasOwnProperty(s._id)) {
              s.completed = true;
              s.name = this.sessionDict[s._id].name;
            } else {
              s.completed = true;
              s.name = '(missing session)';
            }
          });

          // Add uncompleted Sessions
          let uncompletedSessions = this.activity.episodes.find((ae) => {
            return ae._id === e._id;
          }).sessions.filter((s) => {
            return e.sessions.every((es) => {
              return es._id !== s._id;
            })
          }).map((fs) => {
            return {
              _id: fs._id,
              name: fs.name,
              completed: false,
              skipped: false
            };
          });
          if (e.sessions.length > 0) {
            this.episodeIndexOfLastCompletedSession = index;
            this.sessionIndexOfLastCompletion = e.sessions.length - 1;
          }
          e.sessions = e.sessions.concat(uncompletedSessions);
        });
      })

    })
  }

  clearCurrentCompletion() {
    this.saveActive = true;
    if (this.episodeIndexOfLastCompletedSession > -1 && this.episodeIndexOfLastCompletedSession < this.computedEpisodes.length) {
      this.computedEpisodes[this.episodeIndexOfLastCompletedSession].sessions[this.sessionIndexOfLastCompletion].completed = false;
      if (this.sessionIndexOfLastCompletion === this.computedEpisodes[this.episodeIndexOfLastCompletedSession].sessions.length - 1) {
        this.computedEpisodes[this.episodeIndexOfLastCompletedSession].completed = false;
      }
      if (this.sessionIndexOfLastCompletion === 0) {
        if (this.episodeIndexOfLastCompletedSession === 0) {
          this.episodeIndexOfLastCompletedSession = -1;
        } else {
          this.episodeIndexOfLastCompletedSession--;
          this.sessionIndexOfLastCompletion = this.computedEpisodes[this.episodeIndexOfLastCompletedSession].sessions.length - 1;
        }
      } else {
        this.sessionIndexOfLastCompletion--;
      }
    }
  }

  addNewCompletion() {
    this.saveActive = true;
    if (this.episodeIndexOfLastCompletedSession === -1) {
      this.episodeIndexOfLastCompletedSession++;
      this.sessionIndexOfLastCompletion = 0;
    } else if (this.episodeIndexOfLastCompletedSession < this.computedEpisodes.length) {
      if (this.sessionIndexOfLastCompletion < this.computedEpisodes[this.episodeIndexOfLastCompletedSession].sessions.length - 1) {
        this.sessionIndexOfLastCompletion++;
      } else if (this.episodeIndexOfLastCompletedSession < this.computedEpisodes.length - 1) {
        this.sessionIndexOfLastCompletion = 0;
        this.episodeIndexOfLastCompletedSession++;
      }
    }
    this.computedEpisodes[this.episodeIndexOfLastCompletedSession].sessions[this.sessionIndexOfLastCompletion].completed = true;
    if (this.sessionIndexOfLastCompletion === this.computedEpisodes[this.episodeIndexOfLastCompletedSession].sessions.length - 1) {
      this.computedEpisodes[this.episodeIndexOfLastCompletedSession].completed = true;
    }
  }

}
