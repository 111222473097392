import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../../services/data.service";

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  progress: number;

  constructor(public dataService: DataService) { }

  ngOnInit() {
    this.getWidth();
  }

  getWidth() {
    this.progress = this.dataService.progress.barData.completedPercent;
    return Math.floor((this.progress * 0.01 * (window.innerWidth <= 1024 ? 0.8 : 0.85) * 0.95) * window.innerWidth + 50) + 'px';
  }

}
