import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {
  Student,
  LoginResponse,
  TaskResponse,
  SessionResponse,
  LogResponse,
  Log,
  GroupResponse,
  StudentsResponse,
  StudentDetailResponse,
  GroupActivityResponse,
  ConnectStudentResponse
} from "../models/general.model";
import { Constants } from "../constants";

declare var window: any;
@Injectable()
export class ServerService {
  private http: HttpClient;
  private _apiUrl = "http://localhost:3000/";
  private _appUrl = "http://localhost:4200/";
  private usingCordova = false

  /*  /!**
   * General handler for server call errors
   * @param error
   * @returns {any}
   *!/
  static handleError (error: Response | any) { // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }*/

  constructor(http: HttpClient, private location: Location) {
    this.http = http;

    // Switch server address for local development..
    const parsedUrl = new URL(window.location.href);
    const theHost = parsedUrl.hostname;
    const protocol = parsedUrl.protocol;
    if (theHost == "localhost") {
      // Running on localhost dev machine
      this._apiUrl = protocol === 'https' ? "https://localhost:3001/" : "http://localhost:3000/";
      this._appUrl = "https://localhost:4200/#/";
    } else if (theHost == "192.168.1.101") {
      // Running on separate machine, same local network
      this._apiUrl = "https://192.168.1.101:3014/";
      this._appUrl = "https://192.168.1.101:4200/#/";
    } else if (theHost == "kapteinmorf.uio.no") {
      // App is being served from Engagelab server
      this._apiUrl = "https://kapteinmorf.uio.no/";
      this._appUrl = protocol + "//" + theHost + "/#/";
    } else {
      // App is running on tablet
      this.usingCordova = true
      this._apiUrl = Constants.productionUrl;
      this._appUrl = protocol + "//" + theHost + "/#/";
    }
  }

  // Tasks

  getOneTaskById(tasktype: string, taskid: string) {
    return this.http.get<TaskResponse>(
      this._apiUrl + "api/task/" + tasktype + "/" + taskid
    );
  }

  getSessionWithTasks(sessionId: string) {
    return this.http.get<SessionResponse>(
      this._apiUrl + "api/sessiontasks/" + sessionId
    );
  }

  // Login - Login Response contains Student and Activity. Activity is populated with Episode and Session data.

  login(type, pin, pass, ipadCode) {
    return this.http.get<LoginResponse>(
      this._apiUrl + "api/student/login/" + pin + "/" + pass + "/" + ipadCode
    );
  }

  loginSAML(intendsAdmin) {
    let feideLoginUrl = this._apiUrl + "auth/login/saml";
    let feideLoginQuery = `?intent=${intendsAdmin ? 'admin' : 'client'}`;
    feideLoginQuery += `&device=${this.usingCordova ? 'mobileApp' : 'webApp'}`;
    if (this.usingCordova) {
      window.OAuth(
        feideLoginUrl + feideLoginQuery,
        'oauth:dataporten',
        'allowInlineMediaPlayback=yes,toolbar=no'
      );
    } else {
      window.location.href = feideLoginUrl + feideLoginQuery;
    }
  }

  logoutSAML() {
    let feideLoginUrl = this._apiUrl + "auth/logout/saml";
    if (this.usingCordova) {
      feideLoginUrl += '?device=mobileApp';
    }
    window.OAuth(
      feideLoginUrl,
      'oauth:dataporten',
      'allowInlineMediaPlayback=yes,toolbar=no'
    );
  }

  acceptToken(code) {
    return this.http.get<LoginResponse>(
      this._apiUrl + "auth/login/acceptToken",
      { params: { code } }
    );
  }

  // Students

  updateStudent(student: Student, activity_id: string, tabletPasscode: string) {
    return this.http.put(this._apiUrl + "api/student", {
      student: student,
      activity_id: activity_id,
      passcode: tabletPasscode
    });
  }

  updateStudentAdmin(student: Student) {
    return this.http.put(this._apiUrl + "api/dashboard/student", {
      student: student
    });
  }

  getStudentsForGroup(id) {
    return this.http.get<StudentsResponse>(
      this._apiUrl + "api/dashboard/students" + `?groupid=${id}`
    );
  }
  getDetailsForStudent(id) {
    return this.http.get<StudentDetailResponse>(
      this._apiUrl + "api/dashboard/studentdetails" + `?id=${id}`
    );
  }
  deleteGroup(id) {
      return this.http.delete<GroupResponse>(
      this._apiUrl + "api/dashboard/group" + `?id=${id}`
    );
  }
  updateGroup(group) {
    return this.http.put<GroupResponse>(
      this._apiUrl + "api/dashboard/group", {
        group
      }
    );
  }
  connectStudent(groupPin, studentId) {
    return this.http.get<ConnectStudentResponse>(
      this._apiUrl + "api/dashboard/student" + `?id=${studentId}&groupPin=${groupPin}`
    )
  }
  removeStudentFromGroup({ groupId, studentId }) {
    return this.http.get<GroupResponse>(
      this._apiUrl + "api/dashboard/removestudentfromgroup" + `?id=${studentId}&groupid=${groupId}`
    )
  }

  getGroupsAndActivities() {
    return this.http.get<GroupActivityResponse>(
      this._apiUrl + "api/dashboard/groupsactivities"
    );
  }

  // Logs

  postToNettskjema(
    logs: Log[],
    activity_id: string,
    tabletPasscode: string,
    student: Student
  ) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    return this.http.post<LogResponse>(
      this._apiUrl + "api/logs",
      {
        logs: logs,
        activity_id: activity_id,
        passcode: tabletPasscode,
        user: student.user_id,
        pass: student.password
      },
      { headers }
    );
  }
}
