import { Component, OnInit, OnDestroy } from '@angular/core';
import {DataService} from "../../services/data.service";
import {
  ActivityPhase, DelayMode, EpisodePhase, SceneMode, TaskMode,
  ViewState
} from '../../models/general.model';
import {SpeechSounds} from '../../constants';

@Component({
  selector: 'app-delay',
  templateUrl: './delay.component.html',
  styleUrls: ['./delay.component.css']
})
export class DelayComponent implements OnInit, OnDestroy {

  viewState: DelayMode = DelayMode.None;
  DelayMode: any = DelayMode;
  showExitButton = false;
  showStartButton = false;
  episodeName = '';

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.showExitButton = false;
    this.showStartButton = false;
    switch (this.dataService.delayMode) {
      case DelayMode.AwaitingDownload:
        this.showExitButton = false;
        this.viewState = DelayMode.AwaitingDownload;
        break;
      case DelayMode.NoActivitiesFound:
        this.viewState = DelayMode.NoActivitiesFound;
        this.showExitButton = true;
        break;
      case DelayMode.NoSessionsFound:
        this.episodeName = this.dataService.currentEpisode.name;
        this.viewState = DelayMode.NoSessionsFound;
        this.showExitButton = true;
        break;
      case DelayMode.DemoComplete:
        this.viewState = DelayMode.DemoComplete;
        this.showExitButton = true;
        break;
      case DelayMode.WarmupsStarting:
        this.viewState = DelayMode.WarmupsStarting;
        this.dataService.speech.speak(SpeechSounds.narrator.N56, () => {
          this.showStartButton = true;
        }, 1000, false);
        break;
      case DelayMode.WarmupsFinished:
        this.viewState = DelayMode.WarmupsFinished;
        if (this.dataService.activityPhase === ActivityPhase.RCT && this.dataService.episodePhase === EpisodePhase.First) {
          this.dataService.speech.speak(SpeechSounds.narrator.N29, () => {
            this.showStartButton = true;
          }, 1000, false);
        } else {
          this.dataService.speech.speak(SpeechSounds.narrator.N45, () => {
            this.showStartButton = true;
          }, 1000, false);
        }
        break;
      case DelayMode.NoWarmups:
        this.viewState = DelayMode.WarmupsFinished;
        this.dataService.speech.speak(SpeechSounds.narrator.N64, () => {
          this.showStartButton = true;
        }, 1000, false);
        break;
      case DelayMode.AccessDenied:
        this.showExitButton = true;
        this.viewState = DelayMode.AccessDenied;
        break;
    }
  }

  ngOnDestroy() {
  }

  exitToLogin() {
    this.dataService.exitToLogin();
  }

  start() {
    this.dataService.sceneMode = SceneMode.Finished;
    this.dataService.viewState = ViewState.Tasks;
    this.dataService.updateState();
  }
}
