import { Component, OnInit, OnDestroy } from '@angular/core';
import {TaskType3} from '../../models/task-type3.model';
import {DataService} from '../../services/data.service';
import {DragulaService} from 'ng2-dragula-base/dist';
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";

@Component({
  selector: 'app-type3',
  templateUrl: './type3.component.html',
  styleUrls: ['./type3.component.css']
})
export class Type3Component implements OnInit, OnDestroy {

  // Display setup
  public task: TaskType3 = null;
  private unforgivingTestMode = null;
  private round = 1;
  private attempts = 0;
  public opacity = 0;
  public opacityWords = 0;

  // public draggableWords = [];
  private wordStore = [];
  public words = [];
  public droppedObjects1 = [];
  public droppedObjects2 = [];

  public draggedWord = {};
  public droppedItem1 = [];
  public droppedItem2 = [];

  private draggingNow = false;
  public highlight1 = false;
  public highlight2 = false;
  public highlightWord = false;
  private instructionAudio1 = null;
  private instructionAudio2 = null;
  private wordAudio1 = null;
  private wordAudio2 = null;
  private introDone = false;
  private audioPlaying = false;

  // correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
  private result = { correct: 0, of: 0, incorrectAttempts: 0, answer_details: [] };
  private box1Image = '';
  private box2Image = '';
  private wordAudio = {};
  private theWord = '';
  private taskCompleted = false;

  private dragulaSubscription;
  private dragulaCancelSubscription;
  private dragulaDragSubscription;

  constructor(private dragulaService: DragulaService, private dataService: DataService) {
    this.setupDragula();
  }

  ngOnInit() {
    // Begin the task..
    this.unforgivingTestMode = this.dataService.unforgivingTestMode;
    this.task = <TaskType3> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnDestroy() {
    this.dragulaSubscription.unsubscribe();
    this.dragulaCancelSubscription.unsubscribe();
    this.dragulaDragSubscription.unsubscribe();
    this.dragulaService.destroy('drag-bag3');
  }

  // ---------      Dragula functions -------------------

  private setupDragula() {
    this.dragulaService.setOptions('drag-bag3', {
      revertOnSpill: true,
      accepts: (el, target, source, sibling) => {
        return target.id !== 'word-source-box';
      },
      moves: (el, source, handle, sibling) => {
        return source.id === 'word-source-box' && this.opacityWords === 1 && !this.draggingNow; // don't prevent any drags from initiating by default
      }
    });

    this.dragulaDragSubscription = this.dragulaService.drag.subscribe((value) => {
      this.draggingNow = true;
    });

    this.dragulaCancelSubscription = this.dragulaService.cancel.subscribe((value) => {
      this.draggingNow = false;
    });

    this.dragulaSubscription = this.dragulaService.dropModel.subscribe((value) => {
      this.onDrop(value.slice(1));
    });
  }

  private onDrop(args) {
    const [el, target, source, sibling] = args;
    const boxIndex = this.getBoxIndex(target);
    if (this['droppedItem' + boxIndex]) {
      const word = this['droppedItem' + boxIndex].pop();
      this.attempts++;
      if (word.correct === boxIndex) {
        this['droppedObjects' + boxIndex].push(word);
        this.onDropComplete(word, boxIndex);
      } else {
        word.opacity = 0;
        this.dataService.progress.results.incorrectAttempts++;
        this.dataService.progress.results.answer_details.push({ attempt: word.text, correct: false });
        this.wordStore.push(word);
        this.wordStore = Utilities.shuffleArray(this.wordStore);
        setTimeout(() => {
          this.words.push(this.wordStore.pop());
          setTimeout(() => {
            this.words[0]['opacity'] = 1;
            this.draggingNow = false;
          }, 1000)
        }, 0);
      }
    }
  }

  private getWordIndex(el: any) {
    return parseInt(el.id.substr(11), 10);
  }

  private getBoxIndex(target: any) {
    return parseInt(target.id.substr(9), 10);
  }

  // ---------------  Setup --------------------------

  playWordAudio() {
    if (!this.draggingNow && this.introDone && !this.audioPlaying) {
      this.audioPlaying = true;
      this.highlight2 = false;
      this.highlight1 = false;
      this.highlightWord = true;
      if(this.words.length > 0) {
        this.wordAudio[this.words[0].text].addEventListener('ended', () => {
          this.highlightWord = false;
          this.audioPlaying = false;
        });
        this.wordAudio[this.words[0].text].play();
      } else {
        this.highlightWord = false;
        this.audioPlaying = false;
      }
    }
  }

  clickWord(box) {
    if (!this.draggingNow && !this.audioPlaying && this['wordAudio' + box] !== null) {
      this.audioPlaying = true;
      this['wordAudio' + box].play();
      this.dataService.progress.results.use_audio_content_items++;
    }
  }

  clickBoxedWord(text) {
    if (!this.draggingNow && !this.audioPlaying && this.wordAudio[text] !== null) {
      this.audioPlaying = true;
      this.wordAudio[text].play();
      this.dataService.progress.results.use_audio_content_items++;
    }
  }

  setupTask() {

    // Choose random locations
    // let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    // let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 3 task does not exist - check your Session layout in the CMS');
      return;
    }
    this.audioPlaying = false;
    this.taskCompleted = false;
    // this.box1Image = typeof this.task.box1Image !== 'undefined' ? this.task.box1Image.url : 'img/placeholder.jpg';
    // this.box2Image = typeof this.task.box2Image !== 'undefined' ? this.task.box2Image.url : 'img/placeholder.jpg';
    this.box1Image = 'assets/images/placeholder.jpg';
    this.box2Image = 'assets/images/placeholder.jpg';

    this.wordAudio1 = typeof this.task.round1.instruction.audio !== 'undefined' && this.task.round1.instruction.audio.url !== null ?
      new Audio(this.task.round1.instruction.audio.url) : null;
    if (this.wordAudio1 !== null) {
      this.wordAudio1.addEventListener('ended', () => {
        this.audioPlaying = false;
        this.highlightWord = false;
      });
    }

    this.wordAudio2 = typeof this.task.round1.instruction.audio2 !== 'undefined' && this.task.round1.instruction.audio2.url !== null ?
      new Audio(this.task.round1.instruction.audio2.url) : null;
    if (this.wordAudio2 !== null) {
      this.wordAudio2.addEventListener('ended', () => {
        this.audioPlaying = false;
        this.highlightWord = false;
      });
    }
    this.theWord = '';

    this.droppedObjects1 = [];
    this.droppedObjects2 = [];
    const round = this.task.round1;
    let tempWords = [];
    for (let i = 1; i < 7; i++) {
      if (round['option' + i].text !== '') {
        const word = {
          text: round['option' + i].text,
          correct: round['option' + i].correct,
          enabled: false,
          draggable: true,
          opacity: 0
        };

        this.wordAudio[word.text] = typeof round['option' + i].audio !== 'undefined' && round['option' + i].audio.url !== null ?
          new Audio(round['option' + i].audio.url) : null;
        if (this.wordAudio[word.text] !== null) {
          this.wordAudio[word.text].addEventListener('ended', function() {
            this.audioPlaying = false;
            this.highlightWord = false;
          });
        }

        if (word.correct > 0) {
          this.result.of++;
        }
        const occurances = round['option' + i].repeated;
        for (let r = 0; r < occurances; r++) {
          tempWords.push({ ...word });
        }
      }
    }
    this.dataService.progressShow({ stars: tempWords.length });
    tempWords = Utilities.shuffleArray(tempWords);

    this.wordStore = tempWords; // .splice(0,3);
    // this.words[1] = tempWords;

    this.words.push(this.wordStore.pop());
    this.words[0]['enabled'] = true;

    setTimeout(() => {
      this.words[0]['opacity'] = 1;
    }, 1000);

    this.introduceChallenge();
  }

  introduceChallenge = function() {
    this.attempts = 0;
    this.opacity = 1;
    this.opacityWords = 1;

    let generalAudio;

    // First check for custom introduction audio
    if (typeof this.task.round1.instruction.instructionAudio !== 'undefined'
      && this.task.round1.instruction.instructionAudio.url !== null) {
      generalAudio = new Audio(this.task.round1.instruction.instructionAudio.url);
      this.dataService.setSpeakerSound(this.task.round1.instruction.instructionAudio.url, null);
    } else {
      // Play generic audio if no custom audio was set, including two box audios
      generalAudio = new Audio('assets/sounds/task_instructions/type3.mp3');
      this.dataService.setSpeakerSound('assets/sounds/task_instructions/type3.mp3', null);
    }

    generalAudio.addEventListener('ended', () => {
      if (typeof this.task.round1.instruction.audio !== 'undefined' && this.task.round1.instruction.audio.url !== null) {
        this.instructionAudio1 = new Audio(this.task.round1.instruction.audio.url);
        if (typeof this.task.round1.instruction.audio2 !== 'undefined') {
          this.instructionAudio2 = new Audio(this.task.round1.instruction.audio2.url);
          this.instructionAudio1.addEventListener('ended', () => {
            this.highlight1 = false;
            this.instructionAudio2.addEventListener('ended', () => {
              this.introDone = true;
              this.highlight2 = false;
              setTimeout(() => {
                this.audioPlaying = false;
                this.dataService.speakerIsPlaying = false;
                this.playWordAudio();
              }, 1000);
            });
            setTimeout( () => {
              this.highlight2 = true;
              this.instructionAudio2.play();
            }, 500);
          });
        } else {
          this.instructionAudio1.addEventListener('ended', () => {
            this.introDone = true;
            this.highlight1 = false;
            setTimeout( () => {
              this.audioPlaying = false;
              this.dataService.speakerIsPlaying = false;
              this.playWordAudio();
            }, 1000);
          });
        }
        setTimeout( () => {
          this.highlight1 = true;
          this.instructionAudio1.play();
        }, 500);
      } else {
        this.introDone = true;
        this.audioPlaying = false;
        this.dataService.speakerIsPlaying = false;
      }
    });
    this.audioPlaying = true;
    this.dataService.speakerIsPlaying = true;
    generalAudio.play();
  };

  onDropComplete(item, box) {
    this.highlightWord = false;
    this.result.correct++;
    this.dataService.progress.results.answer_details
      .push({ attempt: item.text, correct: true, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall });
    item['opacity'] = 0;
    item.enabled = false;
    const p = this.dataService.progress;
    p.completeAStar();
    this.dataService.progress = p;
    if (this.wordStore.length > 0) {
      this.words.push(this.wordStore.pop());
      this.words[0]['enabled'] = true;
      setTimeout( () => {
        this.words[0]['opacity'] = 1;
        this.draggingNow = false;
        setTimeout( () => {
          this.audioPlaying = false;
          this.playWordAudio();
        }, 1000);
      }, 1000);
    }

    // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
    if ((this.unforgivingTestMode && this.attempts === this.result['round' + this.round].of) ||
      (!this.unforgivingTestMode && this.result.of === this.result.correct)) {
      if (!this.taskCompleted) {
        this.taskCompleted = true;
        this.opacityWords = 0;
        if (this.dataService.taskMode === TaskMode.Warmups) {
          setTimeout(() => {
            const a = new Audio('assets/sounds/task_instructions/warmups/type3.mp3');
            a.addEventListener('ended', () => {
              this.fadeOut();
            });
            a.play();
          }, 1000);
        } else {
          this.fadeOut();
        }
      }
    }
  }

  fadeOut() {
    setTimeout( () => {
      this.opacity = 0;
      this.completeTask();
    }, 1000);
  }

  completeTask() {
    setTimeout( () => {
      this.dataService.completeTask();
    }, 1000);
  }


}
