import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appTaskAnchor]'
})
export class TaskAnchorDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
