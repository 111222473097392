import {Directive, ElementRef, Input, SimpleChanges} from '@angular/core';
import {Utilities} from '../utilities/utilities';

@Directive({
  selector: '[appCaretPosition]'
})
export class CaretPositionDirective {

  caretLoc = "";
  savedCaretIndex = 0;
  morfem = "";
  previousText = "";

  constructor(private el: ElementRef) {}

  @Input() set theMorfem(morfem: string) {
    this.morfem = morfem;
    this.setCaret();
  };

  @Input() set theText(text: string) {
    let t = Utilities.filterTextCharacters(text).toLowerCase();

    if (t.length === this.el.nativeElement.value.length) {
      //this.saveCaret();
      //this.reloadCaret(this.savedCaretIndex);
    }
    this.el.nativeElement.value = t;
    this.previousText = t;
  };

  @Input() set caretLocation(position: string) {
    this.caretLoc = position;
  }

  private setCaret() {
    // this.el.nativeElement.value = this.morfem;
    this.el.nativeElement.focus();
    if (this.caretLoc === 'start') {
      this.el.nativeElement.setSelectionRange(0, 0);
    } else if (this.caretLoc === 'end') {
      this.el.nativeElement.setSelectionRange(this.morfem.length, this.morfem.length);
    }
  }

  private saveCaret() {
    this.savedCaretIndex = this.el.nativeElement.selectionStart;
  }

  private reloadCaret(index) {
    setTimeout(() => {
      this.el.nativeElement.setSelectionRange(index, index);
        this.el.nativeElement.setSelectionRange(index, index);
    }, 500);
  }

}
