import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../../services/data.service";

@Component({
  selector: 'app-progress-stars',
  templateUrl: './progress-stars.component.html',
  styleUrls: ['./progress-stars.component.css']
})
export class ProgressStarsComponent implements OnInit {

  starColour: string;

  constructor(public dataService: DataService) {
    this.starColour = 'yellow';
  }

  ngOnInit() {}

  getMyImage(starNumber: number): string {
    const path = 'assets/images/stars/' + this.starColour + '/';
    if (this.dataService.progress.starData.stars >= starNumber) {
      return this.dataService.progress.starData.completed >= starNumber
        ? (this.dataService.progress.starData.completed > starNumber
          ? path + "starFullState100x100px.png" : path + "starAnimation500x500px.gif") : path + "starEmptyState100x100px.png";
    } else {
      return "";
    }
  }

  getMyVisibility(starNumber: number): boolean {
   return this.dataService.progress.starData.stars >= starNumber;
  }

}
