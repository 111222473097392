import {Task} from './task.model';
import {Constants} from '../constants';

export class Type8Word {
  text: string;
  type?: string;
  audio: { url: string; };
  displayText?: string;
  reference?: number;     // Reference should be the CMS index of this word, to assist matching
  audioTrack: HTMLAudioElement;
  constructor(data: {}) {
    this.audio = { url: null };
    this.text = '';
    if (data) {
      if (data.hasOwnProperty('audio')) {
        this.audio.url = data['audio'].url;
        this.audioTrack = new Audio(this.audio.url);
      }
      this.text = data['text'];
      this.reference = data['reference'];
      // this.type = data['type'];
      /*    if (this.type === 'prefix') {
            this.displayText = this.text + '-';
          } else if (this.type === 'suffix') {
            this.displayText = '-' + this.text;
          } else {
            this.displayText = this.text;
          }*/
      this.displayText = this.text;
    }
  }

  public playAudio(callback) {
    if (this.audio.url !== null) {
      this.audioTrack.addEventListener('ended', () => {
        callback();
      });
      this.audioTrack.play().catch();
    } else {
      callback();
    }
  }
}

export class Type8Correct {
  firstWord: number;
  secondWord: number;
  audio: { url: string; };

  constructor(data: {}) {
    if (data) {
      this.firstWord = data['firstWord'];
      this.secondWord = data['secondWord'];
      this.audio = {url: null};
      if (data.hasOwnProperty('audio') && data['audio'] !== null) {
        this.audio.url = data['audio'].url
      }
    }
  }

  public playAudio() {
    if (this.audio.url !== null) {
      new Audio(this.audio.url).play();
    }
  }
}

export class TaskType8 extends Task {
  morph: string = "";
  affix: string = "";
  category: string = "";
  morf_category: string;

  presetWord: {
    text: string;
    audio: { url: string; };
    position: number;         // Position is the drop box number to place the preselected word into
    reference?: number;
  };
  introduction_audio: { url: string; };
  total_correct_possible: number;
  unforgiving: boolean;

  word1: Type8Word;
  word2: Type8Word;
  word3: Type8Word;
  word4: Type8Word;
  word5: Type8Word;

  word6?: Type8Word;

  correct1: Type8Correct;
  correct2: Type8Correct;
  correct3: Type8Correct;
  correct4: Type8Correct;
  correct5: Type8Correct;


  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.affix = input['affix'];
    this.morf_category = input['morf_category'];

    this.presetWord = {
      text: '',
      position: 0,
      reference: 6,
      audio: { url: null }
    };

    if (input['presetWord']) {
      if ( input['presetWord'].text) {
        this.presetWord.text = input['presetWord'].text;
      }
      if (input['presetWord'].audio && input['presetWord'].audio.url) {
        this.presetWord.audio.url = input['presetWord'].audio.url;
      }
      this.presetWord.position = input['presetWord'].position;
      this.presetWord.reference = 6;
      this.word6 = new Type8Word(this.presetWord);
    }
    if (input.hasOwnProperty('presetWord') && input['presetWord'].hasOwnProperty('audio')) {
      this.presetWord.audio.url = input['presetWord']['audio'].url
    }
    this.introduction_audio = { url: null };
    this.unforgiving = input['unforgiving'];
    if (input.hasOwnProperty('introduction_audio')) {
      this.introduction_audio.url = input['introduction_audio'].url
    }

    for (let w = 1; w < 6; w++) {
      this['word' + w] = new Type8Word(input['word' + w]);
      this['word' + w].reference = w;
    }

    this.total_correct_possible = 0;
    let correctIndex = 1;
    for (let c = 1; c < 6; c++) {
      if (typeof input['correct' + c] !== 'undefined' && input['correct' + c] !== null) {
        this['correct' + correctIndex] = new Type8Correct(input['correct' + c]);
        this.total_correct_possible++;
        correctIndex++;
      }
    }

    return this;
  }


  // Calculate if the given combination fully or partially matches a correct answer
  public hasCombinationStatus(cc: Type8Correct, compareInput: Type8Correct[]): { status: string, correctRef: Type8Correct } {
    let result = { status: 'incorrect', correctRef: null };

    // If only one word has been placed, return 'some'
    if ((cc.firstWord === 0 && cc.secondWord > 0) || (cc.firstWord > 0 && cc.secondWord === 0)) {
      result.status = 'some';
      return result;
    }

    let checkCombinations = (cc, compare): boolean => {
      let a = compare.firstWord === cc.firstWord;
      let b = compare.secondWord === cc.secondWord;

      if (a && b) {
        result.status = 'correct';
        result.correctRef = compare;
        return true;
      }
      return false;
    };

    if (compareInput !== null) {      // Compare using supplied combinations
      for (let i = 0; i < compareInput.length; i++) {
        let compare: Type8Correct = compareInput[i];
        if (checkCombinations(cc, compare)) {
          break;
        }
      }
    } else {
      for (let i = 1; i < 6; i++) {   // Compare using the combinations in this class
        let compare: Type8Correct = this['correct' + i];
        if (checkCombinations(cc, compare)) {
          break;
        }
      }
    }
    return result;
  }

  // A string showing the words inside the given Type7Correct class
  public wordsFromCC(cc: Type8Correct) {
    let w1 = '', w2 = '';
    if (cc.firstWord > 0) {
      w1 = this['word' + cc.firstWord].text;
    }
    if (cc.secondWord > 0) {
      w2 = ', ' + this['word' + cc.secondWord].text;
    }
    return w1 + w2;
  }

  rewriteUrlsToLocalAssets(input: Object) {

    if (this.word1 && this.word1.audio && this.word1.audio.url) {
      this.word1.audio.url =
        Constants.localAssetUrl + input['word1']['audio']['path'] + '/' + input['word1']['audio']['filename'];
    }
    if (this.word2 && this.word2.audio && this.word2.audio.url) {
      this.word2.audio.url =
        Constants.localAssetUrl + input['word2']['audio']['path'] + '/' + input['word2']['audio']['filename'];
    }
    if (this.word3 && this.word3.audio && this.word3.audio.url) {
      this.word3.audio.url =
        Constants.localAssetUrl + input['word3']['audio']['path'] + '/' + input['word3']['audio']['filename'];
    }
    if (this.word4 && this.word4.audio && this.word4.audio.url) {
      this.word4.audio.url =
        Constants.localAssetUrl + input['word4']['audio']['path'] + '/' + input['word4']['audio']['filename'];
    }
    if (this.word5 && this.word5.audio && this.word5.audio.url) {
      this.word5.audio.url =
        Constants.localAssetUrl + input['word5']['audio']['path'] + '/' + input['word5']['audio']['filename'];
    }
    if (this.word6 && this.word6.audio && this.word6.audio.url) {
      this.word6.audio.url =
        Constants.localAssetUrl + input['presetWord']['audio']['path'] + '/' + input['presetWord']['audio']['filename'];
    }

    for (let c = 1; c < 6; c++) {
      let ref = typeof this['correct' + c] !== 'undefined' ? this['correct' + c] : null;
      if (ref && ref['audio'] && ref['audio']['url']) {
        ref['audio']['url'] =
          Constants.localAssetUrl + input['correct' + c]['audio']['path'] + '/' + input['correct' + c]['audio']['filename'];
      }
    }

    if (this.presetWord.audio.url) {
      this.presetWord.audio.url =
        Constants.localAssetUrl + input['presetWord']['audio']['path'] + '/' + input['presetWord']['audio']['filename'];
    }

    if (this.introduction_audio.url) {
      this.introduction_audio.url =
        Constants.localAssetUrl + input['introduction_audio']['path'] + '/' + input['introduction_audio']['filename'];
    }

  }

}
