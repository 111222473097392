import { Component, OnInit } from '@angular/core';
import {SceneMode, SceneType, Session, ShipMode, ViewState} from '../../models/general.model'
import {DataService} from "../../services/data.service";
import {SpeechSounds} from '../../constants';
// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
//   animations: [
//   trigger('stageActive', [
//     state('inactive', style({
//       boxShadow: 'unset',
//       transform: 'scale(1)'
//     })),
//     state('active',   style({
//       boxShadow: '0px 0px 3px blue',
//       transform: 'scale(1.1)'
//     })),
//     transition('inactive => active', animate('100ms ease-in')),
//     transition('active => inactive', animate('100ms ease-out'))
//   ])
// ]
})
export class MapComponent implements OnInit {

  flagSessions: Session[];
  activeSession: Session;
  showActiveSession = false;
  fullPaneHeightOnMobile = false;

  idleTimer = null;
  warningGiven = false;

  constructor(private dataService: DataService) {
   this.fullPaneHeightOnMobile = this.dataService.isCordovaApp;
  }

  ngOnInit() {
    this.showActiveSession = false;
    this.flagSessions = this.dataService.currentEpisode.sessions;
    this.activeSession = this.dataService.currentSession;

    this.resetIdleTimeout();

    setTimeout(() => {
      this.scrollToActiveStage();
    }, 500);
  }

  scrollToActiveStage() {
    if (this.dataService.shipMode !== ShipMode.SessionCompleted) {
      setTimeout(() => {
        if (this.activeSession.location.x > 80) {
          (<HTMLScriptElement>document.querySelector('#map-session-' + this.activeSession._id)).scrollIntoView({behavior: 'smooth'});
        }
        setTimeout(() => {
          this.showActiveSession = true;
          if (this.dataService.shipMode === ShipMode.SessionEnding) {
            setTimeout(() => {
              this.activeSession.activated = false;
              this.dataService.sceneMode = SceneMode.Ready;
              this.dataService.sceneType = SceneType.GeneralScene;
              this.dataService.viewState = ViewState.Ship;
              this.dataService.updateState(true);
            }, 5000);

          }
        }, 1000);

      }, 0);
    }
  }

  resetIdleTimeout() {
    if (this.returnToShipButtonActive()) {
      if (this.idleTimer !== null && this.warningGiven) {
        clearTimeout(this.idleTimer);
      }
      this.idleTimer = setTimeout(() => {
        if (this.warningGiven) {
          this.dataService.speech.speak(SpeechSounds.computer.C62, () => {
            this.dataService.exitToLogin();
          }, 0, true);
        } else {
          this.warningGiven = true;
          this.resetIdleTimeout();
          this.dataService.speech.speak(SpeechSounds.computer.C66, () => {}, 0, true);
        }
      }, 60000)
    }
  }

  startSelectedSession() {
    this.dataService.startSession();
  }

  returnToShipButtonActive() {
    return this.dataService.currentSession.completed && !this.dataService.currentSession.activated;
  }

  returnToShip() {
    if (this.returnToShipButtonActive()) {
      clearTimeout(this.idleTimer);
      this.dataService.sceneMode = SceneMode.Finished;
      this.dataService.viewState = ViewState.Ship;
      this.dataService.shipMode = ShipMode.ReturnedFromInteractiveMap;
      this.dataService.updateState();
    }
  }

}
