import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  ComponentFactoryResolver
} from "@angular/core";
import { ActivatedRoute, UrlSegment } from "@angular/router";
import { DataService } from "../../services/data.service";
import { Type3Component } from "../../tasks/type3/type3.component";
import { Type1Component } from "../../tasks/type1/type1.component";
import { Type6Component } from "../../tasks/type6/type6.component";
import { Type2Component } from "../../tasks/type2/type2.component";
import { Type4Component } from "../../tasks/type4/type4.component";
import { Type5Component } from "../../tasks/type5/type5.component";
import { Type7Component } from "../../tasks/type7/type7.component";
import { Type9Component } from "../../tasks/type9/type9.component";
import { Type8Component } from "../../tasks/type8/type8.component";
import { Type11Component } from "../../tasks/type11/type11.component";
import { Type10Component } from "../../tasks/type10/type10.component";
import { Type12Component } from "../../tasks/type12/type12.component";
import { Type13Component } from "../../tasks/type13/type13.component";
import { TaskMode } from "../../models/general.model";
import { DelayComponent } from "../delay/delay.component";
// import { TaskAnchorDirective } from "../../directives/task-anchor.directive";

@Component({
  selector: "app-task",
  templateUrl: "./task.component.html",
  styleUrls: ["./task.component.css"]
})
export class TaskComponent implements OnInit, OnDestroy {
  selectedTaskClass;
  selectedTaskType: number = -1;
  sampleView = false;

  private componentRegistry = {
    Type1Component: Type1Component,
    Type2Component: Type2Component,
    Type3Component: Type3Component,
    Type6Component: Type6Component,
    Type4Component: Type4Component,
    Type5Component: Type5Component,
    Type7Component: Type7Component,
    Type8Component: Type8Component,
    Type9Component: Type9Component,
    Type11Component: Type11Component,
    Type10Component: Type10Component,
    Type12Component: Type12Component,
    Type13Component: Type13Component,
    DelayComponent: DelayComponent
  };

  // @ViewChild(TaskAnchorDirective, { static: false }) taskAnchor !: TaskAnchorDirective;

  taskTypeSubscription: any;

  constructor(
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dataService: DataService,
    public vcRef: ViewContainerRef
  ) {
    this.route.url.subscribe((res: UrlSegment[]) => {
      let paths = res.map(segment => segment.path);

      // This services the need to display tasks directly from the CMS
      if (paths.length > 2 && paths[0] === "sample") {
        this.sampleView = true;
        this.dataService.taskMode = TaskMode.Sample;
        this.dataService.sampleTask(
          { tasktype: paths[1], taskid: paths[2] },
          nextTaskType => {
            setTimeout(() => {
              dataService.backgroundImage =
                "assets/images/SampleTaskLocation@2x.png";
              this.loadComponent(
                this.componentRegistry["Type" + nextTaskType + "Component"]
              );
            }, 1000);
          }
        );
      }
    });
  }

  ngOnInit() {
    // Here we should have tasks already in the dataService, and the 'currentTask' should be set before changing route to '/task'
    if (!this.sampleView) {
      // this.loadComponent(this.componentRegistry['DelayComponent']);
      this.taskTypeSubscription = this.dataService.selectedTaskType.subscribe(
        nextTaskType => {
          if (!this.sampleView && nextTaskType !== 0) {
            this.loadComponent(
              this.componentRegistry["Type" + nextTaskType + "Component"]
            );
          }
        }
      );
    }
  }

  ngOnDestroy() {
    this.taskTypeSubscription.unsubscribe();
  }

  registerActivity() {
    this.dataService.resetInactiveTimer();
  }

  loadComponent(nextTask) {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      nextTask
    );

    let viewContainerRef = this.vcRef;
    viewContainerRef.clear();

    let componentRef = viewContainerRef.createComponent(componentFactory);
  }
}
