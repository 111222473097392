import { myWindow } from "../typings";
declare let window: myWindow;

export const SpeechSounds = {
  narrator: {
    N01: "01N.mp3",
    N02: "02N.mp3",
    N03: "03N.mp3",
    N04: "04N.mp3",
    N06: "06N.mp3",
    N11: "11N.mp3",
    N29: "29N.mp3",
    N31: "31N.mp3",
    N34: "34N.mp3",
    N35: "35N.mp3",
    N37: "37N.mp3",
    N39: "39N.mp3",
    N45: "45N.mp3",
    N56: "56N.mp3",
    N64: "64N.mp3"
  },
  computer: {
    C05: "05C.mp3",
    C07: "07C.mp3",
    C08: "08C.mp3",
    C10: "10C.mp3",
    C12: "12C.mp3",
    C13: "13C.mp3",
    C14: "14C.mp3",
    C15: "15C.mp3",
    C16: "16C.mp3",
    C17: "17C.mp3",
    C18: "18C.mp3",
    C19: "19C.mp3",
    C20: "20C.mp3",
    C21: "21C.mp3",
    C22: "22C.mp3",
    C23: "23C.mp3",
    C24: "24C.mp3",
    C25: "25C.mp3",
    C26: "26C.mp3",
    C27: "27C.mp3",
    C28: "28C.mp3",
    C30: "30C.mp3",
    C32: "32C.mp3",
    C36: "36C.mp3",
    C38: "38C.mp3",
    C40: "40C.mp3",
    C41: "41C.mp3",
    C41_44: "41_44C.mp3",
    C42: "42C.mp3",
    C43: "43C.mp3",
    C44: "44C.mp3",
    C46: "46C.mp3",
    C47: "47C.mp3",
    C48: "48C.mp3",
    C49: "49C.mp3",
    C50: "50C.mp3",
    C51: "51C.mp3",
    C52: "52C.mp3",
    C53: "53C.mp3",
    C54: "54C.mp3",
    C55: "55C.mp3",
    C56: "56C.mp3",
    C57: "57C.mp3",
    C58: "58C.mp3",
    C59: "59C.mp3",
    C60: "60C.mp3",
    C61: "61C.mp3",
    C62: "62C.mp3",
    C63: "63C.mp3",
    C65: "65C.mp3",
    C66: "66C.mp3",

    C69: "69C.mp3",
    C70: "70C.mp3"
  },
  child: {
    A09: "09A.mp3"
  },
  morfological: {
    MI33: "33MI-lavt.mp3",
    MI33_high: "33MI_høyt.mp3",
    MI33_best: "33MIbest.mp3",
    MI33_org: "33MIorg.mp3",
    MI33_orgtest: "33MIorgtest2.mp3",
    MI33_test: "33MItest.mp3"
  }
};

export const Constants = {
  url: "/",
  useLocalAssets: !!window["cordova"],
  localAssetUrl: "assets/localCMSAssets", // No trailing slash in path!
  productionUrl: "https://kapteinmorf.uio.no/",
  //productionUrl: 'https://localhost:3001/',
  SESSION_TYPES: "Test, Introduction",
  SKIP_SESSION_PASSWORD: "Sesameåpne",
  ipadConnectionCode: "xkalsdju37erkm3kjd7jlo9ssdaWS", // This must be matched with server-side constant.  Set to empty string at web front end.
  activityPhase_rct: "RCT activity", // This must match the 'searchKey' of the RCT Activity in CMS
  activityPhase_rct_testing: "RCT Testing", // This must match the 'searchKey' of the RCT Testing Activity in CMS
  activityPhase_demonstration: "Demonstration activity", // This must match the 'searchKey' of the demonstration Activity in CMS
  activityPhase_regular: "Regular",
  activityPhase_broad_release: "Broad Release", // Used for wider App Store release version of KM
  activityPhase_broad_release_testing: "Broad Release Testing"
};

export const EpisodeScenes: {
  [id: number]: { intros: string[]; outros: string[] };
} = {
  // 0: Reserved for Demo
  0: {
    intros: ["episodes/1/intros/4/"],
    //intros: [],
    outros: ["episodes/5/outros/1/"]
  },
  // 1: Pre-Test scenes
  1: {
    intros:
      Constants.productionUrl.indexOf("kapteinmorf.uio.no") > -1
        ? [
            "episodes/1/intros/1/",
            "episodes/1/intros/2/",
            "episodes/1/intros/3/",
            "episodes/1/intros/4/"
          ]
        : [],
    // intros: ['episodes/1/intros/4/'],
    // intros: [],
    // intros: ['episodes/1/intros/1/','episodes/1/intros/2/','episodes/1/intros/3/','episodes/1/intros/4/'],
    outros: ["episodes/1/outros/1/", "episodes/1/outros/2/"]
    //outros: []
  },
  // 2: Main Episode scenes
  2: {
    // intros: [],
    // intros: ['episodes/2/intros/3/'],
    intros:
      Constants.productionUrl.indexOf("kapteinmorf.uio.no") > -1
        ? [
            "episodes/2/intros/1/",
            "episodes/2/intros/2/",
            "episodes/2/intros/3/",
            "episodes/2/intros/4/",
            "episodes/2/intros/5/",
            "episodes/2/intros/6/",
            "general/1/"
          ]
        : [],
    outros: ["episodes/2/outros/4/"]
    //outros: []
  },
  // 3-5: Post-Test Scenes
  3: {
    intros: ["episodes/3/intros/1/"],
    outros: ["episodes/3/outros/1/"]
  },
  4: {
    intros: ["episodes/4/intros/1/"],
    outros: ["episodes/4/outros/1/"]
  },
  5: {
    intros: ["episodes/5/intros/1/"],
    outros: ["episodes/5/outros/1/"]
  }
};

export const categoryTypes = [
  { value: 0, label: "Real word" },
  { value: 1, label: "Trained item" },
  { value: 2, label: "Non-word" }
];

export const stemTypes = [
  { value: 1, label: "Stem" },
  { value: 2, label: "Morphed Stem" }
];
