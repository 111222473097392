interface Serializable<T> {
  deserialize(input: Object): T;
}

export class Task implements Serializable<Task> {
  taskType: number;
  _id: string = '';
  reference: string;

  constructor(taskType: number, input?: Object) {
    this.taskType = taskType;
    if (input.hasOwnProperty("_id")) {
      this._id = input["_id"];
    }
    if (input.hasOwnProperty("reference")) {
      this.reference = input["reference"];
    }
  }

  deserialize(input: Object): Task {
    return this;
  }
}
