import {Task} from './task.model';
import {Constants} from '../constants';

export class TaskType3 extends Task {
  morph: string = "";
  stem: string = "";
  morphedStem: string = "";
  affix: string = "";
  category: string = "";
  round1: {
    instruction: {
      instructionAudio: {
        url: string;
      },
      instructionAudioText: string;
      audio: {
        url: string;
      },
      audio2: {
        url: string;
      }
    },
    option1: {
      text: string;
      audio: {
        url: string;
      },
      correct: number;
      repeated: number;
    },
    option2: {
      text: string;
      audio: {
        url: string;
      },
      correct: number;
      repeated: number;
    },
    option3: {
      text: string;
      audio: {
        url: string;
      },
      correct: number;
      repeated: number;
    },
    option4: {
      text: string;
      audio: {
        url: string;
      },
      correct: number;
      repeated: number;
    },
    option5: {
      text: string;
      audio: {
        url: string;
      },
      correct: number;
      repeated: number;
    },
    option6: {
      text: string;
      audio: {
        url: string;
      },
      correct: number;
      repeated: number;
    }
  };

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

/*  deserialize(instanceData: any) {
    // Note this.active will not be listed in keys since it's declared, but not defined
    const keys = Object.keys(this);

    for (const key of keys) {
      if (instanceData.hasOwnProperty(key)) {
        this[key] = instanceData[key];
      }
    }
  }*/

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.stem = input['stem'];
    this.morphedStem = input['morphedStem'];
    this.affix = input['affix'];
    this.round1 = input['round1'];
    return this;
  }

  rewriteUrlsToLocalAssets(input: Object) {
    if (this.round1 && this.round1.instruction) {
      if (this.round1.instruction.instructionAudio) {
        this.round1.instruction.instructionAudio.url =
          Constants.localAssetUrl + input['round1']['instruction']['instructionAudio']['path'] +
          '/' + input['round1']['instruction']['instructionAudio']['filename'];
      }
      if (this.round1.instruction.audio) {
        this.round1.instruction.audio.url =
          Constants.localAssetUrl + input['round1']['instruction']['audio']['path'] +
          '/' + input['round1']['instruction']['audio']['filename'];
      }
      if (this.round1.instruction.audio2) {
        this.round1.instruction.audio2.url =
          Constants.localAssetUrl + input['round1']['instruction']['audio2']['path'] +
          '/' + input['round1']['instruction']['audio2']['filename'];
      }
    }
    if (this.round1 && this.round1.option1 && this.round1.option1.audio) {
      this.round1.option1.audio.url =
        Constants.localAssetUrl + input['round1']['option1']['audio']['path'] +
        '/' + input['round1']['option1']['audio']['filename'];
    }
    if (this.round1 && this.round1.option2 && this.round1.option2.audio) {
      this.round1.option2.audio.url =
        Constants.localAssetUrl + input['round1']['option2']['audio']['path'] +
        '/' + input['round1']['option2']['audio']['filename'];
    }
    if (this.round1 && this.round1.option3 && this.round1.option3.audio) {
      this.round1.option3.audio.url =
        Constants.localAssetUrl + input['round1']['option3']['audio']['path'] +
        '/' + input['round1']['option3']['audio']['filename'];
    }
    if (this.round1 && this.round1.option4 && this.round1.option4.audio) {
      this.round1.option4.audio.url =
        Constants.localAssetUrl + input['round1']['option4']['audio']['path'] +
        '/' + input['round1']['option4']['audio']['filename'];
    }
    if (this.round1 && this.round1.option5 && this.round1.option5.audio) {
      this.round1.option5.audio.url =
        Constants.localAssetUrl + input['round1']['option5']['audio']['path'] +
        '/' + input['round1']['option5']['audio']['filename'];
    }
    if (this.round1 && this.round1.option6 && this.round1.option6.audio) {
      this.round1.option6.audio.url =
        Constants.localAssetUrl + input['round1']['option6']['audio']['path'] +
        '/' + input['round1']['option6']['audio']['filename'];
    }
  }

}
