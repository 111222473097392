import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-speaker',
  templateUrl: './speaker.component.html',
  styleUrls: ['./speaker.component.css']
})
export class SpeakerComponent implements OnInit {

  constructor(public dataService: DataService) { }

  ngOnInit() {
  }


  clickSpeaker() {
    this.dataService.speakerClick();
  }

}
