import {Component, OnInit, OnDestroy} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import {DataService} from '../../services/data.service';
import {TaskType8, Type8Correct, Type8Word} from '../../models/task-type8.model';
import {DragulaService} from 'ng2-dragula-base/dist';
import {Utilities} from "../../utilities/utilities";
import {TaskMode} from "../../models/general.model";
import {Type7Word} from '../../models/task-type7.model';

@Component({
  selector: 'app-type8',
  templateUrl: './type8.component.html',
  styleUrls: ['./type8.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        transition(
          ':enter', [
            style({ opacity: 0}),
            animate('500ms', style({'opacity': 1}))
          ]
        ),
        transition(
          ':leave', [
            style({'opacity': 1}),
            animate('500ms', style({'opacity': 0}))
          ]
        )]
    )
  ]
})
export class Type8Component implements OnInit, OnDestroy {

  public task: TaskType8 = null;
  // Display setup
  attempts = 0;
  opacity = 0;
  opacityWords = 0;

  // Models for words
  words: Type8Word[] = [];
  discoveredCombinations = [];
  correctCombinations = [];

  // Divide the correct combined word answers into twos and threes, so we know how many answer boxes to show on screen
  combinationBoxes = 2;

  // Temp models for Dragula
  public draggingWord: Type8Word[] = [];
  public droppedItem1: Type8Word[] = [];
  public droppedItem2: Type8Word[] = [];
  public combinedWord = "";

  draggingNow = false;
  finishDone = false;
  taskCompleted = false;
  audioPlaying = false;

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
  result = {correct: 0, of: 2, incorrectAttempts: 0, answer_details: []};
  correctAudio1 = null;
  correctAudio2 = null;
  audioSentenceQueue = [];

  starLeftCss = {};
  starMiddleCss = {};
  starRightCss = {};

  private dragulaSubscription;
  private dragulaCancelSubscription;
  private dragulaDragSubscription;

  private cancelDropEvent: boolean;

  constructor(private dragulaService: DragulaService, private dataService: DataService) {
    this.setupDragula();
    this.cancelDropEvent = false;
  }

  ngOnInit() {

    // Begin the task..
    this.dataService.progressShow({stars: 2});
    // GroverService.setControllerState(self);

    this.task = <TaskType8> this.dataService.currentTask;
    this.setupTask();
  }

  ngOnDestroy() {
    this.dragulaSubscription.unsubscribe();
    this.dragulaCancelSubscription.unsubscribe();
    this.dragulaDragSubscription.unsubscribe();
    this.dragulaService.destroy("drag-bag8");
  }

  setupTask() {
    this.finishDone = false;
    this.audioSentenceQueue = [];
    this.audioPlaying = false;
    this.taskCompleted = false;
    this.combinedWord = "";
    if (typeof this.task === 'undefined' || this.task === null) {
      alert('A Type 8 task does not exist - check your Session layout in the CMS');
      return;
    }

    let c2 = [];
    for (let ci = 1; ci < 6; ci++ ) {
      if (this.task.hasOwnProperty('correct' + ci)) {
        let correct: Type8Correct = this.task['correct' + ci];
        if (correct.firstWord > 0 || correct.secondWord > 0) {
          c2.push(correct);
        }
      }
    }
    this.correctCombinations = Utilities.shuffleArray(c2);
    this.openStars();

    if (this.task.presetWord.text.length > 0) {
      let word: Type8Word = new Type8Word(this.task.presetWord);
      this['droppedItem' + this.task.presetWord.position].push(word);
    }

    let tempWords = [];
    for (let i = 1; i < 6; i++) {
      const theWord: Type8Word = this.task['word' + i];
      if (theWord.text !== '') {
        tempWords.push(theWord);
      }
    }
    this.result.of = this.correctCombinations.length;
    this.dataService.progressShow({stars: this.result.of});
    this.words = Utilities.shuffleArray(tempWords);
    this.introduceChallenge();
  };

  preventDropOnTap(event) {
    this.cancelDropEvent = true;
    setTimeout(() => {
      this.cancelDropEvent = false;
    }, 1000);
  };


  // ---------  Dragula functions -------------------

  private setupDragula() {
    this.dragulaService.setOptions('drag-bag8', {
      revertOnSpill: true,
      direction: 'horizontal',
      accepts: (el, target, source, sibling) => {
        let boxIndex = this.getBoxIndex(target);
        let dropBoxEmpty = !isNaN(boxIndex) && this['droppedItem' + boxIndex].length === 0;
        return target.id !== 'word-source-box' && dropBoxEmpty;
      },
      moves: (el, source, handle, sibling) => {
        return source.id === 'word-source-box' && this.opacityWords === 1 && !this.draggingNow; // don't prevent any drags from initiating by default
      }
    });

    this.dragulaDragSubscription = this.dragulaService.drag.subscribe((value) => {
      this.draggingNow = true;
    });

    this.dragulaCancelSubscription = this.dragulaService.cancel.subscribe((value) => {
      this.draggingNow = false;
    });

    this.dragulaSubscription = this.dragulaService.dropModel.subscribe((value) => {
      if (!this.cancelDropEvent) {
        this.onDrop();
      } else {
        this.cancelDropEvent = false;
        const [el, target, source, sibling] = value.slice(1);
        const boxIndex = this.getBoxIndex(target);
        const word = this['droppedItem' + boxIndex].pop();
        if (typeof word !== 'undefined') {
          setTimeout(() => {
            this.words.push(word);
            this.draggingNow = false;
          }, 100);
        }
      }
    });
  }

  removeAllWords(discardCorrectWord: boolean) {
    let removeWord: Type8Word = this.droppedItem1[0];
    if (typeof removeWord !== 'undefined' && removeWord.reference !== this.task.presetWord.reference) {
      let word = this.droppedItem1.pop();
      if (!discardCorrectWord) {
        this.words.push(word);
      }
    }
    removeWord = this.droppedItem2[0];
    if (typeof removeWord !== 'undefined' && removeWord.reference !== this.task.presetWord.reference) {
      let word = this.droppedItem2.pop();
      if(!discardCorrectWord) {
        this.words.push(word);
      }
    }
    this.words = Utilities.shuffleArray(this.words);
    this.draggingNow = false;
  };

  private onDrop() {
    this.attempts++;
    let cc: Type8Correct = new Type8Correct({
      firstWord: this.droppedItem1.length > 0 ? this.droppedItem1[0].reference : 0,
      secondWord: this.droppedItem2.length > 0 ? this.droppedItem2[0].reference : 0,
      audio: null
    });

    let result: { status: string, correctRef: Type8Correct } = this.task.hasCombinationStatus(cc, this.correctCombinations);    // Result is the correct combination including audio

    if (!this.task.unforgiving) {
      if (result.status === 'correct') {
        setTimeout(() => {
          this.combinedWord = (this.droppedItem1.length > 0 ? this.droppedItem1[0].text : '')
            + (this.droppedItem2.length > 0 ? this.droppedItem2[0].text : '');
          if (this.discoveredCombinations.indexOf(this.combinedWord) === -1) {
            this.result.correct++;
            this.dataService.progress.results.answer_details
              .push({
                attempt: this.task.wordsFromCC(cc),
                correct: true,
                elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall
              });
            this.dataService.progress.starData.completed++;
            // Fuse into one word
            this.closeStars();
            // Read completed word audio
            result.correctRef.playAudio();
            // Add completed word to completed list
            this.discoveredCombinations.push(this.combinedWord);
            // Remove this combination from the list of correct combinations
            const correctIndex = this.correctCombinations.indexOf(result.correctRef);
            this.correctCombinations.splice(correctIndex, 1);
            // After a delay, check if we are finished
            if (this.result.correct === this.result.of) {
              this.opacityWords = 0;
              setTimeout(() => {
                this.fadeOut();
              }, 1500)
            }
          }
          // Place all words back into 'words', except for a word that formed a correct combination
          setTimeout(() => {
            this.combinedWord = "";
            this.removeAllWords(true);
            this.openStars();
          }, 2000);
        }, 1000);
      } else if (result.status === 'some') {
        this.draggingNow = false;
        // Deactivate the boxIndex position
      } else if (result.status === 'incorrect') {
        this.dataService.progress.results.incorrectAttempts++;
        this.dataService.progress.results.answer_details
          .push({attempt: this.task.wordsFromCC(cc), correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall});
        setTimeout(() => {
          this.removeAllWords(false);
        }, 100);
      }
    }

    else {
      // Show a star regardless of correctness
      this.dataService.progress.starData.completed++;

      if (result.status === 'correct') {
        let combinedWord = (this.droppedItem1.length > 0 ? this.droppedItem1[0].text : '')
          + (this.droppedItem2.length > 0 ? this.droppedItem2[0].text : '');
        if (this.discoveredCombinations.indexOf(combinedWord) === -1) {
          this.result.correct++;
          this.dataService.progress.results.answer_details
            .push({
              attempt: this.task.wordsFromCC(cc),
              correct: true,
              elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall
            });
        }
      } else if (result.status === 'some') {
        this.draggingNow = false;
      } else if (result.status === 'incorrect') {
        this.dataService.progress.results.incorrectAttempts++;
        this.dataService.progress.results.answer_details
          .push({attempt: this.task.wordsFromCC(cc), correct: false, elapsed: this.dataService.progress.results.elapsedTimeSinceLastCall});
      }

      setTimeout(() => {
        this.removeAllWords(false);
      }, 2000);

      // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
      // If number of attempts are limited to correct answers we will finish the task
      if (this.attempts === this.result.of && !this.finishDone) {
        this.opacityWords = 0;
        setTimeout(() => {
          this.fadeOut();
        }, 1500)
      }

    }
  }

  private getWordIndex(el: any) {
    return parseInt(el.id.substr(11), 10);
  }

  private getBoxIndex(target: any) {
    return parseInt(target.id.substr(9), 10);
  }


  // --------------------------------------------------


  openStars() {
    if (this.combinationBoxes === 3) {
      this.starLeftCss = {
        left: '70px'
      };
      this.starMiddleCss = {
        left: '265px'
      };
      this.starRightCss = {
        left: '460px'
      }
    } else {
      this.starLeftCss = {
        left: '60px'
      };
      this.starRightCss = {
        left: '399px'
      }
    }
  }

  closeStars() {
    if (this.combinationBoxes === 3) {
      this.starLeftCss = {
        left: '160px'
      };
      this.starMiddleCss = {
        left: '265px'
      };
      this.starRightCss = {
        left: '371px'
      }
    } else {
      this.starLeftCss = {
        left: '170px'
      };
      this.starRightCss = {
        left: '309px'
      }
    }
  }

  clickWord(item) {
    if (!this.draggingNow && !this.audioPlaying) {
      this.audioPlaying = true;
      item.playAudio(() => {
        this.audioPlaying = false;
      });
      this.dataService.progress.results.use_audio_content_items++;
    } else {
      this.draggingNow = false;
    }
  };

  startDrag() {
    this.draggingNow = true;
  };


  introduceChallenge() {
    this.attempts = 0;
    setTimeout(() => {
      this.opacity = 1;
      this.opacityWords = 1;
      if(this.task.introduction_audio.url) {
        let instructionAudio = new Audio(this.task.introduction_audio.url);
        instructionAudio.addEventListener('ended', () => {
          this.audioPlaying = false;
          this.dataService.setSpeakerSound(this.task.introduction_audio.url, null);
          this.dataService.speakerIsPlaying = false;
        });
        instructionAudio.play();
        this.audioPlaying = true;
        this.dataService.speakerIsPlaying = true;
      }
    }, 1000);
  };


  fadeOut() {
    if (!this.finishDone) {
      this.finishDone = true;
      if (this.dataService.taskMode === TaskMode.Warmups) {
        setTimeout(() => {
          const a = new Audio('assets/sounds/task_instructions/warmups/type8.mp3');
          a.addEventListener('ended', () => {
            this.finish();
          });
          a.play();
        }, 1000);
      } else {
        this.finish();
      }
    }
  };

  finish() {
    setTimeout(() => {
      this.opacity = 0;
      this.completeTask();
    }, 1000);
  };

  completeTask() {
    if (!this.taskCompleted) {
      this.taskCompleted = true;
      setTimeout(() => {
        this.dataService.completeTask();
      }, 1000)
    }
  };

}
