import {Task} from './task.model';
import {Constants} from '../constants';


export class Type9Word {
  _audio: { url: string; };
  type?: string;
  text: string;
  reference?: number;
  displayText?: string;

  audioTrack: HTMLAudioElement;

  constructor(data: {}) {
    this._audio = { url: null };
    this.text = '';
    if (data) {
      this.type = data['type'];
      if (data.hasOwnProperty('audio')) {
        this._audio.url = data['audio'].url;
        this.audioTrack = new Audio(this._audio.url);
      }
      this.text = data['text'];
      /*    if (this.type === 'prefix') {
            this.displayText = this.text + '-';
          } else if (this.type === 'suffix') {
            this.displayText = '-' + this.text;
          } else {
            this.displayText = this.text;
          }*/
      this.displayText = this.text;
    }
  }

  public playAudio(callback) {
    if (this._audio.url !== null) {
      this.audioTrack.addEventListener('ended', () => {
        callback();
      });
      this.audioTrack.play().catch();
    } else {
      callback();
    }
  }
}

export class Type9Correct {
  firstWord: number = 0;
  secondWord: number = 0;
  thirdWord: number = 0;
  fourthWord: number = 0;
  audio: { url: string; };

  constructor(data: {}) {
    this.audio = { url: null };
    if (data) {
      this.firstWord = data['firstWord'];
      this.secondWord = data['secondWord'];
      this.thirdWord = data['thirdWord'];
      this.fourthWord = data['fourthWord'];
      if (data.hasOwnProperty('audio') && data['audio'] !== null) {
        this.audio.url = data['audio'].url
      }
    }
  }

  public playAudio() {
    if (this.audio.url !== null) {
      new Audio(this.audio.url).play();
    }
  }

  // Return match status. Requires supplied words to be unique

  public matchesWords(cc: Type9Correct): string {
    let status = 'some';
    let matchesRemaining = 4;
    let ccWords = [cc.firstWord, cc.secondWord, cc.thirdWord, cc.fourthWord];
    let correctWords = [this.firstWord, this.secondWord, this.thirdWord, this.fourthWord];

    for (let i = 0; i < 4; i++) {
      if (ccWords[i] === correctWords[i]) {
        matchesRemaining--;
      } else if (ccWords[i] !== 0) {
        status = 'incorrect';
      }
    }

    if (matchesRemaining === 0) {
      status = 'correct'
    }
    return status;
  }

}

export class TaskType9 extends Task {
  morph: string = "";
  affix: string = "";
  category: string = "";
  morf_category: string;

  total_correct: number;
  enforce_order: boolean;

  word1: Type9Word;
  word2: Type9Word;
  word3: Type9Word;
  word4: Type9Word;
  word5: Type9Word;
  word6: Type9Word;

  correct1: Type9Correct;
  correct2: Type9Correct;
  correct3: Type9Correct;
  correct4: Type9Correct;
  correct5: Type9Correct;
  correct6: Type9Correct;
  correct7: Type9Correct;
  correct8: Type9Correct;
  correct9: Type9Correct;
  correct10: Type9Correct;
  correct11: Type9Correct;
  correct12: Type9Correct;
  correct13: Type9Correct;
  correct14: Type9Correct;
  correct15: Type9Correct;

  firstWords: string[] = [];
  secondWords: string[] = [];
  thirdWords: string[] = [];
  fourthWords: string[] = [];

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
    this.setupWordLists();
  }

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.affix = input['affix'];
    this.morf_category = input['morf_category'];

    this.total_correct = input['total_correct'] ? input['total_correct'] : 0;
    this.enforce_order = input['enforce_order'];

    this.word1 = new Type9Word(input['word1']);
    this.word1.reference = 1;
    this.word2 = new Type9Word(input['word2']);
    this.word2.reference = 2;
    this.word3 = new Type9Word(input['word3']);
    this.word3.reference = 3;
    this.word4 = new Type9Word(input['word4']);
    this.word4.reference = 4;
    this.word5 = new Type9Word(input['word5']);
    this.word5.reference = 5;
    this.word6 = new Type9Word(input['word6']);
    this.word6.reference = 6;

    this.correct1 = new Type9Correct(input['correct1']);
    this.correct2 = new Type9Correct(input['correct2']);
    this.correct3 = new Type9Correct(input['correct3']);
    this.correct4 = new Type9Correct(input['correct4']);
    this.correct5 = new Type9Correct(input['correct5']);
    this.correct6 = new Type9Correct(input['correct6']);
    this.correct7 = new Type9Correct(input['correct7']);
    this.correct8 = new Type9Correct(input['correct8']);
    this.correct9 = new Type9Correct(input['correct9']);
    this.correct10 = new Type9Correct(input['correct10']);
    this.correct11 = new Type9Correct(input['correct11']);
    this.correct12 = new Type9Correct(input['correct12']);
    this.correct13 = new Type9Correct(input['correct13']);
    this.correct14 = new Type9Correct(input['correct14']);
    this.correct15 = new Type9Correct(input['correct15']);

    return this;
  }

  setupWordLists() {
    let correctItem: Type9Correct, word: string;
    let total_correct_answers = 0;
    let correct_answer_found = false;
    for (let i = 1; i < 16; i++) {
      correct_answer_found = false;
      correctItem = this['correct' + i];
      if (correctItem.firstWord !== 0) {
        correct_answer_found = true;
        word = this['word' + correctItem.firstWord].text;
        if (this.firstWords.indexOf(word) === -1) {
          this.firstWords.push(word);
        }
      }
      if (correctItem.secondWord !== 0) {
        correct_answer_found = true;
        word = this['word' + correctItem.secondWord].text;
        if (this.secondWords.indexOf(word) === -1) {
          this.secondWords.push(word);
        }
      }
      if (correctItem.thirdWord !== 0) {
        correct_answer_found = true;
        word = this['word' + correctItem.thirdWord].text;
        if (this.thirdWords.indexOf(word) === -1) {
          this.thirdWords.push(word);
        }
      }
      if (correctItem.fourthWord !== 0) {
        correct_answer_found = true;
        word = this['word' + correctItem.fourthWord].text;
        if (this.fourthWords.indexOf(word) === -1) {
          this.fourthWords.push(word);
        }
      }

      if (correct_answer_found) {
        total_correct_answers++;
      }
    }
    if (this.total_correct === 0) {
      this.total_correct = total_correct_answers;
    }
  }

  // Calculate if the given combination fully or partially matches a correct answer
  public hasCombinationStatus(cc: Type9Correct, completedWords: string[]): { status: string, correctRef: Type9Correct } {
    let result = { status: 'incorrect', correctRef: null };

    for(let i = 1; i < 16; i++) {
      let compare: Type9Correct = this['correct' + i];
      let status = compare.matchesWords(cc);
      let completedWord = this.combinedWordFromCC(compare);
      if (status === 'some') {
        result.status = 'some';
        break;
      } else if (status === 'correct' && completedWords.indexOf(completedWord) === -1) {
        result = { status: 'correct', correctRef: compare };
        break;
      } else if (status === 'correct' && completedWords.indexOf(completedWord) > -1) {
        result = { status: 'duplicate', correctRef: compare };
        break;
      }
    }
    return result;
  }

  // Return the fully concatenated word for the given word parts
  public combinedWordFromCC(cc: Type9Correct): string {
    let word = cc.firstWord > 0 ? this['word' + cc.firstWord].text : '';
    word += cc.secondWord > 0 ? this['word' + cc.secondWord].text : '';
    word += cc.thirdWord > 0 ? this['word' + cc.thirdWord].text : '';
    word += cc.fourthWord > 0 ? this['word' + cc.fourthWord].text : '';
    return word;
  }


  // A string showing the words inside the given Type7Correct class
  public wordsFromCC(cc: Type9Correct) {
    let w1 = '', w2 = '', w3 = '', w4 = '';
    if (cc.firstWord > 0) {
      w1 = this['word' + cc.firstWord].text;
    }
    if (cc.secondWord > 0) {
      w2 = ', ' + this['word' + cc.secondWord].text;
    }
    if (cc.thirdWord > 0) {
      w3 = ', ' + this['word' + cc.thirdWord].text;
    }
    if (cc.fourthWord > 0) {
      w4 = ', ' + this['word' + cc.fourthWord].text;
    }
    return w1 + w2 + w3 + w4;
  }

  rewriteUrlsToLocalAssets(input: Object) {

    if (this.word1 && this.word1._audio && this.word1._audio.url) {
      this.word1._audio.url =
        Constants.localAssetUrl + input['word1']['audio']['path'] + '/' + input['word1']['audio']['filename'];
    }
    if (this.word2 && this.word2._audio && this.word2._audio.url) {
      this.word2._audio.url =
        Constants.localAssetUrl + input['word2']['audio']['path'] + '/' + input['word2']['audio']['filename'];
    }
    if (this.word3 && this.word3._audio && this.word3._audio.url) {
      this.word3._audio.url =
        Constants.localAssetUrl + input['word3']['audio']['path'] + '/' + input['word3']['audio']['filename'];
    }
    if (this.word4 && this.word4._audio && this.word4._audio.url) {
      this.word4._audio.url =
        Constants.localAssetUrl + input['word4']['audio']['path'] + '/' + input['word4']['audio']['filename'];
    }
    if (this.word5 && this.word5._audio && this.word5._audio.url) {
      this.word5._audio.url =
        Constants.localAssetUrl + input['word5']['audio']['path'] + '/' + input['word5']['audio']['filename'];
    }
    if (this.word6 && this.word6._audio && this.word6._audio.url) {
      this.word6._audio.url =
        Constants.localAssetUrl + input['word6']['audio']['path'] + '/' + input['word6']['audio']['filename'];
    }

    for (let c = 1; c < 16; c++) {
      let ref = typeof this['correct' + c] !== 'undefined' ? this['correct' + c] : null;
      if (ref && ref['audio'] && ref['audio']['url']) {
        ref['audio']['url'] =
          Constants.localAssetUrl + input['correct' + c]['audio']['path'] + '/' + input['correct' + c]['audio']['filename'];
      }
    }


  }


}
