import {Task} from './task.model';
import {Constants} from '../constants';

export class TaskType2 extends Task {
  morph: string = "";
  stem: string = "";
  morphedStem: string = "";
  affix: string = "";
  category: string = "";
  round1: {
    instructionBeforeWord: string;
    word: string;
    wordAudio: { url: string; };
    instructionAfterWord: string;
    instructionAudio: { url: string; };
    instructionAudioText: string;
  };
  round2: {
    instructionBeforeWord: string;
    word: string;
    wordAudio: { url: string; },
    instructionAfterWord: string;
    instructionAudio: { url: string; },
    instructionAudioText: string;
  };
  image1: {
    file: { url: string; filename: string },
    correct1: boolean;
    correct2: boolean;
  };
  image2: {
    file: { url: string; filename: string },
    correct1: boolean;
    correct2: boolean;
  };
  image3: {
    file: { url: string; filename: string },
    correct1: boolean;
    correct2: boolean;
  };
  image4: {
    file: { url: string; filename: string },
    correct1: boolean;
    correct2: boolean;
  };
  image5: {
    file: { url: string; filename: string },
    correct1: boolean;
    correct2: boolean;
  };
  image6: {
    file: { url: string; filename: string },
    correct1: boolean;
    correct2: boolean;
  };

  constructor(tasktype: number, input: Object) {
    super(tasktype, input);
    this.deserialize(input);
    if (Constants.useLocalAssets) {
      this.rewriteUrlsToLocalAssets(input);
    }
  }

  /*  deserialize(instanceData: any) {
      // Note this.active will not be listed in keys since it's declared, but not defined
      const keys = Object.keys(this);

      for (const key of keys) {
        if (instanceData.hasOwnProperty(key)) {
          this[key] = instanceData[key];
        }
      }
    }*/

  deserialize(input: Object) {
    this.category = input['category'];
    this.morph = input['morph'];
    this.stem = input['stem'];
    this.morphedStem = input['morphedStem'];
    this.affix = input['affix'];
    this.round1 = input['round1'];
    this.round2 = input['round2'];
    this.image1 = input['image1'];
    this.image2 = input['image2'];
    this.image3 = input['image3'];
    this.image4 = input['image4'];
    this.image5 = input['image5'];
    this.image6 = input['image6'];
    return this;
  }

  rewriteUrlsToLocalAssets(input: Object) {
    if (this.round1) {
      if (this.round1.instructionAudio) {
        this.round1.instructionAudio.url =
          Constants.localAssetUrl + input['round1']['instructionAudio']['path'] + '/' + input['round1']['instructionAudio']['filename'];
      }
      if (this.round1.wordAudio) {
        this.round1.wordAudio.url =
          Constants.localAssetUrl + input['round1']['wordAudio']['path'] + '/' + input['round1']['wordAudio']['filename'];
      }
    }
    if (this.round2) {
      if (this.round2.instructionAudio) {
        this.round2.instructionAudio.url =
          Constants.localAssetUrl + input['round2']['instructionAudio']['path'] + '/' + input['round2']['instructionAudio']['filename'];
      }
      if (this.round2.wordAudio) {
        this.round2.wordAudio.url =
          Constants.localAssetUrl + input['round2']['wordAudio']['path'] + '/' + input['round2']['wordAudio']['filename'];
      }
    }
    if (this.image1 && this.image1.file) {
        this.image1.file.url =
          Constants.localAssetUrl + input['image1']['file']['path'] + '/' + input['image1']['file']['filename'];
    }
    if (this.image2 && this.image2.file) {
      this.image2.file.url =
        Constants.localAssetUrl + input['image2']['file']['path'] + '/' + input['image2']['file']['filename'];
    }
    if (this.image3 && this.image3.file) {
      this.image3.file.url =
        Constants.localAssetUrl + input['image3']['file']['path'] + '/' + input['image3']['file']['filename'];
    }
    if (this.image4 && this.image4.file) {
      this.image4.file.url =
        Constants.localAssetUrl + input['image4']['file']['path'] + '/' + input['image4']['file']['filename'];
    }
    if (this.image5 && this.image5.file) {
      this.image5.file.url =
        Constants.localAssetUrl + input['image5']['file']['path'] + '/' + input['image5']['file']['filename'];
    }
    if (this.image6 && this.image6.file) {
      this.image6.file.url =
        Constants.localAssetUrl + input['image6']['file']['path'] + '/' + input['image6']['file']['filename'];
    }
  }

}
